import axios from 'axios';
import {
    GET_PRODUCTS_BY_SUBCAT, LOADING_START, LOADING_END, ADD_PRODUCT_TOOLTIP, SET_PRODUCT_VARIANTS_COMMENT, ADD_TO_CART,
    CLEAR_CART, ORDER_FAILED, ORDERED_SUCCESSFULLY, GET_ALL_PRODUCTS, LOADING_ORDER_START, LOADING_ORDER_END,
    ADD_TO_ORDERED, CLEAR_ORDER, CHANGE_SELECTED_CART_TAB, GET_ORDERS, BILL_ASKED, CLEAR_BILL_ASKED, LOADING_CART_START,
    LOADING_CART_END, ADD_USER_NEED_ERROR, ADD_USER_NEED_SUCCESS, RESET_USER_NEED, LOADING_USER_NEED_START,
    LOADING_USER_NEED_END, ORDER_SPOT_FAILED, SET_PRODUCT_PAGE_ID, REMOVE_PRODUCT_PAGE_ID,
    OPEN_COMMENT_MODAL, COMMENT_MODAL_TITLE, COMMENT_VARIANT_ID, PAYMENT_TYPE_MODAL_OPENED, SET_DISABLED_MAKE_ORDER_BUTTON, LIST_INGREDIENTS, PLACE_IMAGE_UPLOADED
} from './types';
import { API_URL, DEFAULT_PRODUCT_IMAGE, BILL_WANTED_EXPIRE_TIME_MINUTES } from '../../constants'
import { openScanModal, setCurrOpenedModal, closeScanModal, setDeliveryModalState } from './../../qr-scan/actions/scan.actions';
import Utils from '../../utils/Utils';
import history from './../../utils/history';
import { isEqual, sortBy, difference } from 'lodash';
import Auth from './../../utils/AuthC';
import moment from 'moment';

export const getPorductIngredients = () => dispatch => {
    axios
        .get(API_URL + 'ingredients')
        .then(res => {
            dispatch({
                type: LIST_INGREDIENTS,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}



export const getAllProductsIfOnePlace = () => dispatch => {
    // if (showLoading) {
    dispatch({ type: LOADING_START });
    // }

    let url = 'productsOnePlace';

    axios.get(API_URL + url)
        .then(res => {
            dispatch({
                type: GET_ALL_PRODUCTS,
                payload: res.data
            });
            // if (showLoading) {
            dispatch({ type: LOADING_END });
            // }
        });
}



export const getProductsBySubcat = (subcat_id) => dispatch => {
    dispatch({ type: LOADING_START });

    axios
        .get(API_URL + 'subcategories/' + subcat_id)
        .then(res => {
            dispatch({
                type: GET_PRODUCTS_BY_SUBCAT,
                payload: res.data
            });
            dispatch({ type: LOADING_END });
        });
}

export const getProductsToOrder = () => dispatch => {
    dispatch({
        type: ADD_TO_CART,
        payload: getLocalCart()
    });
}

export const addProductTooltip = (id, name) => dispatch => {
    dispatch({
        type: ADD_PRODUCT_TOOLTIP,
        payload: { id: id, name: name }
    })
}

export const setOrderComment = (comments) => dispatch => {
    dispatch({
        type: SET_PRODUCT_VARIANTS_COMMENT,
        payload: comments
    })
}

export const addToCart = (product_id, product_variant_id, product, selectedIngredients, prodQuantiy) => dispatch => {
    addToCartLocal(product_id, product_variant_id, false, product, selectedIngredients, prodQuantiy);

    dispatch({
        type: ADD_TO_CART,
        payload: getLocalCart()
    })
}

export const removeProductFromCart = (product) => dispatch => {
    var to_order = window.localStorage.getItem('to_order');//setItem('token', token)

    // debugger
    if (!to_order) {
        to_order = {};
    } else {
        to_order = JSON.parse(to_order);
    }

    const { product_variant_id, ingredients } = product;
    // console.log('to_order', to_order)
    // console.log('to_order[product_variant_id]', to_order[product_variant_id])
    // console.log('[]',getUpdatableIndex(product_variant_id, ingredients))
    // if (to_order && to_order[product_variant_id] && to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)]) {
    //     // console.log('to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)]', to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)])
    //     // delete to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)];
    //     to_order[product_variant_id].splice(getUpdatableIndex(product_variant_id, ingredients), 1)
    // }

    delete to_order[product_variant_id]; // Only for si7 because no ingredients

    window.localStorage.setItem('to_order', JSON.stringify(to_order))

    dispatch({
        type: ADD_TO_CART,
        payload: getLocalCart()
    })

}

export const updateProductQuantity = (product, quantity) => dispatch => {
    var to_order = window.localStorage.getItem('to_order');//setItem('token', token)

    // debugger
    if (!to_order) {
        to_order = {};
    } else {
        to_order = JSON.parse(to_order);
    }

    // console.log('product', product)

    const { product_variant_id, ingredients } = product;

    if (to_order && to_order[product_variant_id] && to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)] && to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)]['quantity']) {
        to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)]['quantity'] = quantity;
    }

    // if (to_order[product_variant_id]['quantity']) {
    //     to_order[product_variant_id]['quantity'] = quantity;
    // }

    window.localStorage.setItem('to_order', JSON.stringify(to_order))

    dispatch({
        type: ADD_TO_CART,
        payload: getLocalCart()
    })
}

// export const getCartProductQuantity = (selectedIngredients) => {
//     var to_order = window.localStorage.getItem('to_order');//setItem('token', token)
//     to_order = JSON.parse(to_order);

//     let cartProductQuantity = 0;
//     if (to_order && Object.values(to_order).length > 0) {
//         Object.values(to_order).map(o => {
//             if (o.findIndex(i => parseInt(i.ingredients.required) == parseInt(selectedIngredients.required) && (isEqual(sortBy(i.ingredients.addable), sortBy(selectedIngredients.addable)) && (isEqual(sortBy(i.ingredients.removable), sortBy(selectedIngredients.removable))))) > -1) {
//                 cartProductQuantity = o.quantity
//             }
//         })
//     }
//     // console.log('cartProductQuantity', cartProductQuantity)
//     return cartProductQuantity;

// } 

// const differentIngridients = (product_variant_id, selectedIngredients) => {
//     var to_order = window.localStorage.getItem('to_order');//setItem('token', token)
//     to_order = JSON.parse(to_order);

//     // console.log('to_order: ', to_order)

//     let equalIngridients = false;
//     if (to_order && Object.values(to_order).length > 0) {
//         Object.values(to_order).map(o => {
//             // console.log('finda', o.findIndex(i => parseInt(i.ingredients.required) == parseInt(selectedIngredients.required) && (isEqual(sortBy(i.ingredients.addable), sortBy(selectedIngredients.addable)) && (isEqual(sortBy(i.ingredients.removable), sortBy(selectedIngredients.removable))))))
//             equalIngridients = equalIngridients || (o.findIndex(i => parseInt(i.ingredients.required) == parseInt(selectedIngredients.required) && (isEqual(sortBy(i.ingredients.addable), sortBy(selectedIngredients.addable)) && (isEqual(sortBy(i.ingredients.removable), sortBy(selectedIngredients.removable))))) > -1)
//         })
//     }
//     // console.log('equalIngridients', equalIngridients)
//     return !equalIngridients;

// }

const getUpdatableIndex = (product_variant_id, selectedIngredients) => {
    var to_order = window.localStorage.getItem('to_order');//setItem('token', token)
    to_order = JSON.parse(to_order);

    let updateIndex = -1;
    if (to_order && Object.values(to_order).length > 0) {
        Object.values(to_order).map(o => {
            // console.log('finda', o.findIndex(i => parseInt(i.ingredients.required) == parseInt(selectedIngredients.required) && (isEqual(sortBy(i.ingredients.addable), sortBy(selectedIngredients.addable)) && (isEqual(sortBy(i.ingredients.removable), sortBy(selectedIngredients.removable))))))
            updateIndex = updateIndex == -1 ? (o.findIndex(i => parseInt(i.ingredients.required) == parseInt(selectedIngredients.required) && (isEqual(sortBy(i.ingredients.addable), sortBy(selectedIngredients.addable)) && (isEqual(sortBy(i.ingredients.removable), sortBy(selectedIngredients.removable)))))) : updateIndex
        })
    }

    return updateIndex;
}

export const addToCartLocal = (product_id, product_variant_id, quantity, product, selectedIngredients = {}, prodQuantiy = 1) => {
    var to_order = window.localStorage.getItem('to_order');//setItem('token', token)
    // console.log('selectedIngredients', selectedIngredients)
    // debugger
    if (!to_order) {
        to_order = {};
    } else {
        to_order = JSON.parse(to_order);
    }

    // console.log(differentIngridients(product_variant_id, selectedIngredients));

    if (selectedIngredients.required) {
        selectedIngredients.required = [selectedIngredients.required];
    }

    if (!to_order[product_variant_id] || (to_order[product_variant_id] && Utils.differentIngridients(product_variant_id, selectedIngredients))) {
        let selectedProdVariant = product['product_variants'].filter(pv => pv.id === product_variant_id)[0];

        let toOrderProd = {};
        if (!to_order[product_variant_id]) {
            to_order[product_variant_id] = [];
        }

        toOrderProd['product_variant_id'] = product_variant_id;
        toOrderProd['product_id'] = product_id;
        toOrderProd['size'] = selectedProdVariant['size'];
        toOrderProd['price'] = selectedProdVariant['price'];
        toOrderProd['additional_price'] = selectedProdVariant['additional_price'];
        toOrderProd['name'] = product['name'];
        toOrderProd['image'] = product['product_images'] && product['product_images'][0] && product['product_images'][0].path ? Utils.getProductImagePath(product['product_images'][0].path, 1) : DEFAULT_PRODUCT_IMAGE;
        toOrderProd['date_added'] = new Date().getTime();
        toOrderProd['product_translations'] = product.translations || '';
        toOrderProd['variant_translations'] = selectedProdVariant.translations || '';
        toOrderProd['product_ingredients'] = product.product_ingredients || '';
        toOrderProd['ingredients'] = selectedIngredients || '';
        toOrderProd['quantity'] = prodQuantiy;
        toOrderProd['discount_percent'] = product.discount_percent;
        // console.log('quantity SET TO ', prodQuantiy)
        to_order[product_variant_id].push(toOrderProd);
        // console.log('vlizam purvoto')

    } else {
        // console.log('vlizam tuk')
        // console.log('vlizam to_order', to_order)
        // console.log('vlizam to_order[product_variant_id]', to_order[product_variant_id])
        // console.log('vlizam updatable index: ', getUpdatableIndex(product_variant_id, selectedIngredients))

        if (!quantity) {
            if (to_order[product_variant_id][getUpdatableIndex(product_variant_id, selectedIngredients)]['quantity']) {
                to_order[product_variant_id][getUpdatableIndex(product_variant_id, selectedIngredients)]['quantity'] += prodQuantiy;
                // console.log('quantity SET TO2 ', prodQuantiy)
            }
        } else {
            to_order[product_variant_id][getUpdatableIndex(product_variant_id, selectedIngredients)]['quantity'] = quantity;
            // console.log('quantity SET TO3 ', quantity)
        }
    }


    window.localStorage.setItem('to_order', JSON.stringify(to_order))
}

export const getLocalCart = function () {
    var to_order = window.localStorage.getItem('to_order');
    if (!to_order) {
        to_order = [];
    } else {
        to_order = JSON.parse(to_order);
    }

    let sortedOrders = to_order;
    // sortedOrders = _.sortBy(sortedOrders, [function(o) { return o.date_added; }])
    sortedOrders = sortBy(sortedOrders, ['date_added'])
    return sortedOrders;

    // return to_order;
}

export const clearCartLocal = () => dispatch => {
    window.localStorage.removeItem('to_order');
    dispatch({ type: CLEAR_CART })// clear cart from state 
}

export const makeOrder = (deliveryData, orderComment, orderType = 2, deliveryPrice = 0, translations) => dispatch => {
    dispatch({ type: LOADING_ORDER_START });
    window.localStorage.removeItem('bill_wanted');

    if (orderType == 1) {
        dispatch(sendRequestRestaurant(orderComment, translations));
    } else if (orderType == 2) {
        dispatch(sendRequestDelivery(deliveryData, orderComment, deliveryPrice));
    } else if (orderType == 3) {
        dispatch(sendRequestPickup(deliveryData, orderComment));
    }

}

export const makeOrderDefault = (data, orderComment, translations, imagesAndCommentsData, hasImagesAndComments) => dispatch => {
    dispatch({ type: LOADING_ORDER_START });
    // console.log(data);return;
    if (!Auth.getToken()) {
        dispatch({ type: LOADING_ORDER_END });
        dispatch({
            type: ORDER_FAILED,
            payload: { message: "Не сте влезли в профила си!" } //No translation needed
        })
        return;
    }

    const { distributor_id, shop_id, total_price, realOrderComment } = data;
    var to_order = getLocalCart();

    let productsArrToSend = [];
    Object.values(to_order).map(t =>
        productsArrToSend = productsArrToSend.concat(t)
    );
    // console.log('productsArrToSend', productsArrToSend)

    axios.post(API_URL + 'orders', {
        products: productsArrToSend,
        distributor_id: distributor_id,
        shop_id: shop_id,
        user_id: Auth.getUserFromToken() ? Auth.getUserFromToken().id : null,
        product_variants_comment: JSON.stringify(orderComment),
        order_comment: realOrderComment

    })
        .then(res => {
            // console.log("DATA ", imagesAndCommentsData)
            if (res.data.success) {
                if (hasImagesAndComments) {
                    imagesAndCommentsData.append("order_id", res.data.payload.order_id);

                    axios.post(API_URL + 'orders/images_comments', imagesAndCommentsData)
                        .then(imagesResp => {
                            dispatch({
                                type: ORDERED_SUCCESSFULLY,
                                payload: { message: "Успешно изпратена поръчка.", token: res.data.token, current_order_type: 2 } //No translation needed
                            })

                        })
                        .catch(imagesErr => {
                            dispatch({
                                type: ORDER_FAILED,
                                payload: { message: "Възникна грешка при изпращане на поръчката." } //No translation needed
                            });
                        })
                } else {
                    dispatch({
                        type: ORDERED_SUCCESSFULLY,
                        payload: { message: "Успешно изпратена поръчка.", token: res.data.token, current_order_type: 2 } //No translation needed
                    })

                    dispatch({ type: LOADING_ORDER_END });
                }


            }

            // if (res.data.error) {
            //     dispatch({
            //         type: ORDER_FAILED,
            //         payload: { message: "Възникна грешка при изпращане на поръчката." } //No translation needed
            //     });
            //     Utils.removeUserToken();
            //     dispatch({ type: LOADING_ORDER_END });
            // } else {
            //     dispatch({
            //         type: ORDERED_SUCCESSFULLY,
            //         payload: { message: "Поръчката ви скоро ще бъде доставена.", token: res.data.token, current_order_type: 2 } //No translation needed
            //     })

            //     dispatch({ type: LOADING_ORDER_END });
            // }
        }).catch(error => {
            dispatch({
                type: ORDER_FAILED,
                payload: { message: "Възникна грешка при изпращане на поръчката!" } //No translation needed
            })
            // Utils.removeUserToken();
            dispatch({ type: LOADING_ORDER_END });
            throw (error);
        });
}

const sendOrderImagesAndComments = (data) => {
    axios.post(API_URL + 'orders/images_comments', data)

}

const sendSmsForNewOrder = (orderData, products, orderTypes, phoneNum) => {
    const { name, phone, address, email, pickupDate, pickupTime } = orderData;

    let pickupDateFormat = new Date(pickupDate + '.' + (new Date()).getFullYear());
    pickupDateFormat = Utils.appendLeadingZeroes(pickupDateFormat.getDate()) + '.' + Utils.appendLeadingZeroes(parseInt(parseInt(pickupDateFormat.getMonth()) + 1));

    let pickupTimeText = '';
    if (orderTypes == 3) {
        pickupTimeText = 'Shte%20vzeme%20v:%20' + pickupDateFormat + '%20' + pickupTime;
    } else if (orderTypes == 2) {
        pickupTimeText = 'Dostavi%20v:%20' + pickupDateFormat + '%20' + pickupTime;
    }

    let prods = '';
    Object.values(products).map(p => {
        prods += p.quantity + ' x ' + p.name + ' | ';
    });

    // DENIS PHONE axios.get(`https://platform.clickatell.com/messages/http/send?apiKey=q8ppKOM_Sy-gydxpYqKXvw==&to=359884014689&content=TabL.BG%20|%20Nova%20poruchka%20ot%20${name}:%20${prods}%20${pickupTimeText}`) //DENIS PHONE
    axios.get(`https://platform.clickatell.com/messages/http/send?apiKey=q8ppKOM_Sy-gydxpYqKXvw==&to=${phoneNum}&content=TabL.BG%20|%20Nova%20poruchka%20ot%20${name}:%20${prods}%20${pickupTimeText}`)

}

const sendSmsForNewOrderDenis = (orderData, products, orderTypes) => {
    const { name, phone, address, email, pickupDate, pickupTime } = orderData;

    let pickupDateFormat = new Date(pickupDate + '.' + (new Date()).getFullYear());
    pickupDateFormat = Utils.appendLeadingZeroes(pickupDateFormat.getDate()) + '.' + Utils.appendLeadingZeroes(parseInt(parseInt(pickupDateFormat.getMonth()) + 1));

    let pickupTimeText = '';
    if (orderTypes == 3) {
        pickupTimeText = 'Shte%20vzeme%20v:%20' + pickupDateFormat + '%20' + pickupTime;
    } else if (orderTypes == 2) {
        pickupTimeText = 'Dostavi%20v:%20' + pickupDateFormat + '%20' + pickupTime;
    }

    let prods = '';
    Object.values(products).map(p => {
        prods += p.quantity + ' x ' + p.name + ' | ';
    });

    axios.get(`https://platform.clickatell.com/messages/http/send?apiKey=q8ppKOM_Sy-gydxpYqKXvw==&to=359884014689&content=TabL.BG%20|%20Nova%20poruchka%20ot%20${name}:%20${prods}%20${pickupTimeText}`) //DENIS PHONE

}


const sendRequestDelivery = (deliveryData, orderComment, deliveryPrice) => dispatch => {
    const { name, phone, address, email, pickupDate, pickupTime } = deliveryData;

    const userToken = Utils.getUserToken();
    var to_order = getLocalCart();

    let productsArrToSend = [];
    Object.values(to_order).map(t =>
        productsArrToSend = productsArrToSend.concat(t)
    );
    // console.log('productsArrToSend', productsArrToSend)

    axios.post(API_URL + 'orders/delivery', {
        products: productsArrToSend,
        client_name: name,
        client_phone: phone,
        client_address: address,
        client_email: email,
        token: userToken,
        product_variants_comment: orderComment,
        place_id: window.localStorage.getItem("place"),
        delivery_price: deliveryPrice,
        delivery_time: pickupDate + '.' + (new Date()).getFullYear() + ' ' + pickupTime,
    })
        .then(res => {
            if (res.data.error) {
                dispatch({
                    type: ORDER_FAILED,
                    payload: { message: "Възникна грешка при изпращане на поръчката." } //No translation needed
                });
                Utils.removeUserToken();
                dispatch({ type: LOADING_ORDER_END });
            } else {
                dispatch({
                    type: ORDERED_SUCCESSFULLY,
                    payload: { message: "Поръчката ви скоро ще бъде доставена.", token: res.data.token, current_order_type: 2 } //No translation needed
                })

                // Set token when delivery/preorder
                if (userToken == '' || !userToken) {
                    Utils.setUserToken(res.data.token);
                }

                // if (window.location.host == 'bulname.tabl.bg') {
                //     axios.get('https://tabl.bg/RefreshOrderStatus.php?host=' + window.location.host + '&name=' + name); // Send email
                // }

                // if (window.location.host == 'gabrovo.pizza') {
                //     sendSmsForNewOrder(deliveryData, Object.values(to_order), 2, '359876277377'); // Send sms
                // }

                // if (window.location.host == 'bulgarche.tabl.bg') {
                //     sendSmsForNewOrder(deliveryData, Object.values(to_order), 2, '359877583910'); // Send sms
                //     // axios.get('https://tabl.bg/RefreshOrderStatus.php?host=' + window.location.host + '&name=' + name); // Send mail
                // }

                // if (window.location.host.includes('localhost') || window.location.host == 'test.tabl.bg') {
                //     sendSmsForNewOrderDenis(deliveryData, Object.values(to_order), 2);
                //     // axios.get('https://tabl.bg/RefreshOrderStatus.php?host='+window.location.host+'&name=' + name); // Send mail
                // }

                // Send info email for each user that have been inserted their email 
                // if (email) {
                //     axios.get('https://tabl.bg/RefreshFinalizeOrderStatus.php?host=' + window.location.host + '&phone=' + phone + '&email=' + email + '&name=' + name + '&address=' + address); // Send mail
                // }

                dispatch({ type: LOADING_ORDER_END });
            }
        }).catch(error => {
            dispatch({
                type: ORDER_FAILED,
                payload: { message: "Възникна грешка при изпращане на поръчката!" } //No translation needed
            })
            Utils.removeUserToken();
            dispatch({ type: LOADING_ORDER_END });
            throw (error);
        });

}



const sendRequestPickup = (pickupData, orderComment) => dispatch => {
    const { name, phone, address, email, pickupDate, pickupTime } = pickupData;
    const userToken = Utils.getUserToken();
    var to_order = getLocalCart();

    let productsArrToSend = [];
    Object.values(to_order).map(t =>
        productsArrToSend = productsArrToSend.concat(t)
    );


    axios.post(API_URL + 'orders/preorder', {
        products: productsArrToSend,
        client_name: name,
        client_phone: phone,
        client_address: address,
        client_email: email,
        token: userToken,
        product_variants_comment: orderComment,
        place_id: window.localStorage.getItem("place"),
        pickup_time: pickupDate + '.' + (new Date()).getFullYear() + ' ' + pickupTime,
    })
        .then(res => {
            if (res.data.error) {
                dispatch({
                    type: ORDER_FAILED,
                    payload: { message: "Възникна грешка при изпращане на поръчката." } //No translation needed
                });
                Utils.removeUserToken();
                dispatch({ type: LOADING_ORDER_END });
            } else {
                dispatch({
                    type: ORDERED_SUCCESSFULLY,
                    payload: { message: "Поръчката ви скоро ще бъде доставена.", token: res.data.token, current_order_type: 3 } //No translation needed
                })

                // Set token when delivery/preorder
                if (userToken == '' || !userToken) {
                    Utils.setUserToken(res.data.token);
                }

                // if (window.location.host == 'bulname.tabl.bg') {
                //     axios.get('https://tabl.bg/RefreshOrderStatus.php?host=' + window.location.host + '&name=' + name); // Send email
                // }

                // if (window.location.host == 'gabrovo.pizza') {
                //     sendSmsForNewOrder(pickupData, Object.values(to_order), 3, '359876277377'); // Send sms
                // }

                // if (window.location.host == 'bulgarche.tabl.bg') {
                //     sendSmsForNewOrder(pickupData, Object.values(to_order), 3, '359877583910'); // Send sms
                //     // axios.get('https://tabl.bg/RefreshOrderStatus.php?host=' + window.location.host + '&name=' + name); // Send mail
                // }

                // if (window.location.host.includes('localhost') || window.location.host == 'test.tabl.bg') {
                //     sendSmsForNewOrderDenis(pickupData, Object.values(to_order), 3);
                //     // axios.get('https://tabl.bg/RefreshOrderStatus.php?host='+window.location.host+'&name=' + name); // Send mail
                // }

                // Send info email for each user that have been inserted their email 
                // if (email) {
                //     axios.get('https://tabl.bg/RefreshFinalizeOrderStatus.php?host=' + window.location.host + '&phone=' + phone + '&email=' + email + '&name=' + name + '&address=' + address); // Send mail
                // }

                dispatch({ type: LOADING_ORDER_END });
            }
        }).catch(error => {
            dispatch({
                type: ORDER_FAILED,
                payload: { message: "Възникна грешка при изпращане на поръчката!" } //No translation needed
            })
            Utils.removeUserToken();
            dispatch({ type: LOADING_ORDER_END });
            throw (error);
        });

}


const sendRequestRestaurant = (orderComment, stranslations) => dispatch => {
    var to_order = getLocalCart();
    const userToken = Utils.getUserToken();

    // dispatch(setCurrOpenedModal( "multiple" ));
    let productsArrToSend = [];
    Object.values(to_order).map(t =>
        productsArrToSend = productsArrToSend.concat(t)
    );

    axios
        .post(API_URL + 'orders', {
            // table_token: userToken,
            session: userToken,
            products: productsArrToSend,
            place_id: window.localStorage.getItem("place"),
            product_variants_comment: orderComment
        })
        .then(res => {
            // if (res.data.error) {
            //     // ne bi trqbvalo da vliza
            //     // dispatch({
            //     //     type: ORDER_FAILED,
            //     //     payload: { message: "Възникна грешка при изпращане на поръчката." } //No translation needed
            //     // });
            //     // Utils.removeUserToken();
            //     // dispatch({ type: LOADING_ORDER_END });

            // } else {
            dispatch({
                type: ORDERED_SUCCESSFULLY,
                payload: { message: "Поръчката ви скоро ще бъде сервирана.", current_order_type: 1 } //No translation needed
            })

            // dispatch(closeScanModal());

            dispatch({ type: LOADING_ORDER_END });
            // }
            dispatch(closeScanModal());
            dispatch(setCurrOpenedModal(''))
            // dispatch(setDeliveryModalState(0));

        }).catch(error => {
            if (window.localStorage.getItem('app')) {
                // history.push("/close-browser-app")
                window.location.pathname = "/close-browser-app"
            } else {

                dispatch(openScanModal());

                let msg = "";
                if (Utils.getUserToken()) {
                    msg = "Моля сканирайте отново QR кода";
                    dispatch(setDeliveryModalState(1));

                } else {
                    msg = "Възникна грешка при изпращане на поръчката"
                }

                dispatch({
                    type: ORDER_FAILED,
                    payload: { message: msg } //No translation needed
                });

                // dispatch(closeScanModal());

                // Utils.removeUserToken();
                dispatch({ type: LOADING_ORDER_END });
            }

            // dispatch({ type: ORDER_SPOT_FAILED });
            // throw (error);

            // if is in app mode


        });
}


export const clearOrderStatus = () => dispatch => {
    dispatch({ type: CLEAR_ORDER });
}

export const getAllProductsAccordingToPlaces = (showLoading = 0) => dispatch => {
    if (showLoading) {
        dispatch({ type: LOADING_START });
    }

    let url = 'productsAccordingToPlaces?active=1';
    // if (placeId !== 0) {
    //     url += '&place_id=' + placeId;
    // }

    axios.get(API_URL + url)
        .then(res => {
            dispatch({
                type: GET_ALL_PRODUCTS,
                payload: res.data
            });
            if (showLoading) {
                dispatch({ type: LOADING_END });
            }
        });
}

export const getAllProducts = (showLoading = 0, placeId = 0) => dispatch => {
    if (showLoading) {
        dispatch({ type: LOADING_START });
    }

    let url = 'products?active=1';
    if (placeId !== 0) {
        url += '&place_id=' + placeId;
    }

    axios.get(API_URL + url)
        .then(res => {
            dispatch({
                type: GET_ALL_PRODUCTS,
                payload: res.data
            });
            if (showLoading) {
                dispatch({ type: LOADING_END });
            }
        });
}

export const getOrders = (fromDate = null, toDate = null, shopId = null, orderId = null) => dispatch => {
    const userFromToken = Auth.getUserFromToken();
    // const userId = uToken ? uToken : Utils.getUserToken();
    if (!(userFromToken || userFromToken.id)) {
        dispatch({
            type: GET_ORDERS,
            payload: []
        });
        return;
    }

    let query = "?user_id=" + userFromToken.id;

    if(fromDate) {
        query += `&fromDate=${moment(fromDate).format('YYYY-MM-DD')}`;
    }
    if(toDate) {
        toDate = moment(toDate).format('YYYY-MM-DD') + " 23:59";
        query += `&toDate=${encodeURIComponent(toDate)}`;
    }
    if(orderId) {
        query += `&orderId=${orderId}`;
    }
    if(shopId) {
        query += `&shopId=${shopId}`;
    }

    axios.get(API_URL + 'orders' + query)
        .then(result => {
            if (result.data.error) {
                dispatch({
                    type: GET_ORDERS,
                    payload: []
                })
            } else {
                dispatch({
                    type: GET_ORDERS,
                    payload: sortBy(result.data, ['id']).reverse()
                })
            }
        })
        .catch(err => {
            Utils.removeUserToken();
            dispatch({
                type: GET_ORDERS,
                payload: []
            });
        })
}

export const changeSelectedCartTab = (selectedCartTab) => dispatch => {
    dispatch({
        type: CHANGE_SELECTED_CART_TAB,
        payload: selectedCartTab
    });
}

export const removeUserTokenAndOrder = () => dispatch => {
    Utils.removeUserToken();
    dispatch({
        type: GET_ORDERS,
        payload: []
    });
}


export const clearBillWanted = () => dispatch => {
    dispatch({ type: CLEAR_BILL_ASKED, });
}

export const askForBill = (paymentMethod) => dispatch => {
    const userToken = Utils.getUserToken();
    if (!userToken) {
        dispatch({
            type: BILL_ASKED,
            payload: false
            // payload: "Сметката ви е поискана успешно." //No translation needed
        });
        return;
    }

    dispatch({ type: LOADING_CART_START });

    axios
        .put(API_URL + `orders/by_table_session/${userToken}/bill_wanted`,
            {
                status: true,
                paymentMethod: paymentMethod
            }
        )
        .then(res => {
            dispatch({
                type: BILL_ASKED,
                payload: res.data
                // payload: "Сметката ви е поискана успешно." //No translation needed
            })
            dispatch(getOrders());

            // this.getOrders();
            dispatch({ type: LOADING_CART_END });
            Utils.updateUserTokenExpire(BILL_WANTED_EXPIRE_TIME_MINUTES);

            window.localStorage.setItem('bill_wanted', 1);
        })
        .catch(err => {
            dispatch({ type: LOADING_CART_END });
            dispatch({
                type: BILL_ASKED,
                payload: false
            })
        });
}

export const addUserNeed = (userNeed, scannedToken) => dispatch => {
    const userToken = Utils.getUserToken() || scannedToken;
    if (!userToken) {
        return;
    }
    //TODO
    // if(!Auth.getToken()) {
    //     history.push('/scanTable');
    //     return;
    // }

    dispatch({ type: LOADING_USER_NEED_START });

    axios
        .post(API_URL + 'user_needs/by_session/' + userToken, {
            need: userNeed
        })
        .then(res => {
            dispatch({
                type: ADD_USER_NEED_SUCCESS,
                payload: "Сервитьорът беше нотифициран." //No translation needed
            });
            dispatch({ type: LOADING_USER_NEED_END });
        })
        .catch(e => {
            dispatch({
                type: ADD_USER_NEED_ERROR,
                payload: "Възникна грешка при изпращане на желанието ви." //No translation needed
            });
            dispatch({ type: LOADING_USER_NEED_END });
        });
}

export const resetUserNeedStatus = () => dispatch => {
    dispatch({
        type: RESET_USER_NEED,
    })
}


export const setProductPageId = (productId) => dispatch => {
    dispatch({
        type: SET_PRODUCT_PAGE_ID,
        payload: productId
    });
}

export const removeProductPageId = () => dispatch => {
    dispatch({
        type: REMOVE_PRODUCT_PAGE_ID
    });
}

export const setCommentModalData = (open, itemName, itemProductVariantId) => dispatch => {

    //     openCommentModal
    // commentModalTitle
    // commentVariantId

    dispatch({
        type: OPEN_COMMENT_MODAL,
        payload: open
    });
    dispatch({
        type: COMMENT_MODAL_TITLE,
        payload: itemName
    });
    dispatch({
        type: COMMENT_VARIANT_ID,
        payload: itemProductVariantId
    });
}


export const closePaymentTypeModal = () => dispatch => {
    dispatch({
        type: PAYMENT_TYPE_MODAL_OPENED,
        payload: false
    });
}

export const openPaymentTypeModal = () => dispatch => {
    dispatch({
        type: PAYMENT_TYPE_MODAL_OPENED,
        payload: true
    });
}

export const setDisabledMakeOrderButton = (status) => dispatch => {
    dispatch({
        type: SET_DISABLED_MAKE_ORDER_BUTTON,
        payload: status
    });
}
