import {
    GET_PRODUCTS_BY_SUBCAT, GET_ALL_PRODUCTS, LOADING_START, LOADING_END, ADD_PRODUCT_TOOLTIP, ADD_TO_CART, CLEAR_CART, ORDER_FAILED,
    ORDERED_SUCCESSFULLY, LOADING_ORDER_START, LOADING_ORDER_END, CLEAR_ORDER, ADD_TO_ORDERED, CHANGE_SELECTED_CART_TAB, GET_ORDERS, SET_PRODUCT_VARIANTS_COMMENT,
    BILL_ASKED, CLEAR_BILL_ASKED, LOADING_CART_START, LOADING_CART_END, ADD_USER_NEED_ERROR, ADD_USER_NEED_SUCCESS, RESET_USER_NEED, LOADING_USER_NEED_START,
    LOADING_USER_NEED_END, ORDER_SPOT_FAILED, SET_PRODUCT_PAGE_ID, REMOVE_PRODUCT_PAGE_ID, OPEN_COMMENT_MODAL, COMMENT_MODAL_TITLE, COMMENT_VARIANT_ID,
    PAYMENT_TYPE_MODAL_OPENED, SET_DISABLED_MAKE_ORDER_BUTTON, LIST_INGREDIENTS, PLACE_IMAGE_UPLOADED
} from '../actions/types';

const initialState = {
    subcat_products: [],
    all_products: [],
    isLoading: false,
    isLoadingOrder: false,
    isLoadingCart: false,
    isLoadingUserNeeds: false,
    add_product_tooltip: {},
    to_order: [],
    ordered: [],
    order_sent: null,
    order_message: '',
    selected_cart_tab: 0,
    product_variants_comment: [],
    bill_wanted: false,
    bill_wanted_status: false,
    user_need_failed: '',
    user_need_added: '',
    current_order_type: 0,
    view_product_page_id: 0,
    open_comment_modal: false,
    comment_modal_title: '',
    comment_variant_id: '',
    payment_type_modal_opened: false,
    disabledMakeOrderButton: false,
    productIngredients: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCTS_BY_SUBCAT:
            return {
                ...state,
                subcat_products: action.payload
                // subcat_products: [...state.subcat_products.findIndex(p => p.id == action.payload[0].id) != -1 ? [...state.subcat_products] : [...state.subcat_products, action.payload]]
            }
        case GET_ALL_PRODUCTS:
            return {
                ...state,
                all_products: action.payload,
                // subcat_products: [action.payload] // 
            }

        case LOADING_START:
            return {
                ...state,
                isLoading: true
            }
        case LOADING_END:
            return {
                ...state,
                isLoading: false
            }
        case LOADING_ORDER_START:
            return {
                ...state,
                isLoadingOrder: true
            }
        case LOADING_ORDER_END:
            return {
                ...state,
                isLoadingOrder: false
            }
        case LOADING_CART_START:
            return {
                ...state,
                isLoadingCart: true
            }
        case LOADING_CART_END:
            return {
                ...state,
                isLoadingCart: false
            }

        case ADD_PRODUCT_TOOLTIP:
            return {
                ...state,
                add_product_tooltip: action.payload
            }

        case ADD_TO_CART:
            return {
                ...state,
                to_order: action.payload
            };

        case CLEAR_CART:
            return {
                ...state,
                to_order: {}
            };

        case ORDERED_SUCCESSFULLY:
            return {
                ...state,
                order_sent: 'true',
                order_message: action.payload.message,
                user_token: action.payload.token,
                current_order_type: action.payload.current_order_type
            }
        case ORDER_FAILED:
            return {
                ...state,
                order_sent: 'false',
                order_message: action.payload.message,
            }
        // case ORDER_SPOT_FAILED:
        //     return {
        //         ...state,
        //         scan_modal_open: 1
        //     }

        case BILL_ASKED:
            return {
                ...state,
                bill_wanted: 'true',
                bill_wanted_status: action.payload && action.payload.success ? 'true' : 'false'
                // bill_wanted_status: action.payload
            }
        case CLEAR_BILL_ASKED:
            return {
                ...state,
                bill_wanted: 'false',
            }


        case GET_ORDERS:
            return {
                ...state,
                cart_msg: "",
                ordered: action.payload
            };


        case CLEAR_ORDER:
            return {
                ...state,
                order_sent: null,
                order_message: ''
            }

        case ADD_TO_ORDERED:
            return {
                ...state,
                ordered: action.payload
            };


        case CHANGE_SELECTED_CART_TAB:
            return {
                ...state,
                selected_cart_tab: action.payload
            }

        case SET_PRODUCT_VARIANTS_COMMENT:
            return {
                ...state,
                product_variants_comment: action.payload
            }


        case ADD_USER_NEED_ERROR:
            return {
                ...state,
                user_need_failed: action.payload
            }
        case ADD_USER_NEED_SUCCESS:
            return {
                ...state,
                user_need_added: action.payload
            }
        case RESET_USER_NEED:
            return {
                ...state,
                user_need_failed: '',
                user_need_added: '',
            }
        case LOADING_USER_NEED_START:
            return {
                ...state,
                isLoadingUserNeeds: true
            }
        case LOADING_USER_NEED_END:
            return {
                ...state,
                isLoadingUserNeeds: false
            }

        case SET_PRODUCT_PAGE_ID:
            return {
                ...state,
                view_product_page_id: action.payload
            }
        case REMOVE_PRODUCT_PAGE_ID:
            return {
                ...state,
                view_product_page_id: 0
            }

        case OPEN_COMMENT_MODAL:
            return {
                ...state,
                open_comment_modal: action.payload
            }

        case COMMENT_MODAL_TITLE:
            return {
                ...state,
                comment_modal_title: action.payload
            }

        case COMMENT_VARIANT_ID:
            return {
                ...state,
                comment_variant_id: action.payload
            }

        case PAYMENT_TYPE_MODAL_OPENED:
            return {
                ...state,
                payment_type_modal_opened: action.payload
            }

        case SET_DISABLED_MAKE_ORDER_BUTTON:
            return {
                ...state,
                disabledMakeOrderButton: action.payload
            }

        case LIST_INGREDIENTS:
            return {
                ...state,
                productIngredients: action.payload
            }


        default:
            return state;
    }
}