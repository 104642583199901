// import jwt from 'jsonwebtoken';
// import jwtDecode from 'jwt-decode';
import {
    SET_CURRENT_USER, LOGOUT, SET_LOGIN_MODAL_OPENED, SET_REGISTER_MODAL_OPENED, DEAUTHENTICATE_USER, CHANGE_PASSWORD_MODAL_OPENED,
    START_LOAGIND_LOGIN, END_LOAGIND_LOGIN
} from './types';
import setAuthorizationToken from './../../utils/setAuthorizationToken';
import axios from 'axios';
import Auth from './../../utils/AuthC';
import jwt from 'jsonwebtoken';
import history from './../../utils/history'
// import {history} from './../utils/history';
// import { history } from './../store';
import { API_URL } from '../../constants';
import { addErrorMessages } from './../../messages/messages.actions';

export const logout = () => dispatch => {
    // localStorage.removeItem('jwtToken');
    // auth.removeToken();
    // auth.removeUser();    
    // setAuthorizationToken(false);
    // history.push("/admin/login")
    // window.location.href='/admin/login';
    // return dispatch => {
    //     dispatch(setCurrentUser({}));
    // }

    Auth.removeToken();
    dispatch(deauthenticateUser());
    history.push("/login")
    return {
        type: LOGOUT
    }
}

export function login(data) {
    return dispatch => {

        dispatch({ type: START_LOAGIND_LOGIN })

        axios.post(API_URL + 'users/login', data).then(res => {
            const token = res.data.token;
            if (token) {
                Auth.setToken(token);
                setAuthorizationToken(token);
                dispatch(setCurrentUser(jwt.decode(token)));
                // history.push("/")
                dispatch({ type: END_LOAGIND_LOGIN })
                window.location.href = "/";
            } else {
                dispatch({ type: END_LOAGIND_LOGIN })
                dispatch(addErrorMessages(["Невалидни данни за вход"]));
            }
        })
            .catch(err => {
                dispatch({ type: END_LOAGIND_LOGIN })
                dispatch(addErrorMessages(["Невалидни данни за вход"]));
            })
    }
}

export function setCurrentUser(user) {
    return {
        type: SET_CURRENT_USER,
        user
    }
}

export function setLoginModalOpened(state) {
    return {
        type: SET_LOGIN_MODAL_OPENED,
        state
    }
}

export function setRegisterModalOpened(state) {
    return {
        type: SET_REGISTER_MODAL_OPENED,
        state
    }
}

export function deauthenticateUser(state) {
    return {
        type: DEAUTHENTICATE_USER
    }
}


export const setChangePasswordModalOpenedState = (state) => dispatch => {
    dispatch({
        type: CHANGE_PASSWORD_MODAL_OPENED,
        payload: state,
    })
}



export const submitChangePassword = (email, oldPass, newPass) => dispatch => {
    axios.post(API_URL + 'users/change-password', { email: email, password: oldPass, new_password: newPass })
        .then(result => {
            // dispatch(addErrorMessages(["DONE"]));
            dispatch(logout());
            return;
        })
        .catch(err => {
            dispatch(addErrorMessages(["Неверни данни"]));
        })
}
