import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import { register } from './serviceWorker';

import App from './App';
import store from './store';
import history from './utils/history';
import { getProductsToOrder, getAllProducts, getAllProductsIfOnePlace, clearCartLocal } from './products/actions/products.action';
import { getOrders } from './products/actions/products.action';
import { getCategoriesHierarchy } from './categories/actions/categories.action';
// import { getOrders } from './cart/actions/cart.action'; //TODO MAKE it works
import { getSettings } from './settings/actions/settings.action';
import { getPlaces } from './places/actions/places.actions';
import { verifyToken } from './qr-scan/actions/scan.actions';
import { setCurrentUser, logout } from './users/actions/users.actions';
import { getDistributorsForUserRegion } from './distributors/actions/distributors.actions';
import { getShopsForUser } from './shops/actions/shops.actions';
import { getRegions } from './regions/actions/regions.actions';

import Utils from './utils/Utils';
import setAuthorizationToken from './utils/setAuthorizationToken';
import { showHeaderCategory } from './categories/actions/categories.action';

import axios from 'axios';
import { API_URL, DEFAULT_PRODUCT_IMAGE } from './constants';
import jwt from 'jsonwebtoken';
// import jwtDecode from 'jwt-decode';

import Auth from './utils/AuthC';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

history.listen(location => {
    if (history.action === 'POP') {
        store.dispatch(showHeaderCategory(false));
    }
})


if(Auth.getToken()) {
    try {
        // jwtDecode(Auth.getToken());
        let decodedUser = jwt.decode(Auth.getToken());
        store.dispatch(setCurrentUser(decodedUser));
        setAuthorizationToken(Auth.getToken());

        store.dispatch(getRegions());
        store.dispatch(getDistributorsForUserRegion(decodedUser.regionId));
        store.dispatch(getShopsForUser(decodedUser.id));
        store.dispatch(getSettings());

        
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // dev code
            store.dispatch(getPlaces());
            // store.dispatch(getAllProductsIfOnePlace());
            store.dispatch(getAllProducts());

            store.dispatch(getCategoriesHierarchy());
            // store.dispatch(getSettings());
        } else {
            // production code
            let places = store.getState().places.places;
            
            // After scanning (/t/:token) -> don't take the prods and cats because ther are taken in verifyTokenPage.js
            if (!document.location.pathname.includes('/t/')) {
                if (places.length == 1) {
                    store.dispatch(getAllProducts());
                    store.dispatch(getCategoriesHierarchy())
                }
            }
        }
        // console.log('lognat')
        store.dispatch(getProductsToOrder());
        // store.dispatch(getOrders());

    } catch(e) {
        // console.log("ERRR ");
        console.log(e);
    }
}

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    console.log(error);
    if(error.response && error.response.status && error.response.status == 401) {
        store.dispatch(logout());
        return;
    } else {
        // if(error.response.data.error.errors) {
            // store.dispatch(addErrorMessages(error.response.data.error.errors));
            // TODO: Tuk moje napravo da vikam addErrorMsgs()
        // }
    }
    return Promise.reject(error);
});


// store.dispatch(getAllProducts());
// store.dispatch(getCategoriesHierarchy());

window.localStorage.setItem('hide_designes', 0); // OnRefresh reset "hide_designes" => Product themes options will be visible
window.localStorage.setItem('initial_translate', 1); // First initial translate flag
// console.log("ASNDANSDOKNSADONASODNKOASNDOASD")
const app =
    <Provider store={store}>
        <BrowserRouter history={history}>
            <App />
        </BrowserRouter>
    </Provider>

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// register();
