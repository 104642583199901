import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = theme => ({
    root: {
        maxHeight: "500px !important",
        bottom: "0px !important",
        top: "initial !important",
        '& .MuiDialog-paperScrollPaper': {
            width: '100%',
            height: '100%',
            margin: 0,
            maxWidth: 'unset',
            maxHeight: 'unset',
            borderRadius: 0,
            overflow: 'unset',
        }
    },
    backdrop: {
        zIndex: 2,
        color: '#fff',
        position: 'absolute !important', //backdrop not on the full screen
    },
    appBar: {
        position: "relative",
        backgroundColor: "rgb(203,65,2)",
        borderBottomLeftRadius: 11,
        borderBottomRightRadius: 11,
    },
    title: {
        marginLeft: 10,
        flex: 1,
        display: "flex",
        alignItems: "center",
        lineHeight: 1.1,
    },
    UserNeedsContainer: {
        overflowY: 'unset',
        paddingTop: 10,
        paddingBottom: 5,
        textAlign: 'left'
    },
    userNeedsBtn: {
        width: '100%',
        padding: 12,
        paddingLeft: 80,
        borderRadius: 0,
        background: '#f6f6f6',
        background: 'rgb(246, 246, 246)',
        borderBottom: '1px solid #ff7513',
        '& .MuiButton-label': {
            justifyContent: 'left',
        }
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class UserNeeds extends Component {
    render() {
        const { isLoadingUserNeeds, classes, addUserNeed, translations } = this.props;

        // User needs
        const USER_NEEDS = [
            { id: 1, text: translations.navbar && translations.pages && translations.pages.call_waiter ? translations.pages.call_waiter.clean_table : '', icon: 'https://tabl.bg/img/icons/clean_table.svg', needValue: "Clean table" },
            { id: 2, text: translations.navbar && translations.pages && translations.pages.call_waiter ? translations.pages.call_waiter.new_cutlery : '', icon: 'https://tabl.bg/img/icons/new_cutlery.svg', needValue: "New cutlery" },
            { id: 3, text: translations.navbar && translations.pages && translations.pages.call_waiter ? translations.pages.call_waiter.more_napkins : '', icon: 'https://tabl.bg/img/icons/more_napkins.svg', needValue: "Napkins" },
            { id: 4, text: translations.navbar && translations.pages && translations.pages.call_waiter ? translations.pages.call_waiter.change_ashtray : '', icon: 'https://tabl.bg/img/icons/change_ashtray.svg', needValue: "Change ashtray" },
            { id: 5, text: translations.navbar && translations.pages && translations.pages.call_waiter ? translations.pages.call_waiter.bring_ice : '', icon: 'https://tabl.bg/img/icons/bring_ice.svg' /*'https://image.flaticon.com/icons/svg/2458/2458112.svg'*/, needValue: "Ice" },
            { id: 6, text: translations.navbar && translations.pages && translations.pages.call_waiter ? translations.pages.call_waiter.other : '', icon: 'https://tabl.bg/img/icons/call_waiter.svg', needValue: "Other" },
        ];
        // Todo - add hotel needs in future

        return (
            <div>
                <Dialog
                    fullScreen
                    open={this.props.open}
                    onClose={this.props.closeModal}
                    TransitionComponent={Transition}
                    className={classes.root}
                    data-class="dialog-container"
                >

                    <Backdrop open={isLoadingUserNeeds} className={classes.backdrop}>
                        <CircularProgress className="is-loading-colored-only" />
                    </Backdrop>

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.props.closeModal}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {translations.navbar && translations.navbar.call_waiter ? translations.navbar.call_waiter : ''}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <Grid container spacing={2} className={classes.UserNeedsContainer} >
                        {Object.values(USER_NEEDS).map((needs, k) => (
                            <Grid item xs={12} style={{ padding: 0 }} key={k}>
                                <img src={needs.icon} style={{
                                    width: 35,
                                    position: 'absolute',
                                    zIndex: 1,
                                    marginTop: 7,
                                    marginLeft: 25,
                                }} />
                                <Button className={classes.userNeedsBtn} onClick={() => addUserNeed(needs.needValue)}>{needs.text}</Button>
                            </Grid>
                        ))}
                    </Grid>

                </Dialog>
            </div >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isLoadingUserNeeds: state.products.isLoadingUserNeeds || false,
    };
};

export default withStyles(useStyles)(connect(mapStateToProps, {})(UserNeeds));
