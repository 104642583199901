import axios from 'axios';
import { API_URL } from '../../constants';
import { getOrders } from './../../products/actions/products.action';

export const cancelOrder = (data) => dispatch => {

    axios
        .post(API_URL + 'orders/cancel', data)
        .then(res => {
            
            dispatch(getOrders());

        }).catch(error => {
            throw (error);
        });
}
