import React, { Component, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
import CircularProgress from '@material-ui/core/CircularProgress';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import { setChosenPlace } from './../../../places/actions/places.actions';
import Utils from './../../../utils/Utils';
import { getAllProducts } from './../../../products/actions/products.action';
import { getCategoriesHierarchy } from './../../../categories/actions/categories.action';
import { setChangePasswordModalOpenedState, submitChangePassword } from './../../../users/actions/users.actions'
import clsx from 'clsx';
import Auth from '../../../utils/AuthC';

const styles = theme => ({
    modal: {
        '& .MuiPaper-root': {
            width: '350px !important',
        }
    },
    modalTitle: {
        textAlign: 'center',
        backgroundColor: "rgb(203,65,2)",
        color: 'white',
        boxShadow: '0 1px 5px 0px black',
        '& .MuiTypography-root': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        }
    },
    dialogContent: {
        // minHeight: 100,
        'background-image': 'url(https://tabl.bg/img/white-shiny-background.jpg)',
        'background-position': 'center bottom',
        'background-attachment': 'fixed',
        'background-size': 'cover',
    },
    dialogContentText: {
        paddingTop: 10,
    },
    modalTypesBtns: {
        color: 'white',
        width: '100%',
        fontSize: 18,
        display: 'grid',
        // 'grid-template-rows': '50% 50%',
        'place-self': 'center',
        textAlign: 'center',
        // color: '#575757',
        borderRadius: 0,
        borderBottom: '1px solid #ff7600',
        backgroundColor: '#f9f9f9',
        minWidth: 180,
        '@media screen and (max-width: 475px)': {
            minWidth: '80%',
        },

        'border': '1px solid #ebebeb',

        // 'border': '1px solid #ff5200',
        // 'border-top-right-radius': 7,
        // 'border-top-left-radius': 7,

        'border-top-left-radius': 20,
        'border-bottom-right-radius': 20,

        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: 'none',

        height: 80,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    modalTypesBtnsBorderBottom: {
        'border-bottom': '1px solid #ff5200 !important',
    },
    modalTypesIcons: {
        fontSize: 42,
        color: '#ff7600',
    },
    isMultipleOptionsModalContainer: {
        margin: '20px 0 20px 0',
        display: 'grid',
        // 'grid-template-columns': '50% 50%',
        'place-self': 'center',
        textAlign: 'center',
        padding: 0,
        // '@media screen and (max-width: 475px)': {
        //     'grid-template-columns': 'none',
        //     // '& > *:first-child': {
        //     //     marginBottom: 40,
        //     // },
        //     margin: '20px 0 20px 0',
        // }
    },
    textField: {
        width: '100%',
        '& label.Mui-focused': {
            color: 'rgba(254, 105, 2, 0.7)',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'rgb(254, 105, 2)',
        },
    },

});

class ChoosePlaceModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            currentPassword: '',
            newPassword: '',
        }
    }

    componentWillReceiveProps(nextProps) {
        var places = nextProps.places;
        var chosen_place = nextProps.chosen_place;

        if (places.length > 0) {
            // && !Utils.getUserToken()
            if (places.length > 1 && !chosen_place) {
                this.setState({
                    opened: true
                })
            } else {
                this.setState({
                    opened: false
                })
            }
        }
    }

    choosePlace = (placeId) => {
        this.props.setChosenPlace(placeId, true);
        this.props.getCategoriesHierarchy(placeId)
        this.props.getAllProducts(0, placeId)
    }

    render() {
        const { classes, fullWidth = true, maxWidth = 'xs', open, handleClose, translations } = this.props;

        const user = Auth.getUserFromToken();

        return (
            <React.Fragment>

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={this.props.changePasswordModalOpened}
                    onClose={() => this.props.setChangePasswordModalOpenedState(false)}
                    aria-labelledby="max-width-dialog-title"
                    TransitionComponent={Grow}
                    // className={classes.modal}
                    data-class="dialog-container"
                >
                    {/* <CircularProgress className="is-loading language-modal" /> */}
                    <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} data-class="dialog-title-container">
                        {/* {translations.pages && translations.pages.choose_place && translations.pages.choose_place.title ? translations.pages.choose_place.title : ''} */}
                        Смяна на парола
                    </DialogTitle>

                    <DialogContent
                        className={classes.dialogContent}
                    >
                        <DialogContentText
                        // className={classes.dialogContentText}
                        >

                            <form
                            // onSubmit={props.onSubmit}
                            >
                                <div style={{ marginBottom: 10 }}></div>

                                <TextField className={classes.textField} type="password"
                                    onChange={(e) => this.setState({ currentPassword: e.target.value })}
                                    name="name" label="Текуща парола" required style={{ width: '100%' }} />

                                <br /><br />

                                <TextField className={classes.textField} type="password"
                                    onChange={(e) => this.setState({ newPassword: e.target.value })}
                                    name="address" label="Нова парола" required style={{ width: '100%' }} />
                                <br /><br />

                                <Button variant="contained" style={{ color: 'rgb(203,65,2)', width: '100%', boxShadow: 'none' }}
                                    onClick={() => this.props.submitChangePassword(user.email, this.state.currentPassword, this.state.newPassword)}
                                >
                                    Промени
                            </Button>

                            </form>
                        </DialogContentText>
                    </DialogContent>

                </Dialog>
                {/* </Grow> */}
            </React.Fragment>
        );
    }

}


const mapStateToProps = (state, ownProps) => {
    return {
        places: state.places.places || [],
        chosen_place: state.places.chosen_place || null,
        translations: state.lang.translations,
        changePasswordModalOpened: state.users.changePasswordModalOpened || false,
    };
};

export default withStyles(styles)(connect(mapStateToProps, { setChosenPlace, getAllProducts, getCategoriesHierarchy, setChangePasswordModalOpenedState, submitChangePassword })(ChoosePlaceModal)
)

