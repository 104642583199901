import { GET_DISTRIBUTORS } from './types';
import axios from 'axios';

import { API_URL } from '../../constants';
import Auth from './../../utils/AuthC';

export const getDistributorsForUserRegion = (region_id = 0) => dispatch => {
    let query = '';

    if(Auth.getUserFromToken().role == "SUPER_ADMIN") {

    } else {
        query = "?user_id="+ Auth.getUserFromToken().id;
    }


    axios.get(API_URL + 'distributors' + query)
        .then(result => {
            dispatch({
                type: GET_DISTRIBUTORS,
                payload: result.data
            })
        })
        .catch(err => {
        })
}
