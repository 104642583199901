import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";
import FeedbackAnswersList from './FeedbackAnswersList';
import FeedbackQuestions from './FeedbackQuestions';
import Fade from '@material-ui/core/Fade';
import { getFeedbackQuestions, sendFeedback, getFeedbackAnswers } from './../actions/feedback.actions';
import { showHeaderCategory } from '../../categories/actions/categories.action';
import { setMainBackground } from '../../settings/actions/settings.action';
import Utils from "../../utils/Utils";
import clsx from 'clsx';

const styles = theme => ({
    feedBackContainer: {
        // backgroundColor: 'rgba(147, 147, 147, 0.4)',
        minHeight: 'calc(100vh - 55px)',
        marginTop: 55,
        paddingLeft: '10%',
        paddingRight: '10%',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    },
    feedBackTitle: {
        color: '#efefef',
        textAlign: 'center',
        fontSize: 18,
    },
    feedBackTitleDark: {
        color: '#1e1e1e'
    }
});

class FeedbackPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: []
        }
    }

    componentWillMount() {
        this.props.getFeedbackQuestions();
        this.props.getFeedbackAnswers();
    }

    componentDidMount() {
        this.props.setMainBackground('linear-gradient( rgba(147, 147, 147, 0.4), rgba(147, 147, 147, 0.4) )');
    }

    // componentDidUpdate() {
    //     let { settings } = this.props;

    //     if (settings && settings.about_page_title && settings.about_page_title.value) {
    //         this.props.showHeaderCategory(this.props.translations && this.props.translations.common ? this.props.translations.common.action.back : '');
    //     }
    // }   

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.type === 'number' ? parseFloat(e.target.value) : e.target.value });
    }

    handleAnswerFill = (e, questionType, newV, qId) => {
        let answers = this.state.answers;
        let newAnswers = [];
        newAnswers['feedbackquestionId'] = qId;
        newAnswers['text'] = (questionType == 'text') ? newV : '';
        newAnswers['stars'] = (questionType == 'text') ? 0 : newV;
        answers.push(Object.assign({}, newAnswers));
        this.setState({ answers });
    }

    handleFormSubmit = () => {
        this.props.sendFeedback(Object.values(Object.assign({}, this.state.answers)));
    }

    render() {
        const { classes, feedback_questions, feedback_answers, translations, settings } = this.props;

        let feedbackQuestions = Object.values(feedback_questions);
        let feedbackAnswers = Object.values(feedback_answers);
        // if (settings && settings.about_page_title && settings.about_page_title.value) { //Bugfixed
            this.props.showHeaderCategory(this.props.translations && this.props.translations.common ? this.props.translations.common.action.back : '');
        // }

        return (
            <Fade in={true} timeout={{ enter: 350, exit: 350 }}>

                <div className={classes.feedBackContainer}>
                    <br />

                    <h2 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.feedBackTitle, classes.feedBackTitleDark) : classes.feedBackTitle}>{translations.pages && translations.pages.feedback ? translations.pages.feedback.title : ''}</h2>

                    <FeedbackQuestions
                        feedbackQuestions={feedbackQuestions}
                        handleFormSubmit={this.handleFormSubmit}
                        handleAnswerFill={this.handleAnswerFill}
                        translations={translations}
                    />

                    <br />
                    <hr />

                    <FeedbackAnswersList
                        answers={feedbackAnswers}
                        translations={translations}
                        settings={settings}
                    />

                </div>
            </Fade>
        )
    }

}


const mapStateToProps = (state, ownProps) => {

    return {
        feedback_questions: state.feedback.feedback_questions || [],
        feedback_answers: state.feedback.feedback_answers || [],
        translations: state.lang.translations || [],
        settings: state.settings.settings || [],
        // message: state.feedback.message || null
    };
    // catName: state.name,
    // products: state.products
};

export default withStyles(styles)(connect(mapStateToProps, { showHeaderCategory, getFeedbackQuestions, sendFeedback, getFeedbackAnswers, setMainBackground })(FeedbackPage));
