import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SearchIcon from '@material-ui/icons/Search';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import LanguageIcon from '@material-ui/icons/Language';
import CommentIcon from '@material-ui/icons/Comment';
import MenuIcon from '@material-ui/icons/Menu';
import InfoIcon from '@material-ui/icons/Info';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookIcon from '@material-ui/icons/Book';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import RoomIcon from '@material-ui/icons/Room';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import DirectionsIcon from '@material-ui/icons/Directions';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import { useHistory } from 'react-router-dom';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import Utils from '../../utils/Utils';
import ImageIcon from '@material-ui/icons/Image';
import ShopIcon from '@material-ui/icons/Shop';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles(theme => ({
    speedDial: {
        position: 'fixed',
        zIndex: 1100,
        padding: 0,
        top: 0,

        // // Each SpeedDial container
        // '& .MuiSpeedDialAction-staticTooltip:last-child': {
        //     'margin-top': '15px',
        //     'border-top': '1px solid rgba(255, 255, 255, 0.69)',
        //     'padding-top': '12px',
        // },

        // Header open button
        '& .MuiSpeedDial-fab': {
            boxShadow: 'none',
            background: 'inherit',
            borderRadius: 0,
            height: 55,
            width: 100,
            '@media screen and (min-width: 600px)': {
                borderRight: '1px solid #ffffff4d',
            },
        },
        '& .MuiSpeedDial-fab:hover': {
            background: 'rgba(0, 0, 0, 0.04)',
        },

        // Option label
        '& .MuiSpeedDialAction-staticTooltipLabel': {
            whiteSpace: 'nowrap',
            fontSize: 16,
            background: '#eaeaea',
            borderBottom: '2px solid #FE6902',
            boxShadow: '2px 2px 5px 1px #555',
            color: '#464646',
            fontWeight: 'bold',
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        },

        // Option button hover
        '& .MuiSpeedDialAction-fab:hover': {
            background: '#db4600',
            color: '#e7e7e7',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.3), 0px 6px 10px 0px rgba(0,0,0,0.24), 0px 1px 18px 0px rgba(0,0,0,0.22)'
        },
    },
    // Option button
    speedDialAction: {
        width: 55,
        height: 55,
        borderRadius: 5,
        backgroundColor: '#FF5900',
        // // backgroundColor: 'rgb(254,105,2)',
        // backgroundColor: 'rgb(203,65,2)',
        color: 'white',
    },
    // Option button icon
    speedDialTooltipIcons: {
        fontSize: '1.7rem',
    }
}));


const SpeedDialTooltipOpen = (props) => {
    const { settings, translations } = props;
    let history = useHistory();

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    let actions = []

    // Orders
    actions.push(
        { icon: <ShopIcon className={classes.speedDialTooltipIcons} />, name: translations && translations.navbar && translations.navbar.orders, onClick: props.openOrdersPage }
    );

    // Profile
    actions.push(
        { icon: <AccountCircleIcon className={classes.speedDialTooltipIcons} />, name: translations && translations.navbar && translations.navbar.profile, onClick: props.openProfilePage }
    );

    // Logout
    actions.push(
        { icon: <ExitToAppIcon className={classes.speedDialTooltipIcons} />, name: translations && translations.navbar && translations.navbar.logout, onClick: props.logout }
    );

    // Restaurant direction
    // if (settings && settings.show_restaurant_location && settings.show_restaurant_location.value == 1 && Utils.getSettingsValue(settings, 'show_restaurant_location_position') == 'header') {
    //     actions.push({ icon: <DirectionsIcon className={classes.speedDialTooltipIcons} />, name: translations && translations.navbar && translations.navbar.restaurant_location, onClick: () => { window.open('https://www.google.com/maps/dir/?api=1&destination=' + (settings.place_address ? settings.place_address.value : ''), '_blank'); } })
    // }

    // // Make reservation
    // if (settings && settings.allow_reservations && settings.allow_reservations.value == 1 && Utils.getSettingsValue(settings, 'reservations_position') == 'header') {
    //     actions.push({ icon: <BeenhereIcon className={classes.speedDialTooltipIcons} />, name: translations && translations.navbar && translations.navbar.make_reservations, onClick: () => window.location.host == 'demo.tabl.bg' ? window.open('https://reservations.tabl.bg') : window.open('/reservations') })
    // }

    // Search
    if (settings && settings.call_btn_instead_of_search && settings.call_btn_instead_of_search.value.length > 0 && Utils.getSettingsValue(settings, 'search_btn_position') == 'header') {
        actions.push({ icon: <SearchIcon className={classes.speedDialTooltipIcons} />, name: translations && translations.navbar && translations.navbar.search_product, onClick: () => { props.toggleSearch() } });
    }

    // Call Waiter
    // if (Utils.getSettingsValue(settings, 'user_needs_position') == 'header' 
    // && (settings && settings.order_type && (settings.order_type.value == 1 || settings.order_type.value == 5 || settings.order_type.value == 6 || settings.order_type.value == 7))
    // && Utils.getSettingsValue(settings, 'call_waiter_footer_button') != '1') {
    //     actions.push({ icon: <RecordVoiceOverIcon className={classes.speedDialTooltipIcons} />, name: translations && translations.navbar && translations.navbar.call_waiter, onClick: () => { props.openUserNeedsModal() } })
    // }

    // Change language
    if (Utils.getSettingsValue(settings, 'change_language_position') == 'header') {
        actions.push({ icon: <LanguageIcon className={classes.speedDialTooltipIcons} />, name: translations && translations.navbar && translations.navbar.change_language, onClick: props.openLanguageModal })
    }

    // Change table
    // if (Utils.getSettingsValue(settings, 'show_change_table') == 1) {
    //     actions.push({ icon: <OpenInBrowserIcon className={classes.speedDialTooltipIcons}/>, name: (translations && translations.common && translations.common.change_table), onClick: props.changeTable });
    // }

    // About Restaurant
    // if ((settings && settings.about_page_title && settings.about_page_title.value.length > 0) && (Utils.getSettingsValue(settings, 'about_page_btn_position') == 'header')) {
    //     actions.push({ icon: settings && settings.is_hotel && settings.is_hotel.value == 1 ? <HomeWorkIcon className={classes.speedDialTooltipIcons}/> : <RestaurantMenuIcon className={classes.speedDialTooltipIcons}/>, name: (settings ? (settings.about_page_title ? settings.about_page_title.value : '') : (settings.meta_title_place_name ? settings.meta_title_place_name.value : '')), onClick: props.openAboutRestaurant });
    // }   

    
    // Gallery
    // if (Utils.getSettingsValue(settings, 'show_place_gallery') == '1') {
    //     actions.push({ icon: <ImageIcon className={classes.speedDialTooltipIcons}/>, name: (translations && translations.common && translations.common.gallery), onClick: props.openGallery });
    // }   
    
    // Feedback
    // actions.push(
    //     { icon: <CommentIcon className={classes.speedDialTooltipIcons} />, name: translations && translations.navbar && translations.navbar.feedback, onClick: props.openFeedback }
    // );



    // About TabL
    // actions.push({ icon: <InfoIcon className={classes.speedDialTooltipIcons} />, name: <>{translations && translations.common && translations.common.about} <span className="skiptranslate">TabL</span></>, onClick: props.openAboutUs });

    const toggleOpen = () => {
        setOpen(!open)
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    // const backgrounds = [
    //     'rgb(254,105,2)', 
    //     'rgb(254,97,2)', 
    //     'rgb(227,84,2)', 
    //     'rgb(211,71,2)', 
    //     'rgb(203,65,2)', 
    // ]

    return (
        <div className={classes.root}>

            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                className={classes.speedDial}
                icon={<MenuIcon style={{ fontSize: '1.8rem' }} />}
                onClose={handleClose}
                onClick={toggleOpen}
                open={open}
                direction={"down"}
                size="large"
                data-class="top-speed-dial-container"
            >
                <Backdrop open={open} />

                {actions.map((action, i) => (
                    <SpeedDialAction
                        className={classes.speedDialAction}
                        // style={{backgroundColor:backgrounds[i]}}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        tooltipPlacement="right"
                        onClick={() => { action.onClick(); handleClose(); }}
                        data-class="each-top-speed-dial-button"
                    />
                ))}
            </SpeedDial>

            {/* <div style={{ marginLeft: 160, zIndex: 12356, position: 'relative', top: 13px, left: 136px; }}>
                <SearchIcon style={{font-size: '1.8em'}/>
            </div> */}

        </div>
    );
}

export default SpeedDialTooltipOpen;