import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Switch, Route, Router } from 'react-router-dom';
import history from './utils/history';
import AppNavbar from './common/navbar/AppNavbar'

import CategoriesPage from './categories/components/CategoriesPage';
import ProductsPage from './products/components/ProductsPage';
// import SignInPage from './components/auth/signInPage';
// import ScannerPage from './components/qr_scanner/scannerPage';
// import LogoutPage from './components/auth/logoutPage';
import Page404 from './common/errors/404';
// import PreorderPage from './components/preorder/PreorderPage';
import FeedbackPage from './feedback/components/FeedbackPage';
import SearchPage from './search/components/SearchPage';
import AboutUsPage from './aboutus/components/AboutUsPage';
import AboutRestaurantPage from './aboutus/components/AboutRestaurantPage';
import AllProductsPage from './products/components/AllProductsPage';
import SubcatsWithProductsPage from './products/components/SubcatsWithProductsPage';
import SubcatsWithProductsSluggedPage from './products/components/SubcatsWithProductsSluggedPage';
import NavigateToComponent from './common/NavigateToComponent';
import ScanPage from './qr-scan/components/scanPage';
import VerifyTokenPage from './qr-scan/components/VerifyTokenPage';
import CategoriesHierarchyPage from './categories/components/CategoriesHierarchyPage';

const RouterMultiplePlaces = (props) => {

    // useEffect(() => {
    //     // if(!props.fetched) {
    //     //     props.fetchRules();
    //     // }
    // }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour


    let mainPage;
    let additionalBeginRoute = "";
    if (props.settings.no_categories_view) {
        if (props.settings.no_categories_view.value == 0) {
            mainPage = <Route exact path='/' component={CategoriesPage} />
        } else if (props.settings.no_categories_view.value == 1) {
            mainPage = <Route exact path='/' component={AllProductsPage} />
        } else if (props.settings.no_categories_view.value == 2) {
            mainPage = <Route exact path='/' component={SubcatsWithProductsPage} />
        } else if (props.settings.no_categories_view.value == 3) {
            mainPage = <Route path='/:subcatSlug' component={SubcatsWithProductsSluggedPage} />
            additionalBeginRoute = <Route exact path='/' component={SubcatsWithProductsSluggedPage} />
        } else {

            mainPage = <Route exact path='/:catSlug' component={CategoriesHierarchyPage} />
            additionalBeginRoute = <Route exact path='/' component={CategoriesHierarchyPage} />
        
        }
    }

    return (
        <div>
            <Router history={history}>
                <div>
                    <AppNavbar />
                    <Switch>
                        {mainPage}
                        <Route exact path='/products/:subcat_id' component={ProductsPage} />
                        <Route exact path='/feedback' component={FeedbackPage} />
                        <Route exact path='/search' component={SearchPage} />
                        <Route exact path='/about-tabl' component={AboutUsPage} />
                        <Route exact path='/subcategories/:subcat_id/products' component={NavigateToComponent} />
                        <Route exact path='/scan' component={ScanPage} />
                        <Route component={Page404} />

                        {mainPage}
                        {additionalBeginRoute}

                        {/* <Route exact path='/signin' component={SignInPage} />
                        <Route exact path='/categories/:cat_id' component={ProductsListPage} />
                        <Route exact path='/scanTable' component={ScannerPage} />
                        <Route exact path='/preorder' component={PreorderPage} />
                        <Route exact path='/logout' component={LogoutPage} /> */}
                        {/* <Route path="*" exact={true} component={Page404} /> */}
                    </Switch>
                </div>

            </Router>
        </div>
    )
}

// export default MyRouter;


const mapStateToProops = (state, ownProps) => {
    return {
        settings: state.settings.settings || [],
    };
};

export default (connect(mapStateToProops, {})(RouterMultiplePlaces));