import React, { Component, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grow } from '@material-ui/core/';
import Utils from './../../utils/Utils';
import { setRegisterModalOpened } from './../../users/actions/users.actions';
import { getAllProducts, addToCart, changeSelectedCartTab, removeProductFromCart } from './../../products/actions/products.action';
import { getCategoriesHierarchy } from './../../categories/actions/categories.action';
// import { setVariantModalOpened, setSelectedProduct } from './../../cart/actions/cart.actions';

// import { withTracking } from 'react-tracker';
// import { productClick } from './../../tracking/events/productEvents';

import clsx from 'clsx';;

const HOW_MANY_TIMES_TO_CLICK_CLOSE_FOR_STOP_SHOWING_ON_THE_SAME_DAY = 2;
// const currentDate = new Date();

const styles = theme => ({
    modal: {
        '& .MuiPaper-root': {
            width: '350px !important',
        }
    },
    modalTitle: {
        textAlign: 'center',
        backgroundColor: "rgb(203,65,2)",
        color: 'white',
        boxShadow: '0 1px 5px 0px black',
        '& .MuiTypography-root': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        }
    },
    dialogContent: {
        minHeight: 100,
        padding: 0,
    },
    dialogContentText: {
        padding: 20,
        fontSize: 18,
    },
    fastOrderButtonProduct: {
        color: 'black',
        width: '100%',
        fontSize: 16,
        // display: 'grid',
        // 'grid-template-rows': '50% 50%',
        // 'place-self': 'center',
        // textAlign: 'center',
        // color: '#575757',
        borderRadius: 0,
        // borderBottom: '1px solid #ff7600',
        // backgroundColor: '#f9f9f9',
        minWidth: 180,
        '@media screen and (max-width: 475px)': {
            minWidth: '80%',
        },

        // 'border': '1px solid #ebebeb',

        'border-bottom': '1px solid #ff5200',
        // 'border-top-right-radius': 7,
        // 'border-top-left-radius': 7,

        // paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,

        padding: 15,

        // // display: 'grid',
        // 'grid-template-columns': '70% 30%',
        // borderBottom: 'none',

        // height: 80,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        'border-bottom': '1px solid #ff5200 !important',
        margin: '0 !important',
    },
    activeFastOrderButtonProduct: {
        background: 'rgb(204, 204, 204)'
    },
    // modalTypesBtnsBorderBottom: {
    //     'border-bottom': '1px solid #ff5200 !important',
    // },
    modalTypesIcons: {
        fontSize: 42,
        color: '#ff7600',
    },
    isMultipleOptionsModalContainer: {
        padding: 0,
        // margin: '40px 0 45px 0',
        display: 'grid',
        // 'grid-template-columns': '50% 50%',
        'place-self': 'center',
        textAlign: 'center',
        padding: 0,
        '@media screen and (max-width: 475px)': {
            'grid-template-columns': 'none',
            // '& > *:first-child': {
            //     marginBottom: 40,
            // },
            // margin: '20px 0 45px 0',
        }
    },
    submitButton: {
        width: '100%',
        color: 'white',
    },
    closeButton: {
        width: '100%',
        color: 'black',
        background: '#dbdbdb',
    },
    variantCheckbox: {
        margin: 0,
        color: 'rgb(254, 105, 2)',
        '&.Mui-checked': {
            color: 'rgb(254, 105, 2)',
        },
    },
    singleProductVariantLabel: {
        background: '#dfdfdf',
        padding: 5,
        borderRadius: 6,
        fontSize: 15,
        fontWeight: 'normal !important',
    },
    productImage: {
        // marginBottom: 10,
        maxHeight: 75,
        maxWidth: 100,
        width: 'auto',
        margin: '0 auto',
    }
});


class InitialBannerFastOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            initialLoad: 1,
            // selectedProducts: [],
        }
    }


    render() {
        const { classes, fullWidth = true, maxWidth = 'xs', open, handleClose, translations, allProducts, settings } = this.props;

        return (
            <React.Fragment>

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={true}
                    onClose={this.onClose}
                    aria-labelledby="max-width-dialog-title"
                    TransitionComponent={Grow}
                    // className={classes.modal}
                    data-class="dialog-container"
                >
                    {/* <CircularProgress className="is-loading language-modal" /> */}
                    <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} data-class="dialog-title-container">
                        {/* {translations.pages && translations.pages.choose_place ? translations.pages.choose_place.title : ''} */}
                        Уважаеми клиенти
                    </DialogTitle>

                    <DialogContent className={classes.dialogContent} >
                        <DialogContentText className={classes.dialogContentText} >
                            Системата е <i>деактивирана</i>. Благодарим за разбирането!

                            <br /><br />

                            За да бъде активирано обратнa, е необходимо да се свържете с нас.

                            <br /><br />

                            - TabL.BG
                        </DialogContentText>
                    </DialogContent>
                    {/* <DialogActions>
                        <Button
                            // disabled={Object.values(this.props.productsToOrder).length == 0}
                            // background-color: #dbdbdb;
                            // color: #2a2a2a;
                            // style={{ backgroundColor: Object.values(this.props.productsToOrder).length == 0 ? '#dbdbdb' : '#ff5101', }}
                            style={{ backgroundColor: '#ff5101' }}
                            className={classes.submitButton}
                            onClick={() => window.location.href = 'https://tabl.bg/kontakti'}
                            color="red"
                            data-class="initial-banner-fast-order-submit-button"
                        >
                            Свържете се с TabL.BG
                        </Button>
                    </DialogActions> */}

                </Dialog>
                {/* </Grow> */}
            </React.Fragment >
        );
    }

}


const mapStateToProps = (state, ownProps) => {
    return {
        // places: state.places.places || [],
        // chosen_place: state.places.chosen_place || null,
        // allProducts: state.products.all_products || [],
        // products: state.products || [],
        // productsToOrder: state.products.to_order || [],
        // settings: state.settings.settings || [],
        // to_order: state.products.to_order || [],
    };
};

// const mapTrackingToProps = trackEvent => ({
//     trackProductClick: (page, action_type, category_id = null, product_id = null) => trackEvent(productClick(page, action_type, category_id, product_id)),
//     // trackPageView: (pageId, userId) => trackEvent(pageView(pageId, userId))
// });

export default (withStyles(styles)(InitialBannerFastOrder))