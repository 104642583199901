import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui//core/Grow';
import clsx from 'clsx';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';

const useStyles = makeStyles(theme => ({
    footerContainer: {
        background: 'rgba(0, 0, 0, 0.38)',
        overflow: 'hidden',
    },

    footerContainerItems: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        // '@media screen and (max-width: 550px)': {
        //     gridTemplateColumns: '40% 40%',
        // },
    },

    iconContainer: {
        color: '#ddd',
        transition: 'all .2s',
        transform: 'scale(1)',

    },

    socialTabContainer: {
        padding: '10px 0',
        display: 'block',
        textAlign: 'center',
        '&:hover .iconContainer': {
            transform: 'scale(1.3)',
        }
    },

    iconTextContainer: {
        color: '#efefef'
    },

    footerText: {
        color: 'white',
        textAlign: 'center'
    }

}));


const ProductsFooterContainer = (props) => {
    const classes = useStyles();
    const { settings, translations } = props;

    return (
        <div className={classes.footerContainer} data-class="products-footer-container">
            <div className={classes.footerContainerItems}>
                {settings.footer_in_products_page_facebook && settings.footer_in_products_page_facebook.value ?
                    <div>
                        <a href={settings.footer_in_products_page_facebook.value} target="_blank" rel="noopener noreferrer" className={clsx("text-decoration-none", classes.socialTabContainer)}>
                            <div className={clsx(classes.iconContainer, 'iconContainer')}><FacebookIcon /></div>
                            <div className={classes.iconTextContainer} data-class="each-footer-link-description">{translations.translations && translations.translations.common && translations.translations.common.like_us}</div>
                        </a>
                    </div>
                    :
                    null
                }

                {settings.footer_in_products_page_instagram && settings.footer_in_products_page_instagram.value ?
                    <div>
                        <a href={settings.footer_in_products_page_instagram.value} target="_blank" rel="noopener noreferrer" className={clsx("text-decoration-none", classes.socialTabContainer)}>
                            <div className={clsx(classes.iconContainer, 'iconContainer')}><InstagramIcon /></div>
                            <div className={classes.iconTextContainer} data-class="each-footer-link-description">{translations.translations && translations.translations.common && translations.translations.common.follow_us}</div>
                        </a>
                    </div>
                    :
                    null
                }

                {settings.footer_in_products_page_youtube && settings.footer_in_products_page_youtube.value ?
                    <div>
                        <a href={settings.footer_in_products_page_youtube.value} target="_blank" rel="noopener noreferrer" className={clsx("text-decoration-none", classes.socialTabContainer)}>
                            <div className={clsx(classes.iconContainer, 'iconContainer')}><YouTubeIcon /></div>
                            <div className={classes.iconTextContainer} data-class="each-footer-link-description">{translations.translations && translations.translations.common && translations.translations.common.youtube}</div>
                        </a>
                    </div>
                    :
                    null
                }

                {settings.footer_in_products_page_tripadvisor && settings.footer_in_products_page_tripadvisor.value ?
                    <div>
                        <a href={settings.footer_in_products_page_tripadvisor.value} target="_blank" rel="noopener noreferrer" className={clsx("text-decoration-none", classes.socialTabContainer)}>
                            <div className={clsx(classes.iconContainer, 'iconContainer')}><img src='https://gabrovo.pizza/files/img/icons/tripadvisor-64.png' width="25" /></div>
                            <div className={classes.iconTextContainer} data-class="each-footer-link-description">{translations.translations && translations.translations.common && translations.translations.common.tipadvisor}</div>
                        </a>
                    </div>
                    :
                    null
                }

            </div>

            {settings.footer_text_in_products_page_tripadvisor && settings.footer_text_in_products_page_tripadvisor.value ?
                <div data-class="products-footer-text" className={classes.footerText}
                    dangerouslySetInnerHTML={{
                        __html: settings.footer_text_in_products_page_tripadvisor.value
                    }}>
                </div>
                :
                null
            }

        </div>
    );

}

export default ProductsFooterContainer;