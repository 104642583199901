import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    modal: {
        '& .MuiPaper-root': {
            width: '350px !important',
        }
    },
    modalTitle: {
        textAlign: 'center',
        backgroundColor: "rgb(203,65,2)",
        color: 'white',
        boxShadow: '0 1px 5px 0px black',
        '& .MuiTypography-root': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        }
    },
    dialogContent: {
        minHeight: 100,
    },
    dialogContentText: {
        paddingTop: 10,
    }
}));

export default function MaxWidthDialog(props) {
    const { fullWidth = false, maxWidth = 'xs', open, handleClose, translations } = props;
    const classes = useStyles();

    useEffect(() => {
        if (open === true) {
            if (props.translationsType == 1) {
                setTimeout(function () {
                    let mainTranslationContainer = document.getElementById('gTanslateElem');
                    const secondaryTranslationContainer = document.getElementById('gTanslateElem2');
                    const modalLoader = document.querySelector('.language-modal.is-loading');

                    if (mainTranslationContainer === null) {
                        mainTranslationContainer = document.createElement('div');
                        mainTranslationContainer.id = 'gTanslateElem';
                    }

                    secondaryTranslationContainer.appendChild(window.gTranslateElemInit().X);

                    if (mainTranslationContainer && secondaryTranslationContainer && secondaryTranslationContainer.parentNode != mainTranslationContainer) {
                        secondaryTranslationContainer.appendChild(mainTranslationContainer);
                        secondaryTranslationContainer.style.display = 'block';
                        mainTranslationContainer.style.display = 'block';
                    }

                    modalLoader.style.display = 'none';
                }, 500);
            } else {
                setTimeout(function () {
                    const modalLoader = document.querySelector('.language-modal.is-loading');
                    modalLoader.style.display = 'none';
                }, 500);
            }

        }
    })

    let langOptions = Object.values(props.languages).map(l => {
        return (
            <option key={l.id} value={l.code}>{l.code}</option>
        )
    })

    let trans = props.translationsType == 1 ?
        (
            <div id="gTanslateElem2" style={{ display: "none" }}></div>) :
        (
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group" style={{ 'text-align': 'center' }}>
                        <select className="form-control" id="currLang" type="text" name="currLang"
                            style={{
                                width: '20%',
                                height: 40
                            }}
                            onChange={props.currLangOnChange} value={props.currLang}>
                            {langOptions}
                        </select>
                    </div>
                </div>
            </div>
        );

    return (
        <React.Fragment>

            <div id="gTanslateElem" style={{ display: "none" }}></div>

            <Dialog
                TransitionComponent={Grow}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
                className={classes.modal}
                data-class="dialog-container"
            >
                <CircularProgress className="is-loading language-modal" />
                <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} data-class="dialog-title-container">{translations.pages && translations.pages.change_language ? translations.pages.change_language.title : ''}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <DialogContentText className={classes.dialogContentText}>

                        {/* <div id="translate-container"></div> */}
                        {trans}

                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ display: 'block' }}>
                    <Button
                        onClick={props.handleClose}
                        style={{ color: 'black' }}
                    >
                        Затвори
                    </Button>

                    <Button
                        className="skiptranslate"
                        onClick={() => window.location.reload(false)}
                        style={{ color: '#FE6902', fontWeight: 'bold', float: 'right' }}
                        className="notranslate" id="revert_translation"
                    >
                        Обратно на Български
                    </Button>
                </DialogActions>
            </Dialog>
            {/* </Grow> */}
        </React.Fragment >
    );
}