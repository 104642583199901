import { GET_REGIONS } from './types';
import axios from 'axios';

import { API_URL } from '../../constants';
import Auth from './../../utils/AuthC';

export const getRegions = () => dispatch => {
    let query = '';
    // if(region_id != 0) {
    //     query = "?region_id="+region_id;
    // }

    // console.log("USERRRRR", Auth.getUserFromToken());

    if(Auth.getUserFromToken()) {
        if(Auth.getUserFromToken().role != "SUPER_ADMIN") {
            query = "?user_id=" + Auth.getUserFromToken().id;
        }
    }

    axios.get(API_URL + 'regions'+query)
        .then(result => {
            dispatch({
                type: GET_REGIONS,
                payload: result.data
            })
        })
        .catch(err => {
        })
}
