export
    const styles = theme => ({
        backdrop: {
            zIndex: 2,
            color: '#fff',
            position: 'absolute !important', //backdrop not on the full screen
        },
        modal: {
            '& .MuiPaper-root': {
                width: '100% !important',
            },
            '& .MuiDialogContent-root': {
                paddingTop: 5,
                paddingBottom: 0,
            }
        },
        textFieldContainer: {
            margin: '15px 0',
            '&:nth-of-type(1)': {
                marginTop: 0,
            },
            '&:last-child': {
                marginBottom: 0,
            }
        },
        textField: {
            width: '100%',
            '& label.Mui-focused': {
                color: 'rgba(254, 105, 2, 0.7)',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: 'rgb(254, 105, 2)',
            },
        },
        errorContainer: {
            color: '#ff0c00f0',
            textAlign: 'center',
            fontWeight: 'bold',
            borderBottom: '1px solid #ff0c00f0',
            marginTop: 17,
            marginBottom: 5,
        },
        rememberMe: {
            margin: 0,
            color: 'rgb(254, 105, 2)',
            '&.Mui-checked': {
                color: 'rgb(254, 105, 2)',
            },
        },
        promoCodeBtn: {
            color: 'rgba(0, 0, 0, 0.54)',
            float: 'right',
            borderRadius: 0,
            padding: '10px 0',
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
        applyPromoCodeBtn: {
            textAlign: 'center',
            width: '100%',
            borderRadius: 0,
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        },
        buttonsRow: {
            marginBottom: 0,
        },
        promoCodeRow: {
            marginTop: 10,
            marginBottom: 5,
        },
        preorderAddressContainer: {
            marginTop: 10,
        },
        preorderAddress: {
            marginTop: 5,
            borderRadius: 5,
            backgroundColor: '#e6e6e6',
            padding: '5px 11px',
            display: 'grid',
            'grid-template-columns': '70% 30%',
        },
        preorderAddressText: {
            placeSelf: 'center',
            textAlign: 'center',
            padding: 5,
        },
        preorderAddressNavigateBtn: {
            width: '100%',
            placeSelf: 'center',
            verticalAlign: 'bottom',
            borderLeft: '1px solid #ccc',
            textAlign: 'center',
        },
        preorderAddressNavigateIcon: {
            fontSize: 30,
            color: '#0064ac',
        },
        pickupTimeOptionsContainer: {
            display: 'grid',
            gridTemplateColumns: '47% 47%',
            columnGap: '6%',
        },

        orderDisabledContainer: {
            background: 'gray',
            width: '95%',
            background: 'gray',
            margin: '0 auto',
            textAlign: 'center',
            marginTop: 20,
            // background: 'repeating-linear-gradient(45deg, #606dbc, #606dbc 10px, #465298 10px, #465298 20px)',
            backgroundColor: '#ff0c00f0',
            padding: 5,
            color: 'white',
            borderRadius: 5
        },
        isMultipleOptionsModalContainer: {
            margin: '40px 0 45px 0',
            display: 'grid',
            'grid-template-columns': '50% 50%',
            'place-self': 'center',
            textAlign: 'center',
            padding: 0,
            '@media screen and (max-width: 475px)': {
                'grid-template-columns': 'none',
                '& > *:first-child': {
                    marginBottom: 40,
                },
                margin: '20px 0 45px 0',
            }
        },
        modalTypesBtnsBorderBottom: {
            'border-bottom': '1px solid #ff5200 !important',
        },
        modalTypesBtns: {
            display: 'grid',
            // 'grid-template-rows': '50% 50%',
            'place-self': 'center',
            textAlign: 'center',
            color: '#575757',
            borderRadius: 0,
            borderBottom: '1px solid #ff7600',
            backgroundColor: '#f9f9f9',
            minWidth: 180,
            '@media screen and (max-width: 475px)': {
                minWidth: '80%',
            },

            'border': '1px solid #ebebeb',

            // 'border': '1px solid #ff5200',
            'border-top-right-radius': 7,
            'border-top-left-radius': 7,

            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            borderBottom: 'none',
        },
        typeText: {
            paddingLeft: 11,
            paddingRight: 11,
            paddingBottom: 5,
        },
        modalTypesTexts: {
            width: '100%',
            color: 'white',
            fontSize: 11,
            textAlign: 'center',
            backgroundColor: '#ff5e00',

            position: 'absolute',
            bottom: -20,
            width: '100%',
            border: '1px solid #ebebeb',
            marginLeft: -1,
        },

        activeLastModalTypesBtn: {
            '@media screen and (min-width: 475px)': {
                gridColumn: 'span 2',
                marginTop: 35,
                width: '90%'
            },
            marginTop: 40,
        },
        modalTypesIcons: {
            fontSize: 42,
            color: '#ff7600',
        },
        orderUploadedImageContainer: {
            '& img': {
                maxWidth: '80%',
                margin: '0 auto',
                display: 'block',
            },
            '& input': {
                width: '80%',
                margin: '0 auto',
                display: 'block',
                marginBottom: 50,
                height: 40
            }
        }

    });
