import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import Grow from '@material-ui/core/Grow';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Divider from "@material-ui/core/Divider";
import clsx from 'clsx';

import Utils from '../../utils/Utils';
// import { setSearchOpened } from '../actions/search.actions';
import ProductVariantsModal from '../../common/modals/ProductVariant';
import ProductListItem from '../../products/components/ProductListItem'
import { setChangePasswordModalOpenedState } from '../../users/actions/users.actions';
import { showHeaderCategory } from '../../categories/actions/categories.action';
import { addProductTooltip, addToCart, setCommentModalData } from '../../products/actions/products.action';
import { setMainBackground } from '../../settings/actions/settings.action';
import { getRegions } from '../../regions/actions/regions.actions'
import { styles, useColorlibStepIconStyles, ColorlibConnector } from '../styles/profilePage.styles';
import Auth from "../../utils/AuthC";


class SearchPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            variantModalOpen: false,
            selectedProduct: null,
            loadAllShops: false,
            
        }
    }

    componentDidMount() {
        // this.props.showHeaderCategory(this.props.translations && this.props.translations.common ? this.props.translations.common.action.back : '');
        this.props.setMainBackground('linear-gradient( rgba(255, 255, 255, 1), rgba(255, 255, 255, 1) )');
        this.props.getRegions();
    }

    componentWillReceiveProps(nextProps) {
        const user = Auth.getUserFromToken();

        if (user || nextProps.translations) {
            this.props.showHeaderCategory(user.email ? user.email : (this.props.translations && this.props.translations.common ? this.props.translations.common.action.back : ''));
        }
    }



    render() {

        const { classes, settings, translations, shops, distributors, regions } = this.props;
        const user = Auth.getUserFromToken();

        // console.log('shops', shops)

        let currentUserRegions = []
        let currentUserRegionsIds = [];
        if (user && user.region && user.region.id) {
            currentUserRegions = Object.values(this.props.regions).filter(r => r.id == user.region.id);
            currentUserRegions.map(r => currentUserRegionsIds.push(r.id));
        }
        // console.log('currentUserRegionsIds', currentUserRegionsIds)

        let currentUserRegionsLength = currentUserRegions.length;
        let sortedDistributors = this.props.distributors || [];
        sortedDistributors = Object.values(sortedDistributors).sort((a, b) => (a.priority > b.priority) ? 1 : -1)

        let currentUserDistributors = sortedDistributors.filter(d => currentUserRegionsIds.includes(d.regionId));
        let currentUserDistributorsLength = currentUserDistributors.length;

        let initialShops = [];
        let initialShopsCount = 10;
        if(this.state.loadAllShops) {
            initialShopsCount = Object.values(this.props.shops).length;
        }
        for(let i = 0; i < initialShopsCount; i++) {
            let s = Object.values(shops)[i];
            initialShops.push(
                (<ListItem button
                    className={classes.orderedListItem}
                    // style={Object.values(shops).length == (k + 1) ? { borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderBottom: '1px solid #ccc' } : {}}
                >
                    <ListItemText
                        // primary={productName + (((settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && ((parseFloat(opv.product_variant.additional_price)).toFixed(2) != '0.00')) ? (' /+ ' + productQuantity + ' ' + (translations.common && translations.common.count ? translations.common.count : '') + ' ' + (translations.common && translations.common.box ? translations.common.box : '') + ' ' + ((parseFloat(opv.product_variant.additional_price) * 1 * parseInt(productQuantity)).toFixed(2)) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.') + '/') : '')}
                        primary={(s && s.name || "") + (s && s.email && (" - "+s.email) || "") + (s && s.phone && (" - "+s.phone) || "")}
                        secondary={s && s.address ? s.city + " - " + s.address : ''}
                        className={classes.orderedListItemText}
                    />
                </ListItem>)
            )
        }

        return (
            <React.Fragment>
                <br />
                <br /><br />
                <br />
                {/* <br /> */}

                {/* <Button variant="contained" style={{ marginLeft: 10, width: 'calc(100% - 20px)', boxShadow: 'none' }}
                // onClick={props.onSubmit}
                >
                    {user.email}
                </Button> */}

                {/* <br /> */}

                <Button variant="contained" style={{ marginLeft: 10, width: 'calc(100% - 20px)', boxShadow: 'none' }}
                    onClick={() => this.props.setChangePasswordModalOpenedState(true)}
                >
                    Смяна на парола
            </Button>
                < hr className="progress" />


                <ListItem button className={classes.eachOrderHeaderContainer}>
                    <div className="first">
                        <span>
                            Региони
                        </span>
                    </div>
                </ListItem >

                {Object.values(regions).map((r, k) => {
                    // console.log('r', r)
                    return (<ListItem button
                        className={classes.orderedListItem}
                        // style={item.ordertypeId == 1 && count == Object.values(item.order_productvariants).length - 1 ? { borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderBottom: '1px solid #ccc' } : {}}
                        style={currentUserRegionsLength == (k + 1) ? { borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderBottom: '1px solid #ccc' } : {}}
                    >

                        {/* <img
                            //  src={productImage} alt=""
                            className={classes.productCircularImage}
                            src={'https://material-ui.com/static/images/grid-list/mushroom.jpg'} alt="" className={classes.productCircularImage}
                        /> */}

                        <ListItemText
                            // primary={productName + (((settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && ((parseFloat(opv.product_variant.additional_price)).toFixed(2) != '0.00')) ? (' /+ ' + productQuantity + ' ' + (translations.common && translations.common.count ? translations.common.count : '') + ' ' + (translations.common && translations.common.box ? translations.common.box : '') + ' ' + ((parseFloat(opv.product_variant.additional_price) * 1 * parseInt(productQuantity)).toFixed(2)) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.') + '/') : '')}
                            primary={r.name}
                            className={classes.orderedListItemText}
                        />
                    </ListItem>)
                })}
                {/* {count != Object.values(item.order_productvariants).length - 1 ? <Divider className={classes.orderedProductDivider} /> : null} */}


                {/* {(k !== orderedProductsLength - 1) ? < hr className="progress" /> : <div style={{ marginBottom: 20 }} />} */}
                < hr className="progress" />


                <ListItem button className={classes.eachOrderHeaderContainer}>
                    <div className="first">
                        <span>
                            Дистрибутори
                            {/* {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.order : ''}  */}
                            {/* #{item.id} */}
                        </span>
                    </div>
                </ListItem >


                {Object.values(distributors).map((d, k) => {
                    let regionName = '';
                    if (Object.values(this.props.regions) && d.regionId) {
                        regionName = Object.values(this.props.regions).filter(r => r.id == d.regionId);
                        if (regionName && regionName[0] && regionName[0].name) {
                            regionName = regionName[0].name
                        }
                    }

                    return <ListItem button
                        className={classes.orderedListItem}
                        style={currentUserDistributorsLength == (k + 1) ? { borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderBottom: '1px solid #ccc' } : {}}
                    // style={{}}
                    >
                        {/* 
                        <img
                        //  src={productImage} alt=""
                        className={classes.productCircularImage}
                        src={'https://material-ui.com/static/images/grid-list/mushroom.jpg'} alt="" className={classes.productCircularImage}
                    /> */}

                        <ListItemText
                            // primary={productName + (((settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && ((parseFloat(opv.product_variant.additional_price)).toFixed(2) != '0.00')) ? (' /+ ' + productQuantity + ' ' + (translations.common && translations.common.count ? translations.common.count : '') + ' ' + (translations.common && translations.common.box ? translations.common.box : '') + ' ' + ((parseFloat(opv.product_variant.additional_price) * 1 * parseInt(productQuantity)).toFixed(2)) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.') + '/') : '')}
                            primary={d.name}
                            secondary={regionName}

                            className={classes.orderedListItemText}
                        />
                        {/* <ListItemSecondaryAction>
                                </ListItemSecondaryAction> */}

                    </ListItem>
                })}

                < hr className="progress" />

                <ListItem button className={classes.eachOrderHeaderContainer}>
                    <div className="first">
                        <span>
                            Магазини
                        </span>
                    </div>
                </ListItem >

                {initialShops}
                {/* {Object.values(shops).filter(s => s.userId == user.id).map((r, k) => { */}
                {/* {Object.values(shops).map((r, k) => {
                    // console.log('r', r)
                    return (<ListItem button
                        className={classes.orderedListItem}
                        // style={item.ordertypeId == 1 && count == Object.values(item.order_productvariants).length - 1 ? { borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderBottom: '1px solid #ccc' } : {}}
                        style={Object.values(shops).length == (k + 1) ? { borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderBottom: '1px solid #ccc' } : {}}
                    >

                        <ListItemText
                            // primary={productName + (((settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && ((parseFloat(opv.product_variant.additional_price)).toFixed(2) != '0.00')) ? (' /+ ' + productQuantity + ' ' + (translations.common && translations.common.count ? translations.common.count : '') + ' ' + (translations.common && translations.common.box ? translations.common.box : '') + ' ' + ((parseFloat(opv.product_variant.additional_price) * 1 * parseInt(productQuantity)).toFixed(2)) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.') + '/') : '')}
                            primary={r && r.name ? r.name : ''}
                            secondary={r && r.address ? r.address : ''}
                            className={classes.orderedListItemText}
                        />
                    </ListItem>)
                })} */}


                
                <Button variant="contained" component="span" onClick={() => {this.setState({loadAllShops: true})}}  style={{ color: 'rgb(254, 105, 2)', width: '100%', boxShadow: 'none' }} >
                    Покажи всички магазини
                </Button>
                <br />
                <br />
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    // let sortedDistributors = state.distributors.distributors || []
    // sortedDistributors = Object.values(sortedDistributors).sort((a, b) => (a.priority > b.priority) ? 1 : -1)

    return {
        searchText: state.search.search_text,
        allProducts: state.products.all_products,
        products: state.products || [],
        settings: state.settings.settings || [],
        lang: state.lang || [],
        translations: state.lang.translations || [],
        currLang: state.lang.currLang,
        categoriesHierarchy: state.categories.categories_hierarchy || [],
        regions: state.regions.regions,
        distributors: state.distributors.distributors,
        shops: state.shops.shops || [],
    };
};

export default withStyles(styles)(connect(mapStateToProps, { showHeaderCategory, addProductTooltip, addToCart, setMainBackground, setCommentModalData, getRegions, setChangePasswordModalOpenedState })(SearchPage));
