import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';

import ProductListItem from "./ProductListItem";
import ProductVariantsModal from "../../common/modals/ProductVariant";
import { getProductsBySubcat, addProductTooltip, addToCart, changeSelectedCartTab, getAllProducts } from '../actions/products.action';
import { showHeaderCategory } from '../../categories/actions/categories.action';
import { setMainBackground } from '../../settings/actions/settings.action';
import Page404 from '../../common/errors/404'


const styles = theme => ({
    designVariantsContainer: {
        position: 'fixed',
        right: 0,
        top: '35%',
        zIndex: 5,
        maxWidth: 65,
        background: '#cecece',
        background: '#cecece96',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
    },
    designVariantsTitle: {
        textAlign: 'center',
        fontSize: 17,
        marginBottom: 10,
        borderBottom: '1px solid #ccc',
        marginBottom: 5,
        padding: 10,
    },
    productsContainer: {
        WebkitOverflowScrolling: 'touch',
        width: '90%',
        margin: '0 auto',
        paddingTop: 65,
        paddingBottom: 70, // temp unable to hive navbar fix
    },
    mainCollapseButton: {
        fontWeight: 'bold',
        borderRadius: 0,
    },
    collapsedButton: {
        borderRadius: 0,
    },
    activeCollapsedButton: {
        background: '#f1f1f1a1',
    },
    firstCollapsedButton: {
        borderRadius: 0,
        borderTop: '1px solid #b8b8b8',
    },
    lastCollapsedButton: {
        borderRadius: 0,
        borderBottom: '1px solid #b8b8b8',
    },
    headerInfoText: {
        width: '80%',
        maxWidth: 550,
        borderRadius: 5,
        background: 'rgba(204, 204, 204, 0.6)',
        margin: '0 auto',
        marginTop: 80,
        padding: 14,
        fontSize: 17,
        textAlign: 'center',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    }
});

class AllProductsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            variantModalOpen: false,
            selectedProduct: null,
            timeout: null,
            designVariantButtonsOpened: false,
        }
    }

    // Opens variant modal or adds prod to cart
    addToCartBtnClicked = (id) => {
        const currProduct = Object.values(this.props.products.all_products).filter(p => p.id === id)[0];

        if (currProduct.product_variants) {
            if (currProduct.product_variants.length === 1) {
                this.addProdToCart(currProduct.id, currProduct.product_variants[0].id, currProduct)
            } else {
                // Open variant modal
                this.setState({ variantModalOpen: true, selectedProduct: currProduct })
            }
        }

    }

    addProdToCart = (product_id, product_variant_id, product) => {
        this.showAddedProductTooltip(product_id, product_variant_id, product);
        if (this.state.variantModalOpen) {
            this.setState({ variantModalOpen: false });
        }
        this.props.changeSelectedCartTab(0); // select default tab "Cart"
        this.props.addToCart(product_id, product_variant_id, product);
    };


    // Shows tooltip
    showAddedProductTooltip = (id, product_variant_id, product) => {
        let totalProdQuantity = 1;
        if (this.props.products && this.props.products.to_order && Object.keys(this.props.products.to_order).length > 0) {
            const addedProductVariant = this.props.products.to_order.find(pv => pv.product_variant_id === product_variant_id);
            if (addedProductVariant) {
                totalProdQuantity = parseInt(addedProductVariant.quantity) + 1;
            }
        }

        let choosedProductVariant = product.product_variants.filter(pv => pv.id === product_variant_id)[0];
        let tooltipText = totalProdQuantity + ' x ' + product.name + ' - /' + choosedProductVariant.size + '/';

        // Add to cart tooltip
        const addProdTooltip = (id, tooltipText) => this.props.addProductTooltip(id, tooltipText);
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
            this.setState({ timeout: null });
        }
        addProdTooltip(id, tooltipText);
        let timeOut = setTimeout(function () { addProdTooltip(0, tooltipText); }, 2500);
        this.setState({ timeout: timeOut });
    }

    componentWillMount() {
        this.props.showHeaderCategory(null);
        this.props.getAllProducts(1);
    }
    
    componentDidMount() {
        this.props.setMainBackground('linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) )');
    }
    
    render() {
        const { classes, settings, products, translations } = this.props;

        return (
            <React.Fragment>
                <div className={classes.headerInfoText}
                    dangerouslySetInnerHTML={{
                        __html: settings.all_products_view_text.value
                    }}>
                </div>

                <Grid
                    className={classes.productsContainer}
                    container
                    direction="row"
                    //   justify="space-between"
                    //   justify="center"
                    // alignItems="center"
                    spacing={3}
                    style={{ paddingTop: 20 }} // ONLY if there is headerInfoText
                >

                    {products && products.all_products ?
                        (Object.values(products.all_products).map((product, k) => (
                            <ProductListItem
                                k={k}
                                product={product}
                                settings={settings}
                                addProdToCart={this.addProdToCart}
                                addToCartBtnClicked={this.addToCartBtnClicked}
                                translations={translations}
                                currLang={this.props.currLang}
                            />
                        )))
                        :
                        ''
                    }

                </Grid>

                <ProductVariantsModal
                    open={this.state.variantModalOpen}
                    product={this.state.selectedProduct}
                    addProdToCart={this.addProdToCart}
                    closeProductVariantModalHandler={() => this.setState({ variantModalOpen: !this.state.variantModalOpen })}
                    settings={settings}
                    translations={translations}
                    currLang={this.props.currLang}
                />

            </React.Fragment >
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        // isLoading: state.products.isLoading || false,
        products: state.products || [],
        settings: state.settings.settings || [],
        translations: state.lang,
        currLang: state.lang.currLang
    };
};

export default withStyles(styles)(connect(mapStateToProps, { getProductsBySubcat, showHeaderCategory, addProductTooltip, addToCart, changeSelectedCartTab, getAllProducts, setMainBackground })(AllProductsPage));