import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import { showHeaderCategory } from '../../categories/actions/categories.action';
import { setMainBackground } from './../../settings/actions/settings.action';
import EmailIcon from '@material-ui/icons/Email';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import DirectionsIcon from '@material-ui/icons/Directions';
import Utils from "../../utils/Utils";

import clsx from 'clsx';

const styles = theme => ({
    aboutRestaurantContainer: {
        height: 'calc(100vh - 110px)',
        paddingTop: 55,
        paddingBottom: 55,
        paddingLeft: '10%',
        paddingRight: '10%',
    },
    eachRowAboutRestaurant: {
        marginTop: 25,
        display: 'grid',
        'grid-template-columns': '50% 50%',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        '& > div': {
            display: 'flex',
            justifyContent: 'center',
            // alignItems: center, //Vertical
        },
        '@media screen and (max-width: 1000px)': {
            '&': {
                'grid-template-columns': 'none',
            },
        },

    },
    cellTitle: {
        color: 'white',
        fontSize: '2em',
    },
    cellDescription: {
        color: '#ddd',
    },

    contactIcons: {
        fontSize: 30,
        color: '#ddd',
    },
    contactIconsText: {
        verticalAlign: 9,
        color: '#ddd',
        fontSize: 16,
    },

    colorBlack: {
        color: 'black !important',
    }

});

class AboutRestaurantPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            leftFirstContainerLogoVisible: false,
            rightFirstContainerAddressVisible: false,

            leftSecondContainerContactsVisible: false,
            rightSecondContainerWorkHoursVisible: false,

            leftThirdContainerDeliveryVisible: false,
            rightThirdContainerPaymentMethodsVisible: false,
        }
    }


    componentDidMount() {
        setTimeout(() => this.setState({ leftFirstContainerLogoVisible: true }), 100)
        setTimeout(() => this.setState({ rightFirstContainerAddressVisible: true }), 100)

        setTimeout(() => this.setState({ leftSecondContainerContactsVisible: true }), 200)
        setTimeout(() => this.setState({ rightSecondContainerWorkHoursVisible: true }), 200)

        setTimeout(() => this.setState({ leftThirdContainerDeliveryVisible: true }), 400)
        setTimeout(() => this.setState({ rightThirdContainerPaymentMethodsVisible: true }), 400)

        this.props.setMainBackground('linear-gradient( rgba(147, 147, 147, 0.4), rgba(147, 147, 147, 0.4) )');
    }

    componentDidUpdate() {
        let { settings } = this.props;

        if (settings && settings.about_page_title && settings.about_page_title.value) {
            this.props.showHeaderCategory(<span className="skiptranslate">{settings && settings.about_page_title && settings.about_page_title.value}</span>);
        }
    }

    render() {
        let { classes, translations, settings } = this.props;
        translations = translations.translations;

        return (
            <Fade in={true} timeout={{ enter: 350, exit: 350 }}>
                <div>

                    <div className={classes.aboutRestaurantContainer}>

                        <div className={classes.eachRowAboutRestaurant}>
                            <div>
                                <Grow in={this.state.leftFirstContainerLogoVisible} timeout={{ enter: 450, exit: 350 }}>
                                    <img src={settings.logo && settings.logo.value ? Utils.getSettingsPath(settings.logo.value) : (settings.tabl_logo && settings.tabl_logo.value ? Utils.getSettingsPath(settings.tabl_logo.value) : '')} style={{ width: '100%', maxWidth: 570 }} />
                                </Grow>
                            </div>
                            <div>
                                <Grow in={this.state.rightFirstContainerAddressVisible} timeout={{ enter: 450, exit: 350 }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h2 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.cellTitle, classes.colorBlack) : classes.cellTitle}>{settings.meta_title_place_name && settings.meta_title_place_name.value ? settings.meta_title_place_name.value : ''}</h2>
                                        <h3 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}>{settings.meta_description && settings.meta_description.value ? settings.meta_description.value : ''}</h3>
                                    </div>
                                </Grow>
                            </div>
                        </div>


                        <div className={classes.eachRowAboutRestaurant}>
                            <div>
                                <Grow in={this.state.leftSecondContainerContactsVisible} timeout={{ enter: 450, exit: 350 }}>
                                    <div style={{ textAlign: 'center' }}>

                                        <h2 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.cellTitle, classes.colorBlack) : classes.cellTitle}>{translations && translations.common && translations.common.contacts ? translations.common.contacts : ''}</h2>
                                        <hr />

                                        {settings.call_btn_instead_of_search && settings.call_btn_instead_of_search.value ?
                                            <h3 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}>
                                                <a href={'tel:' + (settings.call_btn_instead_of_search.value.replace(' ', ''))} className="text-decoration-none">
                                                    <PhoneInTalkIcon className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.contactIcons, classes.colorBlack) : classes.contactIcons} />
                                                    <span className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.contactIconsText, classes.colorBlack) : classes.contactIconsText}>
                                                        &nbsp;
                                                        {settings.call_btn_instead_of_search.value}
                                                    </span>
                                                </a>
                                                <br />
                                            </h3>
                                            :
                                            null
                                        }

                                        {settings.second_call_btn && settings.second_call_btn.value ?
                                            <h3 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}>
                                                <a href={'tel:' + (settings.second_call_btn.value.replace(' ', ''))} className="text-decoration-none">
                                                    <PhoneInTalkIcon className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.contactIcons, classes.colorBlack) : classes.contactIcons} />
                                                    <span className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.contactIconsText, classes.colorBlack) : classes.contactIconsText}>
                                                        &nbsp;
                                                        {settings.second_call_btn.value}
                                                    </span>
                                                </a>
                                                <br />
                                            </h3>
                                            :
                                            null
                                        }

                                        {settings.place_address && settings.place_address.value ?
                                            <h3 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}>
                                                <a href={"https://www.google.com/maps/dir/?api=1&destination=" + settings.place_address.value} target="_blank" rel="noopener noreferrer" className="text-decoration-none skiptranslate">
                                                    <DirectionsIcon className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.contactIcons, classes.colorBlack) : classes.contactIcons} />
                                                    <span className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.contactIconsText, classes.colorBlack) : classes.contactIconsText}>
                                                        &nbsp;
                                                        {settings.place_address.value}
                                                    </span>
                                                </a>
                                            </h3>
                                            :
                                            null
                                        }

                                    </div>
                                </Grow>
                            </div>
                            
                            {settings.about_page_working_time && settings.about_page_working_time.value ?
                                <div>
                                    <Grow in={this.state.rightSecondContainerWorkHoursVisible} timeout={{ enter: 450, exit: 350 }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <h2 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.cellTitle, classes.colorBlack) : classes.cellTitle}>{translations && translations.common && translations.common.working_hours ? translations.common.working_hours : ''}</h2>
                                            <hr />
                                            <div className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}
                                                dangerouslySetInnerHTML={{
                                                    __html: settings.about_page_working_time && settings.about_page_working_time.value ? settings.about_page_working_time.value : ''
                                                }}>
                                            </div>
                                        </div>
                                    </Grow>
                                </div>
                            :
                                null
                            }
                        </div>



                        <div className={classes.eachRowAboutRestaurant}>
                            {settings.about_page_delivery_prices && settings.about_page_delivery_prices.value ?
                                <div>
                                    <Grow in={this.state.leftThirdContainerDeliveryVisible} timeout={{ enter: 450, exit: 350 }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <h2 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.cellTitle, classes.colorBlack) : classes.cellTitle}>{translations && translations.common && translations.common.delivery ? translations.common.delivery : ''}</h2>
                                            <hr />
                                            <div className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}
                                                dangerouslySetInnerHTML={{
                                                    __html: settings.about_page_delivery_prices && settings.about_page_delivery_prices.value ? settings.about_page_delivery_prices.value : ''
                                                }}>
                                            </div>
                                        </div>
                                    </Grow>
                                </div>
                            :
                                null
                            }

                            {settings.about_page_payment_types && settings.about_page_payment_types.value ? 
                                <div>
                                    <Grow in={this.state.rightThirdContainerPaymentMethodsVisible} timeout={{ enter: 450, exit: 350 }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <h2 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.cellTitle, classes.colorBlack) : classes.cellTitle}>{translations && translations.common && translations.common.payment_methods ? translations.common.payment_methods : ''}</h2>
                                            <hr />
                                            <div className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}
                                                dangerouslySetInnerHTML={{
                                                    __html: settings.about_page_payment_types && settings.about_page_payment_types.value ? settings.about_page_payment_types.value : ''
                                                }}>
                                            </div>
                                        </div>
                                    </Grow>
                                </div>
                            :
                                null
                            }

                            <br />

                        </div>

                    </div>

                </div >
            </Fade >
        )
    }

}


const mapStateToProps = (state, ownProps) => {
    return {
        translations: state.lang,
        settings: state.settings.settings || [],
    };
};

export default withStyles(styles)(connect(mapStateToProps, { showHeaderCategory, setMainBackground })(AboutRestaurantPage));
