import React, { Component, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
import CircularProgress from '@material-ui/core/CircularProgress';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import { setChosenPlace } from './../../places/actions/places.actions';
import Utils from './../../utils/Utils';
import { getAllProducts, addToCart, changeSelectedCartTab, removeProductFromCart } from './../../products/actions/products.action';
import { getCategoriesHierarchy } from './../../categories/actions/categories.action';
import clsx from 'clsx';

const styles = theme => ({
    modal: {
        '& .MuiPaper-root': {
            width: '350px !important',
        }
    },
    modalTitle: {
        textAlign: 'center',
        backgroundColor: "rgb(203,65,2)",
        color: 'white',
        boxShadow: '0 1px 5px 0px black',
        '& .MuiTypography-root': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        }
    },
    dialogContent: {
        minHeight: 100,
    },
    dialogContentText: {
        paddingTop: 10,
    },
    modalTypesBtns: {
        color: 'black',
        width: '100%',
        fontSize: 16,
        // display: 'grid',
        // 'grid-template-rows': '50% 50%',
        // 'place-self': 'center',
        // textAlign: 'center',
        // color: '#575757',
        borderRadius: 0,
        // borderBottom: '1px solid #ff7600',
        // backgroundColor: '#f9f9f9',
        minWidth: 180,
        '@media screen and (max-width: 475px)': {
            minWidth: '80%',
        },

        // 'border': '1px solid #ebebeb',

        'border-bottom': '1px solid #ff5200',
        // 'border-top-right-radius': 7,
        // 'border-top-left-radius': 7,

        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,

        padding: 15,

        // // display: 'grid',
        // 'grid-template-columns': '70% 30%',
        // borderBottom: 'none',

        // height: 80,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
    },
    modalTypesBtnsBorderBottom: {
        'border-bottom': '1px solid #ff5200 !important',
    },
    modalTypesIcons: {
        fontSize: 42,
        color: '#ff7600',
    },
    isMultipleOptionsModalContainer: {
        // margin: '40px 0 45px 0',
        display: 'grid',
        // 'grid-template-columns': '50% 50%',
        'place-self': 'center',
        textAlign: 'center',
        padding: 0,
        '@media screen and (max-width: 475px)': {
            'grid-template-columns': 'none',
            '& > *:first-child': {
                marginBottom: 40,
            },
            margin: '20px 0 45px 0',
        }
    },
    submitButton: {
        width: '100%',
        color: 'white',
        backgroundColor: '#ff5101',
    }
});

class InitialBannerFastOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            selectedProducts: [],
            // totalCartPrice: 0,
        }
    }


    makeTheOrder = () => {
        const { productsToOrder, settings } = this.props;
        let totalCartPrice = 0;
        Object.values(productsToOrder).map((item, k) => {
            totalCartPrice += item.price * 1 * item.quantity;

            if ((settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && (((parseFloat(item.additional_price)).toFixed(2)) != '0.00')) {
                totalCartPrice += parseFloat(item.additional_price) * 1 * item.quantity;
            }

        });

        if (totalCartPrice > 0) {
            this.props.onSubmit(totalCartPrice)
        }
        window.localStorage.setItem('initial_banner_fast_order_closed', 1);
        this.setState({ opened: false });
        // this.setState({})

    }

    // SAME
    // Opens variant modal or adds prod to cart
    // THIS WILL NOT WORK WITH MORE THAN 1 VARIANT ------------------------------------
    addToCartBtnClicked = (id, product) => {
        const currProduct = product;//Object.values(this.props.products.all_products).filter(p => p.id === id)[0];
        // const currProduct = products.filter(p => p.id === id)[0];

        // if (this.props.settings && this.props.settings.variant_modal_with_comment && this.props.settings.variant_modal_with_comment.value == 1) {
        //     // THIS WILL NOT WORK WITH MORE THAN 1 VARIANT ------------------------------------
        //     // this.setState({ variantModalOpen: true, selectedProduct: currProduct, variantModalWithComment: true })
        // } else {
        if (currProduct.product_variants) {
            if (currProduct.product_variants.length === 1) {
                this.addProdToCart(currProduct.id, currProduct.product_variants[0].id, currProduct)
            } else {
                // THIS WILL NOT WORK WITH MORE THAN 1 VARIANT ------------------------------------
                // Open variant modal
                // this.setState({ variantModalOpen: true, selectedProduct: currProduct })
            }
        }
        // }

    }

    addProdToCart = (product_id, product_variant_id, product) => {
        // this.showAddedProductTooltip(product_id, product_variant_id, product); // DO NOT SHOW ------------------------------------
        // if (this.state.variantModalOpen) {
        //     this.setState({ variantModalOpen: false });
        // }
        this.props.changeSelectedCartTab(0); // select default tab "Cart"
        this.props.addToCart(product_id, product_variant_id, product);
    };


    // Shows tooltip
    // showAddedProductTooltip = (id, product_variant_id, product) => {
    //     let totalProdQuantity = 1;
    //     if (this.props.products && this.props.products.to_order && Object.keys(this.props.products.to_order).length > 0) {
    //         const addedProductVariant = this.props.products.to_order.find(pv => pv.product_variant_id === product_variant_id);
    //         if (addedProductVariant) {
    //             totalProdQuantity = parseInt(addedProductVariant.quantity) + 1;
    //         }
    //     }

    //     let choosedProductVariant = product.product_variants.filter(pv => pv.id === product_variant_id)[0];
    //     let tooltipText = totalProdQuantity + ' x ' + product.name + ' - /' + choosedProductVariant.size + '/';

    //     // Add to cart tooltip
    //     const addProdTooltip = (id, tooltipText) => this.props.addProductTooltip(id, tooltipText);
    //     if (this.state.timeout) {
    //         clearTimeout(this.state.timeout);
    //         this.setState({ timeout: null });
    //     }
    //     addProdTooltip(id, tooltipText);
    //     let timeOut = setTimeout(function () { addProdTooltip(0, tooltipText); }, 2500);
    //     this.setState({ timeout: timeOut });
    // }
    // SAME ^

    // componentWillReceiveProps(nextProps) {
    componentDidMount() {
        // var places = nextProps.places;
        // var chosen_place = nextProps.chosen_place;

        if (!window.localStorage.getItem('initial_banner_fast_order_closed')) {
            // && !Utils.getUserToken()
            // if (places.length > 1 && !chosen_place) {
            setTimeout(() => this.setState({ opened: true }), 2000)
        } else {
            this.setState({
                opened: false
            })
        }
    }

    //removeProductFromCart
    handleSelectedProducts = (pId, product) => {
        let selProdsCopy = this.state.selectedProducts;
        const index = this.state.selectedProducts.indexOf(pId);
        if (index > -1) {
            selProdsCopy.splice(index, 1);
        } else {
            selProdsCopy.push(pId);
            this.addToCartBtnClicked(pId, product);
        }

        this.setState({ selectedProducts: selProdsCopy })

    }

    render() {
        const { classes, fullWidth = true, maxWidth = 'xs', open, handleClose, translations, allProducts } = this.props;
        // console.log('allProducts', (Object.values(allProducts).filter(p => p.isLiquid == '1')).slice(0, 10));
        // console.log('this.state.selectedProducts', this.state.selectedProducts)
        return (
            <React.Fragment>

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={this.state.opened}
                    // onClose={onClose}
                    aria-labelledby="max-width-dialog-title"
                    TransitionComponent={Grow}
                    // className={classes.modal}
                    data-class="dialog-container"
                    onClose={() => { window.localStorage.setItem('initial_banner_fast_order_closed', 1); this.setState({ opened: false }); }}

                >
                    {/* <CircularProgress className="is-loading language-modal" /> */}
                    <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} data-class="dialog-title-container">
                        {/* {translations.pages && translations.pages.choose_place ? translations.pages.choose_place.title : ''} */}
                        Бърза поръчка
                         {/* на напитка */}
                    </DialogTitle>

                    <DialogContent
                    // className={classes.dialogContent}
                    >
                        <DialogContentText
                        // className={classes.dialogContentText}
                        >

                            <div className={classes.isMultipleOptionsModalContainer}>

                                {((Object.values(allProducts).filter(p => p.isLiquid == '1')).slice(0, 10)).map((p, k) => {
                                    // console.log('p', p)
                                    return (
                                        <React.Fragment>
                                            <Button size="small" className={clsx(classes.modalTypesBtns, classes.modalTypesBtnsBorderBottom)}
                                                onClick={() => this.handleSelectedProducts(p.id, p)}
                                            // style={{ backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('" + p.image + "')" }}
                                            >
                                                <div style={{ display: 'grid', 'grid-template-columns': '70% 30%', width: '100%', 'align-items': 'center' }}>
                                                    <span style={{ fontWeight: this.state.selectedProducts.includes(p.id) ? 'bold' : 'normal' }}>{p.name}</span>

                                                    {(p.product_images && p.product_images[0] && p.product_images[0].path && p.product_images[0] !== "undefined" && typeof p.product_images[0].path !== "undefined" && p.product_images[0].path) ?
                                                        <img src={Utils.getProductImagePath(p.product_images[0].path)} width="100" />
                                                        :
                                                        null
                                                    }
                                                </div>

                                            </Button>
                                        </React.Fragment>
                                    )

                                })}
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className={classes.submitButton}
                            onClick={() => this.makeTheOrder()}
                            color="red"
                            data-class="initial-banner-fast-order-submit-button">
                            Поръчай
                        </Button>
                    </DialogActions>

                </Dialog>
                {/* </Grow> */}
            </React.Fragment>
        );
    }

}


const mapStateToProps = (state, ownProps) => {
    return {
        places: state.places.places || [],
        chosen_place: state.places.chosen_place || null,
        allProducts: state.products.all_products || [],
        products: state.products || [],
        productsToOrder: state.products.to_order || [],
        settings: state.settings.settings || [],

    };
};

export default withStyles(styles)(connect(mapStateToProps, { setChosenPlace, getAllProducts, addToCart, changeSelectedCartTab, getCategoriesHierarchy, removeProductFromCart })(InitialBannerFastOrder)
)