import React, { Component } from "react";
import { connect } from "react-redux";
import { verifyToken, verifyTokenAndSession } from './../actions/scan.actions';
import { setChosenPlace } from './../../places/actions/places.actions';
import { getAllProducts } from './../../products/actions/products.action';
import { getCategoriesHierarchy } from './../../categories/actions/categories.action';

import QrReader from "react-qr-reader";
import Utils from "../../utils/Utils";
import { withStyles } from '@material-ui/styles';
import queryString from 'query-string';

class VerifyTokenPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
        var self = this;
        this.props
            .verifyTokenAndSession(this.props.token)
            .then(res => {
                if (res.data.payload) {
                    Utils.setUserToken(res.data.payload.session);
                    let placeId = res.data.payload.place_id;
                    self.props.setChosenPlace(placeId)
                    self.props.getAllProducts(0, placeId);
                    self.props.getCategoriesHierarchy(placeId);
                }
                this.props.history.push("/");
            })
            .catch(err => {
                Utils.removeUserToken();

                // for app functionality - close webview and go to app scan page
                if (window.localStorage.getItem("app")) {
                    window.location.pathname = "/close-browser-app";
                } else {
                    this.props.history.push("/");
                }

            })
    }

    render() {

        return (

            <div></div>

        )

    }

}


const mapStateToProps = (state, ownProps) => {
    let token = ownProps.match.params.token;
    const queryStringParams = queryString.parse(window.location.search);
    if (queryStringParams.app) {
        window.localStorage.setItem("app", true);
    } else {

    }

    return {
        token: token
    };
};
export default connect(mapStateToProps, { verifyToken, verifyTokenAndSession, setChosenPlace, getAllProducts, getCategoriesHierarchy })(VerifyTokenPage);

// export default (connect(mapStateToProps)(ScanPage));