import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import PostAddIcon from '@material-ui/icons/PostAdd';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Utils from '../../utils/Utils';

const useStyles = makeStyles(theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    commentTextField: {
        width: '100%',
        '& label.Mui-focused': {
            color: '#848484',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(254, 105, 2, .7)',
            },
        },
    }
}));

export default function MaxWidthDialog(props) {
    const classes = useStyles();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [comment, setComment] = React.useState('');

    const { open, onClose, modalTitle, onSubmit, translations } = props;
    //     if (translations)
    // console.log('translations', translations)
    useEffect(() => {
        setComment('');

        if (props.comments.length > 0) {
            const currentVariantComment = props.comments.find(p => p.variantId == props.commentVariantId);
            if (currentVariantComment) {
                setComment(currentVariantComment.comment);
            }
        }
    }, [props])


    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={onClose}
                aria-labelledby="max-width-dialog-title"
                TransitionComponent={Grow}
                data-class="dialog-container"
            >
                <DialogTitle id="max-width-dialog-title" style={{ textAlign: 'center', backgroundColor: "rgb(203,65,2)", color: 'white', boxShadow: '0 1px 5px 0px black' }} data-class="dialog-title-container">{Utils.truncate(modalTitle, 22, 20)}</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ paddingTop: 10 }}>
                        <TextField
                            className={classes.commentTextField}
                            onChange={e => setComment(e.target.value)}
                            label={translations && translations.common && translations.common.comment ? translations.common.comment : ''}
                            value={comment}
                            multiline
                            rows="3"
                            variant="outlined"
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ display: 'block' }}>
                    <Button
                        onClick={onClose}
                        style={{ color: 'black' }}
                    >
                        {translations && translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.close ? translations.pages.cart_delivery.close : ''}
                    </Button>
                    {comment.length > 0 ?
                        <Button
                            onClick={() => { onSubmit(props.commentVariantId, ''); onClose() }}
                        >
                            {translations && translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.clear ? translations.pages.cart_delivery.clear : ''}
                        </Button>
                        :
                        null
                    }

                    <Button
                        onClick={() => { onSubmit(props.commentVariantId, comment); onClose() }}
                        style={{ color: '#FE6902', fontWeight: 'bold', float: 'right' }}
                    >
                        {translations && translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.save ? translations.pages.cart_delivery.save : ''}
                    </Button>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}



