import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import Grow from '@material-ui/core/Grow';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Divider from "@material-ui/core/Divider";
import clsx from 'clsx';
import TablePagination from '@material-ui/core/TablePagination';
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { GridList, GridListTile, GridListTileBar, Collapse, TableBody, TableCell, Table, TableHead, TableRow, TableContainer, Paper, TextField } from '@material-ui/core/';
import { Pagination, Autocomplete, createFilterOptions } from '@material-ui/lab';
// import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';

import { DEFAULT_PRODUCT_IMAGE, ITEMS_PER_PAGE } from '../../constants'

import Utils from '../../utils/Utils';
// import { setSearchOpened } from '../actions/search.actions';
import ProductVariantsModal from '../../common/modals/ProductVariant';
import ProductListItem from '../../products/components/ProductListItem'
import { showHeaderCategory } from '../../categories/actions/categories.action';
import { addProductTooltip, addToCart, setCommentModalData, getOrders } from '../../products/actions/products.action';
import { setMainBackground } from '../../settings/actions/settings.action';
import { cancelOrder } from './../actions/orders.actions';

import { styles, useColorlibStepIconStyles, ColorlibConnector } from '../styles/ordersPage.styles';

const bgStatuses = {
    "ordered": 'Поръчана',
    'paid': "Платена",
    'canceled': 'Анулирана',
    'cancelled': 'Анулирана' // for sure
}

class SearchPage extends Component {
    constructor(props) {
        super(props);
        const now = new Date();

        this.state = {
            variantModalOpen: false,
            selectedProduct: null,
            orderIdGalleryOpened: 0,
            currentPage: 0,
            rowsPerPage: ITEMS_PER_PAGE,
            filterDateFrom: new Date(new Date().setDate(now.getDate() - 30)),
            filterDateTo: new Date(),
            filterShopId: 0,
        }
    }

    componentDidMount() {
        this.props.showHeaderCategory(this.props.translations && this.props.translations.common ? this.props.translations.common.action.back : '');
        this.props.setMainBackground('linear-gradient( rgba(255, 255, 255, 1), rgba(255, 255, 255, 1) )');

        this.props.getOrders(this.state.filterDateFrom, this.state.filterDateTo, this.state.search_shop);
    }

    componentWillReceiveProps() {
    }

    toggleOrderGallery = (orderIdGalleryOpened = 0) => {
        this.setState({ orderIdGalleryOpened: this.state.orderIdGalleryOpened == orderIdGalleryOpened ? 0 : orderIdGalleryOpened })
    }


    addIngredientsPriceInOrder = (item) => {
        let ingredientsPrice = 0;

        if (item && item.opv_ingredients) {
            item.opv_ingredients.map(ing => {
                if (ing.ingredient_type == "required" || ing.ingredient_type == "addable") {
                    ingredientsPrice += parseFloat(this.props.productIngredients[parseInt(ing.ingredientId)].price);
                }
            })
        }

        return ingredientsPrice;
    }

    onChangeSelectAutocomplete = (ev, name, newValue) => {
        this.setState({ [name]: newValue ? newValue.id : 0 });
    }

    callCancelOrder = (orderId) => {
        this.props.cancelOrder({ orderId: orderId });
    }

    callFilter = () => {
        this.props.getOrders(this.state.filterDateFrom, this.state.filterDateTo, this.state.filterShopId);
    }

    render() {

        const { classes, settings, translations, orders } = this.props;

        let filteredOrders = this.props.orders;

        const items_count = Object.keys(filteredOrders).length
        const start_offset = this.state.currentPage * this.state.rowsPerPage;
        let start_count = 0;

        // if ((this.state.filterDateFrom) || (this.state.filterDateTo)) {
        //     if (this.state.filterDateFrom) {
        //         filteredOrders = filteredOrders.filter(o => new Date(this.state.filterDateFrom).getTime() < new Date(o.createdAt).getTime())
        //     }
        //     if (this.state.filterDateTo) {
        //         filteredOrders = filteredOrders.filter(o => new Date(this.state.filterDateTo).getTime() > new Date(o.createdAt).getTime())
        //     }
        //     if (this.state.filterShopId) {
        //         filteredOrders = filteredOrders.filter(f => (f.shopId == this.state.filterShopId))
        //     }
        // }

        return (
            <div data-class="remove-li-dots">
                <br /><br />

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container style={{ padding: '0 10px' }}>

                        <Grid xs={12} md={6} lg={3} style={{ marginTop: 19 }}>
                            <TablePagination
                                style={{ display: 'table', margin: '0 auto', padding: 0 }}
                                component="div"
                                labelRowsPerPage={'Покажи'}
                                count={items_count}
                                page={this.state.currentPage}
                                onChangePage={(e, v) => this.setState({ currentPage: v })}
                                rowsPerPage={this.state.rowsPerPage}
                                onChangeRowsPerPage={(e) => { this.setState({ rowsPerPage: e.target.value }); this.setState({ currentPage: 0 }) }}
                            />
                        </Grid>

                        <Grid xs={12} md={3} lg={2} style={{ marginTop: 19 }}>
                            <DatePicker
                                style={{ display: 'table', margin: '0 auto' }}
                                autoOk
                                label="От"
                                // clearable
                                disableFuture
                                name="filterDateFrom"
                                value={this.state.filterDateFrom}
                                onChange={e => this.setState({ currentPage: 0, filterDateFrom: new Date(e).getMonth() + 1 + '/' + new Date(e).getDate() + '/' + new Date(e).getFullYear() + ' 00:00:00' })}
                                format="dd-MM-yyyy"
                            />
                        </Grid>

                        <Grid xs={12} md={3} lg={2} style={{ marginTop: 19 }}>
                            <DatePicker
                                style={{ display: 'table', margin: '0 auto' }}
                                autoOk
                                label="До"
                                // clearable
                                disableFuture
                                name="filterDateTo"
                                value={this.state.filterDateTo}
                                onChange={e => this.setState({ currentPage: 0, filterDateTo: new Date(e).getMonth() + 1 + '/' + new Date(e).getDate() + '/' + new Date(e).getFullYear() + ' 23:59:59' })}
                                format="dd-MM-yyyy"
                            />
                        </Grid>

                        <Grid xs={12} md={6} lg={2} style={{ marginTop: 19 }}>
                            <Autocomplete
                                freeSolo
                                name={"filterShopId"}
                                onChange={(ev, newValue) => this.onChangeSelectAutocomplete(ev, 'filterShopId', newValue)}
                                getOptionLabel={(option) => option.name}
                                // value={defaultShop}
                                filterOptions={createFilterOptions({ limit: 100 })}
                                renderOption={(option) => (
                                    <React.Fragment> {option.name} </React.Fragment>
                                )}
                                options={Object.values(this.props.shops)}
                                style={{ 'max-width': 400, margin: '0 auto' }}
                                renderInput={(params) => (
                                    <TextField {...params} label="" placeholder={"Обект"} margin="normal" style={{ paddingTop: 0 }} />
                                )}
                            />
                        </Grid>
                        {/* <Grid xs={12} md={1} style={{ marginTop: 30 }}>
                        </Grid> */}
                        <Grid xs={12} md={6} lg={2} style={{ 'max-width': 400, margin: '0 auto', 'align-self': 'center', display: 'grid' }}>
                            <Button variant="contained" component="span" onClick={this.callFilter} style={{ color: '#f66201', fontWeight: 'bold', width: '100%', boxShadow: 'none' }} >
                                Филтрирай
                            </Button>
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>

                <br />

                {
                    filteredOrders.map((o, i) => {

                        if (i >= start_offset && start_count < this.state.rowsPerPage) {
                            start_count++;

                            let orderDate = new Date(o.createdAt);
                            orderDate = Utils.appendLeadingZeroes(orderDate.getDate()) + '.' + Utils.appendLeadingZeroes(parseInt(orderDate.getMonth()) + 1) + ' ' + Utils.appendLeadingZeroes(orderDate.getHours()) + ':' + Utils.appendLeadingZeroes(orderDate.getMinutes()); // + ':' + Utils.appendLeadingZeroes(orderDate.getSeconds());

                            let eachOrderTotalSum = 0;
                            let eachOrderedVariant = [];
                            // console.log('o.shopId', o.shopId)
                            let currentDistributor = this.props.distributors && this.props.distributors.length > 0 ? this.props.distributors.find(d => d.id == o.distributorId) : [];
                            // let currentShop = this.props.shops && this.props.shops.length > 0 ? Object.values(this.props.shops).find(s => console.log('s.id ',s.id, ' == ',o.shopId)) : [];
                            // console.log('currentDistributor', currentDistributor)
                            // console.log('this.props.shops', this.props.shops)
                            // console.log('currentShop', currentShop)

                            let shopName = o.shop && o.shop.name ? o.shop.name : ''
                            let shopAddress = o.shop && o.shop.city ? o.shop.city : '' //or address ?

                            let salespersonName = this.props.users.name;

                            o.order_productvariants.map((opv, count) => {
                                let productQuantity = opv.quantity ? opv.quantity : '';
                                let productPrice = opv.product_variant && opv.product_variant.price ? opv.product_variant.price : '';
                                let productsById = this.props.productsById;

                                let productImage = productsById[opv.product_variant.productId] && productsById[opv.product_variant.productId].product_images && productsById[opv.product_variant.productId].product_images[0] && productsById[opv.product_variant.productId].product_images[0].path ? Utils.getProductThumbImagePath(productsById[opv.product_variant.productId].product_images[0].path) : DEFAULT_PRODUCT_IMAGE;

                                // let variant = productsById[opv.product_variant.productId]
                                //     && productsById[opv.product_variant.productId].product_variants.filter(v => v.id == opv.product_variant.productId);
                                // variant = variant && variant[0] || null;

                                // let productSize = (variant && variant.translations && variant.translations[this.props.currLang]
                                //     && variant.translations[this.props.currLang].size) ? variant.translations[this.props.currLang].size : (variant && variant.size ? variant.size : '');

                                let variant = opv.product_variant || null;
                                let productSize = variant && variant.size || null;
                                // let productQuantity = opv.quantity ? opv.quantity : '';
                                opv.discount_percent = opv.product_variant && opv.product_variant.productId && productsById[opv.product_variant.productId] && productsById[opv.product_variant.productId].discount_percent || 0;

                                // let productVariant = productSize + ' - ' + productQuantity + ' x ' + (parseFloat(productPrice) + parseFloat(this.addIngredientsPriceInOrder(opv))).toFixed(2) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.');
                                // let productVariant = productSize + ' - ' + productQuantity + ' x ' + Utils.calculateProductPriceOrder(opv, settings, opv.opv_ingredients, 1) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.');
                                let productVariant = productSize
                                let productVariantPrice = Utils.calculateProductPriceOrder(opv, settings, opv.opv_ingredients, 1) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.')
                                let productVariantQuantity = productQuantity;
                                let productVariantTotalPrice = Utils.calculateProductPriceOrder(opv, settings, opv.opv_ingredients, productQuantity) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.')
                                //  + ' - ' +  + ' x ' + ;
                                let productName =
                                    (productsById[opv.product_variant.productId]
                                        && productsById[opv.product_variant.productId].translations
                                        && productsById[opv.product_variant.productId].translations[this.props.currLang]
                                        && productsById[opv.product_variant.productId].translations[this.props.currLang].name) ? productsById[opv.product_variant.productId].translations[this.props.currLang].name :
                                        (productsById && productsById[opv.product_variant.productId] && productsById[opv.product_variant.productId].name ? productsById[opv.product_variant.productId].name : '');

                                let productCode = opv && opv.product_variant && opv.product_variant.product && opv.product_variant.product.code ? opv.product_variant.product.code : ''

                                let productDistrobutorCode = opv && opv.product_variant && opv.product_variant.product && opv.product_variant.product.product_distributor_codes && opv.product_variant.product.product_distributor_codes[0] && opv.product_variant.product.product_distributor_codes[0].code ? opv.product_variant.product.product_distributor_codes[0].code : ''
                                // console.log('opv.product_variant.product.product_distributor_codes', opv.product_variant.product.product_distributor_codes)


                                // eachOrderTotalSum += parseFloat((Math.round((parseFloat(productQuantity) * (parseFloat(productPrice) + parseFloat(this.addIngredientsPriceInOrder(opv)))) * 100) / 100).toFixed(2));

                                // eachOrderedVariant.push(
                                //     <ListItem button
                                //         className={classes.orderedListItem}
                                //     >

                                //         <img
                                //             src={productImage} alt={productName}
                                //             className={classes.productCircularImage}
                                //         />

                                //         <ListItemText
                                //             primary={productName + (((settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && ((parseFloat(opv.product_variant.additional_price)).toFixed(2) != '0.00')) ? (' /+ ' + productQuantity + ' ' + (translations.common && translations.common.count ? translations.common.count : '') + ' ' + (translations.common && translations.common.box ? translations.common.box : '') + ' ' + ((parseFloat(opv.product_variant.additional_price) * 1 * parseInt(productQuantity)).toFixed(2)) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.') + '/') : '')}
                                //             secondary={productVariant}
                                //             className={classes.orderedListItemText}
                                //         />
                                //         <ListItemSecondaryAction>
                                //             {Utils.calculateProductPriceOrder(opv, settings, opv.opv_ingredients)} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}
                                //             {/* {((Math.round((parseFloat(productQuantity) * (parseFloat(productPrice) + parseFloat(this.addIngredientsPriceInOrder(opv)))) * 100) / 100)).toFixed(2)} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'} */}
                                //         </ListItemSecondaryAction>
                                //     </ListItem>
                                // )

                                eachOrderedVariant.push(

                                    <TableRow hover
                                        key={count}
                                        style={{ background: count % 2 == 0 ? 'initial' : 'rgba(0, 0, 0, 0.04)' }}
                                    >

                                        <TableCell >
                                            {shopName}
                                        </TableCell>

                                        <TableCell >
                                            {shopAddress}
                                        </TableCell>

                                        <TableCell >
                                            {productName}
                                        </TableCell>

                                        <TableCell >
                                            {productCode}
                                        </TableCell>


                                        <TableCell >
                                            {productDistrobutorCode}
                                        </TableCell>


                                        <TableCell >
                                            {salespersonName}
                                        </TableCell>



                                        <TableCell >
                                            {productVariant}
                                        </TableCell>

                                        <TableCell align="right">
                                            {productVariantPrice}
                                        </TableCell>

                                        <TableCell align="right">
                                            {productVariantQuantity}
                                        </TableCell>

                                        <TableCell align="right">
                                            {productVariantTotalPrice}
                                        </TableCell>

                                        {/* <ListItemText
                                            primary={ }
                                            secondary={}
                                            className={classes.orderedListItemText}
                                        /> */}
                                        {/* <ListItemSecondaryAction> */}
                                        {/* {Utils.calculateProductPriceOrder(opv, settings, opv.opv_ingredients)} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'} */}
                                        {/* {((Math.round((parseFloat(productQuantity) * (parseFloat(productPrice) + parseFloat(this.addIngredientsPriceInOrder(opv)))) * 100) / 100)).toFixed(2)} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'} */}
                                        {/* </ListItemSecondaryAction> */}
                                        {/* </ListItem> */}
                                        {/* </Grid> */}

                                    </TableRow>

                                )


                            })

                            return (
                                <React.Fragment>

                                    {/* <ListItem button className={classes.eachOrderHeaderContainer}> */}

                                    {/* Ship to name
                                        Клиент

                                        Ship to address
                                        Адрес за доставка

                                        Brand code
                                        Продукт

                                        Item No
                                        EAN

                                        Distributor No
                                        -

                                        Salesperson Name
                                        Търг. Представител


                                        Base Unit of Measure
                                        Базова мярка


                                        Ед. цена с ДДС
                                        -


                                        Количество


                                        Сума без ДДС */}

                                    {/* </ListItem> */}

                                    {/* <ListItem button className={classes.eachOrderHeaderContainer}> */}
                                    {/* <div className="first">
                                            <span>
                                                {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.order : ''} #{o.id}
                                            </span>
                                        </div>
                                        <div>
                                            <ScheduleIcon className={classes.orderTimeIcon} />
                                            <span className={classes.eachOrderTime}>
                                                {orderDate}

                                            </span>
                                        </div>
                                        <div className="last tooltip-text-align-center">
                                            <span> */}
                                    {/* {deliveryIncluded ?
                                                    <React.Fragment> */}
                                    {/* TODO - This logic is working, the problem is only in the tooltip showing */}
                                    {/* <ClickAwayListener onClickAway={() => this.setState({ totalCartPriceWhenWithDeliveryTooltipOpened: false })}>
                                                <div>
                                                    <Tooltip
                                                        PopperProps={{
                                                            disablePortal: true,
                                                        }}
                                                        onClose={() => this.setState({ totalCartPriceWhenWithDeliveryTooltipOpened: false })}
                                                        open={this.state.totalCartPriceWhenWithDeliveryTooltipOpened}
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener
                                                        title={Utils.translate(this.props.translations.pages.cart_delivery.delivery_price_included_in_total_price, { delivery_price_num: parseFloat(delivery_price_num) + '' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'), min_price_free_delivery_num: parseFloat(min_price_free_delivery_num) + '' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.') })}
                                                    >
                                                        <span onClick={() => this.state.totalCartPriceWhenWithDeliveryTooltipOpened == false ? this.setState({ totalCartPriceWhenWithDeliveryTooltipOpened: true }) : void (0)} onMouseOver={() => this.setState({ totalCartPriceWhenWithDeliveryTooltipOpened: true })} onMouseOut={() => this.setState({ totalCartPriceWhenWithDeliveryTooltipOpened: false })}>
                                                            {Utils.normalizePrice(eachOrderTotalSum)} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}
                                                            <InfoIcon style={{ verticalAlign: '-6', marginRight: 3 }} />
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                </ClickAwayListener> */}
                                    {/* </React.Fragment>
                                                : */}
                                    {/* <span>
                                                    {o.total_price} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}
                                                </span> */}
                                    {/* } */}
                                    {/* </span>
                                        </div>
                                    </ListItem > */}

                                    {/* <ListItem > */}


                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow hover >
                                                    <TableCell component="th" scope="row" width={10}>Ship to name</TableCell>
                                                    <TableCell component="th" scope="row" width={10}>Ship to address</TableCell>
                                                    <TableCell component="th" scope="row" width={25}>Brand code</TableCell>
                                                    <TableCell component="th" scope="row" width={10}>Item No</TableCell>
                                                    <TableCell component="th" scope="row" width={10}>Distributor No</TableCell>
                                                    <TableCell component="th" scope="row" width={10}>Salesperson Name</TableCell>
                                                    <TableCell component="th" scope="row" width={10}>Base Unit of Measure</TableCell>
                                                    <TableCell align="right" component="th" scope="row" width={5}></TableCell>
                                                    <TableCell align="right" component="th" scope="row" width={5}>Количество</TableCell>
                                                    <TableCell align="right" component="th" scope="row" width={5}>Сума без ДДС</TableCell>
                                                </TableRow>

                                                <TableRow hover style={{ background: '#DDEBF7' }}>
                                                    <TableCell component="th" scope="row" width={10}>Клиент</TableCell>
                                                    <TableCell component="th" scope="row" width={10}>Адрес за доставка</TableCell>
                                                    <TableCell component="th" scope="row" width={25}>Продукт</TableCell>
                                                    <TableCell component="th" scope="row" width={10}>EAN</TableCell>
                                                    <TableCell component="th" scope="row" width={10}></TableCell>
                                                    <TableCell component="th" scope="row" width={10}>Търг. Представител</TableCell>
                                                    <TableCell component="th" scope="row" width={10}>Базова мярка</TableCell>
                                                    <TableCell align="right" component="th" scope="row" width={5}>Ед. цена с ДДС</TableCell>
                                                    <TableCell align="right" component="th" scope="row" width={5}>Количество</TableCell>
                                                    <TableCell align="right" component="th" scope="row" width={5}>Сума с ДДС</TableCell>
                                                </TableRow>

                                            </TableHead>
                                            <TableBody>

                                                {eachOrderedVariant}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>



                                    {/* </ListItem > */}
                                    {/* {count != Object.values(item.order_productvariants).length - 1 ? <Divider className={classes.orderedProductDivider} /> : null} */}



                                    {
                                        o.order_images_comments.length > 0 ?

                                            <ListItem button
                                                className={classes.orderedListItem}
                                            >


                                                <ListItemText
                                                    onClick={() => this.toggleOrderGallery(o.id)}
                                                    // primary={productName + (((settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && ((parseFloat(opv.product_variant.additional_price)).toFixed(2) != '0.00')) ? (' /+ ' + productQuantity + ' ' + (translations.common && translations.common.count ? translations.common.count : '') + ' ' + (translations.common && translations.common.box ? translations.common.box : '') + ' ' + ((parseFloat(opv.product_variant.additional_price) * 1 * parseInt(productQuantity)).toFixed(2)) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.') + '/') : '')}
                                                    primary={'Виж прикачените снимки'}
                                                    secondary={
                                                        <>
                                                            {o.order_images_comments.length} {o.order_images_comments.length == 1 ? 'файл' : 'файла'}
                                                        </>
                                                    } className={classes.orderedListItemText}
                                                />
                                            </ListItem>
                                            :
                                            null
                                    }

                                    <ListItem
                                        className={classes.orderedListItem}
                                    >

                                        {/* <img
                                            //  src={productImage} alt=""
                                            className={classes.productCircularImage}
                                            src={'https://material-ui.com/static/images/grid-list/mushroom.jpg'} alt="" className={classes.productCircularImage}
                                            /> 
                                        */}

                                        {/* <ListItemText
                                            // primary={productName + (((settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && ((parseFloat(opv.product_variant.additional_price)).toFixed(2) != '0.00')) ? (' /+ ' + productQuantity + ' ' + (translations.common && translations.common.count ? translations.common.count : '') + ' ' + (translations.common && translations.common.box ? translations.common.box : '') + ' ' + ((parseFloat(opv.product_variant.additional_price) * 1 * parseInt(productQuantity)).toFixed(2)) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.') + '/') : '')}
                                            primary={
                                            <React.Fragment> 
                                        */}

                                        <Collapse in={this.state.orderIdGalleryOpened == o.id} style={{ width: '100%', maxWidth: '100%' }}>

                                            <GridList cellHeight={200} spacing={1} className={classes.gridList} style={{ width: '100%', maxWidth: '100%' }}>
                                                {o.order_images_comments.map((img) => (
                                                    <GridListTile key={img.image}
                                                    //  cols={tile.featured ? 2 : 1} rows={tile.featured ? 2 : 1}
                                                    >
                                                        {/* Utils.getProductThumbImagePath(productsById[opv.product_variant.productId].product_images[0].path) */}

                                                        <img src={Utils.getOrderImagePath(img.image, o.id)} alt={img.comment} />
                                                        <GridListTileBar
                                                            title={img.comment}
                                                            // subtitle={<span>{tile.date}</span>}
                                                            titlePosition="top"
                                                            // actionIcon={
                                                            //     <IconButton aria-label={`star ${tile.comment}`} className={classes.icon}>
                                                            //         <StarBorderIcon />
                                                            //     </IconButton>
                                                            // }
                                                            actionPosition="left"
                                                            className={classes.titleBar}
                                                        />
                                                    </GridListTile>
                                                ))}
                                            </GridList>
                                        </Collapse>
                                        {/* </React.Fragment>}
                                            className={classes.orderedListItemText}
                                        /> */}
                                    </ListItem>

                                    {/* <Divider className={classes.orderedProductDivider} /> */}


                                    <ListItem className={clsx(classes.eachOrderFooterContainer, classes.eachOrderFooterContainerFourColumns)}>
                                        <div className="first">
                                            <span>
                                                {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.order : ''} #{o.id}
                                            </span>
                                        </div>
                                        <div>
                                            <span>
                                                {orderDate}
                                                {/* {o.client_phone} */}
                                                <br />
                                                {o.total_price} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}
                                            </span>
                                        </div>
                                        <div className="">
                                            <span>
                                                Статус: {bgStatuses[o.status]}
                                                <br />
                                                {o.status != 'canceled' ?
                                                    <Button variant="contained" component="span" onClick={() => this.callCancelOrder(o.id)}
                                                        style={{ color: '#f66201', fontWeight: 'bold', width: '100%', boxShadow: 'none', maxWidth: 130, padding: 0 }} >
                                                        Анулирай
                                                    </Button>
                                                    :
                                                    null
                                                }
                                            </span>
                                        </div>
                                        <div className="last">
                                            <span>
                                                Коментар: {o.order_comment}
                                            </span>
                                        </div>
                                    </ListItem>


                                    {/* {(k !== orderedProductsLength - 1) ? < hr className="progress" /> : <div style={{ marginBottom: 20 }} />} */}
                                    < hr className="progress" />


                                </React.Fragment >
                            )
                        }
                    }
                    )
                }

                {/* // {count != Object.values(item.order_productvariants).length - 1 ? <Divider className={classes.orderedProductDivider} /> : null} */}



                {/* <Pagination count={10} stlye={{ margin: '0 auto' }} /> */}

                <TablePagination
                    component="div"
                    labelRowsPerPage={'Покажи'}
                    style={{ padding: 0 }}
                    count={items_count}
                    page={this.state.currentPage}
                    onChangePage={(e, v) => this.setState({ currentPage: v })}
                    rowsPerPage={this.state.rowsPerPage}
                    onChangeRowsPerPage={(e) => { this.setState({ rowsPerPage: e.target.value }); this.setState({ currentPage: 0 }) }}
                />


            </div >
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let sortedDistributors = state.distributors.distributors || []
    sortedDistributors = Object.values(sortedDistributors).sort((a, b) => (a.priority > b.priority) ? 1 : -1)

    return {
        distributors: sortedDistributors,
        shops: state.shops.shops || [],
        searchText: state.search.search_text,
        allProducts: state.products.all_products,
        productsById: state.products.all_products || [],
        products: state.products || [],
        orders: state.products && state.products.ordered ? state.products.ordered : [],
        settings: state.settings.settings || [],
        lang: state.lang || [],
        translations: state.lang.translations || [],
        currLang: state.lang.currLang,
        categoriesHierarchy: state.categories.categories_hierarchy || [],
        users: state.users.user || []
    };
};

export default withStyles(styles)(connect(mapStateToProps, { showHeaderCategory, addProductTooltip, addToCart, setMainBackground, setCommentModalData, getOrders, cancelOrder })(SearchPage));
