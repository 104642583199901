import React from "react";
import history from './history';
import { PRODUCT_IMG_URL, PRODUCT_ORDER_IMG_URL, DEFAULT_PRODUCT_IMAGE, DEFAULT_SUBCATEGORY_IMAGE, DEFAULT_CATEGORY_IMAGE, ORDER_EXPIRE_TIME_HOURS, PRODUCT_THUMB_IMG_URL, DEFAULT_PRODUCT_THUMB_IMAGE, SETTINGS_IMG_URL } from '../constants';
import { isEqual, sortBy, difference } from 'lodash';

class Utils {

	static cropMail(email) {
		if (email && (email.indexOf("@") !== -1)) {
			return email.split("@")[0];
		}
		return email;
	}

	static objectToFormData(obj) {
		let formData = new FormData();
		for (var key in obj) {
			formData.append(key, obj[key]);
		}
		return formData;
	}

	static onChange(e) {
		return { [e.target.name]: (e.target.type && e.target.type === 'number') ? parseFloat(e.target.value) : e.target.value }
	}

	static onChangeCheckbox(e) {
		return { [e.target.name]: e.target.checked ? 1 : 0 };
	}

	static truncate(input, maxInputLength = 15, cutFirstXSymbold = 5) {
		if (input && input.length > maxInputLength)
			return input.substring(0, cutFirstXSymbold) + '...';
		else
			return input;
	}

	static appendLeadingZeroes = (n) =>
		(n <= 9 ? "0" + n : n)

	static buildURLQuery = (obj) =>
		Object.entries(obj)
			.map(pair => pair.map(encodeURIComponent).join('='))
			.join('&');

	static changeListDateFormat = (d) => {
		let bgMonths = [];
		bgMonths[0] = 'яну';
		bgMonths[1] = 'фев';
		bgMonths[2] = 'март';
		bgMonths[3] = 'апр';
		bgMonths[4] = 'май';
		bgMonths[5] = 'юни';
		bgMonths[6] = 'юли';
		bgMonths[7] = 'авг';
		bgMonths[8] = 'сеп';
		bgMonths[9] = 'окт';
		bgMonths[10] = 'ное';
		bgMonths[11] = 'дек';

		return this.appendLeadingZeroes(d.getDate()) + '-' + bgMonths[d.getMonth()] + '-' + d.getFullYear() + ' ' + this.appendLeadingZeroes(d.getHours()) + ':' + this.appendLeadingZeroes(d.getMinutes())
	}

	static errorHandler(error) {
		const customErrorMsg = "Възникна грешка #";
		let errorCode = 0;

		// https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
		if (error.response) {
			errorCode = 1;
			console.log(error.response.data); //DO NOT REMOVE
			console.log(error.response.status); //DO NOT REMOVE
			console.log(error.response.headers); //DO NOT REMOVE
			/*
				* The request was made and the server responded with a
				* status code that falls out of the range of 2xx
			*/
		} else if (error.request) {
			errorCode = 2;
			errorCode += ' - Проблем със сървъра или слаб интернет!';
			/*
			* The request was made but no response was received, `error.request`
			* is an instance of XMLHttpRequest in the browser and an instance
			* of http.ClientRequest in Node.js
			*/
			console.log(error.request); //DO NOT REMOVE
		} else {
			errorCode = 3;
			// Something happened in setting up the request and triggered an Error
			console.log('Error', error.message); //DO NOT REMOVE
		}

		return customErrorMsg + errorCode;
	}

	static navigateTo = (url) => {
		history.push(url);
	}


	static getSettingsPath = (imgSrc) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			return SETTINGS_IMG_URL + imgSrc;
		}
		return '';
	}

	static getOrderImagePath = (imgSrc, orderId, returnDefaultImage = 0) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			return PRODUCT_ORDER_IMG_URL + `order_${orderId}/${imgSrc}`;
		}
		if (returnDefaultImage) {
			return DEFAULT_PRODUCT_IMAGE;
		}
		return '';

	}

	static getProductImagePath = (imgSrc, returnDefaultImage = 0) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			return PRODUCT_IMG_URL + imgSrc;
		}
		if (returnDefaultImage) {
			return DEFAULT_PRODUCT_IMAGE;
		}
		return '';
	}

	static getProductThumbImagePath = (imgSrc, returnDefaultImage = 0) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			if (window.location.hostname == 'localhost') {
				return PRODUCT_THUMB_IMG_URL.replace(window.location.origin, 'https://demo.tabl.bg') + imgSrc;
			}
			return PRODUCT_THUMB_IMG_URL + imgSrc;
		}
		if (returnDefaultImage) {
			return DEFAULT_PRODUCT_THUMB_IMAGE;
		}
		return '';
	}

	static getSubcategoryImagePath = (imgSrc, returnDefaultImage = 0) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			}
			if (window.location.hostname == 'localhost') {
				return PRODUCT_THUMB_IMG_URL.replace(window.location.origin, 'https://demo.tabl.bg') + imgSrc;
			}
			return PRODUCT_THUMB_IMG_URL + imgSrc;
		}
		if (returnDefaultImage) {
			return DEFAULT_SUBCATEGORY_IMAGE;
		}
		return '';
	}


	static getCategoryImagePath = (imgSrc) => {
		if (imgSrc) {
			if ((imgSrc.indexOf('http://') === 0 || imgSrc.indexOf('https://') === 0)) {
				return imgSrc;
			} else {
				if (window.location.hostname == 'localhost') {
					return PRODUCT_THUMB_IMG_URL.replace(window.location.origin, 'https://demo.tabl.bg') + imgSrc; //TODO this is not product_img_url
				}
				return PRODUCT_THUMB_IMG_URL + imgSrc; //TODO this is not product_img_url
			}
		}
		return DEFAULT_CATEGORY_IMAGE;
	}

	static normalizePrice = (price) => {
		return price.toFixed(2);
	}

	static removeUserToken = () => {
		window.localStorage.removeItem('user_token');
	}

	static setUserToken = function (token) {
		let tokenExpireDate = new Date();
		tokenExpireDate.setHours(tokenExpireDate.getHours() + ORDER_EXPIRE_TIME_HOURS);
		window.localStorage.setItem('user_token', JSON.stringify({ token: token, expires: tokenExpireDate }));
	}

	static updateUserTokenExpire = (minutes = 15) => {
		const token = this.getUserToken();
		if (token) {
			const tokenExpireDate = new Date((new Date()).getTime() + minutes * 60000);
			window.localStorage.setItem('user_token', JSON.stringify({ token: token, expires: tokenExpireDate }));
		}
	}

	static getUserToken = () => {
		let user_token = window.localStorage.getItem('user_token');
		if (user_token) {
			user_token = JSON.parse(user_token);

			let expires = new Date(user_token.expires);
			const expires_diff = expires.getTime() - new Date().getTime();
			if (expires_diff > 0 && expires_diff < ORDER_EXPIRE_TIME_HOURS * 3600000) {
				return user_token.token;
			} else {
				return '';
			}
		}
		return '';
	}

	static calculateAnimationTimeout = (k) => {
		const animTimeout = 450 + (k * 100);
		return (animTimeout > 3000) ? 3000 : animTimeout;
	}



	static fireEvent = (element, event) => {
		// console.log("in Fire Event");
		if (document.createEventObject) {
			// dispatch for IE
			// console.log("in IE FireEvent");
			var evt = document.createEventObject();
			return element.fireEvent('on' + event, evt)
		}
		else {
			// dispatch for firefox + others
			// console.log("In HTML5 dispatchEvent");
			var evt = document.createEvent("HTMLEvents");
			evt.initEvent(event, true, true); // event type,bubbling,cancelable
			return !element.dispatchEvent(evt);
		}
	}

	static getSettingsValue = (settings, settingsName = '', returnDefault = false) => {
		if (settingsName && settings && settings[settingsName] && settings[settingsName].value && (settings[settingsName].value).toString().length > 0) {
			return settings[settingsName].value;
		}
		return returnDefault;
	}

	// {this.translate(this.props.translations.common.dashboard, {count: 5, total: 8})}
	static translate(text, args = {}) {
		if (Object.keys(args).length === 0) {
			return text;
		}

		Object.entries(args).map(a => text = text.replace('{' + a[0] + '}', a[1]))
		return text;
	}

	static validatedCommentObject(allComments, variantId, comment) {
		const commentExists = allComments.findIndex(p => parseInt(p.variantId) == parseInt(variantId));
		if (commentExists === -1) {
			if (!comment) {
				return false;
			}

			let currentComment = {}
			currentComment.variantId = variantId;
			currentComment.comment = comment;

			allComments.push(currentComment);
		} else {
			allComments[commentExists].comment = comment;
		}

		return allComments;
	}


	// Categories and Subcategories
	static splitCatSubcatOnTwoRowsBySpace(name, settings) {
		if ((settings && settings.split_header_category_subcategory_on_two_rows && settings.split_header_category_subcategory_on_two_rows.value && settings.split_header_category_subcategory_on_two_rows.value == '1')
			&& (name.indexOf(' ') !== -1)) {
			// Used if two spaces in string // bugfix
			let secondPartsString = '';
			let secondPartArr = (name.split(' ')).slice(1);
			secondPartArr.map(a => secondPartsString += (' ' + a))

			return <React.Fragment>{name.split(' ')[0]}<br />{secondPartsString}</React.Fragment>
		}
		return name;
	}

	// Orders
	// Checks if ordering is enabled, based on settings
	static disabledOrdering(settings) {
		const currentHour = new Date().getHours();
		const currentMinutes = new Date().getMinutes();
		let working_hours = settings && settings.working_hours ? settings.working_hours.value : '';

		let minPickupTimeHours = 0;
		let minPickupTimeMinutes = 0;
		let maxPickupTimeHours = 0;
		let maxPickupTimeMinutes = 0;

		let minPickupTimeHoursTwo = 0;
		let minPickupTimeMinutesTwo = 0;
		let maxPickupTimeHoursTwo = 0;
		let maxPickupTimeMinutesTwo = 0;

		if (working_hours) {
			working_hours = working_hours.split('-');

			let minPickupTime = working_hours[0];
			minPickupTimeHours = parseInt(minPickupTime.split(':')[0]);
			minPickupTimeMinutes = parseInt(minPickupTime.split(':')[1]);

			let maxPickupTime = working_hours[1];
			maxPickupTimeHours = parseInt(maxPickupTime.split(':')[0]);
			maxPickupTimeMinutes = parseInt(maxPickupTime.split(':')[1]);
		}

		let second_working_hours = settings && settings.second_working_hours ? settings.second_working_hours.value : '';
		if (second_working_hours) {
			second_working_hours = second_working_hours.split('-');

			let minPickupTimeTwo = second_working_hours[0];
			minPickupTimeHoursTwo = parseInt(minPickupTimeTwo.split(':')[0]);
			minPickupTimeMinutesTwo = parseInt(minPickupTimeTwo.split(':')[1]);

			let maxPickupTimeTwo = second_working_hours[1];
			maxPickupTimeHoursTwo = parseInt(maxPickupTimeTwo.split(':')[0]);
			maxPickupTimeMinutesTwo = parseInt(maxPickupTimeTwo.split(':')[1]);
		}

		if (working_hours && settings.disable_overtime_orders && settings.disable_overtime_orders.value && settings.disable_overtime_orders.value == 1) {
			if ((minPickupTimeHours <= currentHour) && (currentHour <= maxPickupTimeHours)) {
				if ((currentHour == minPickupTimeHours) && (currentMinutes < minPickupTimeMinutes)) {
					return true; //disabled
				} else if ((currentHour == maxPickupTimeHours) && (currentMinutes > maxPickupTimeMinutes)) {
					if (second_working_hours && (maxPickupTimeHours == minPickupTimeHoursTwo)) {
						if ((currentMinutes > minPickupTimeMinutes) && (currentMinutes < minPickupTimeMinutesTwo)) {
							return true; //disabled
						}
						return false;
					}
					return true; //disabled
				}
				return false;
			} else if (second_working_hours && ((minPickupTimeHoursTwo <= currentHour) && (currentHour <= maxPickupTimeHoursTwo))) {
				if ((currentHour == minPickupTimeHoursTwo) && (currentMinutes < minPickupTimeMinutesTwo)) {
					return true; //disabled
				} else if ((currentHour == maxPickupTimeHoursTwo) && (currentMinutes > minPickupTimeMinutesTwo)) {
					return true; //disabled
				}
				return false;
			}
			return true;
		}

		return false;
	}

	// Always prints message, check first if ordering is enabled by calling: this.disabledOrdering
	static getDisabledOrderingMessage(translations, settings) {
		const currentHour = new Date().getHours();

		const working_hours = this.getSettingsValue(settings, "working_hours").split('-');
		const minPickupTime = working_hours[0];
		const minPickupTimeHours = parseInt(minPickupTime.split(':')[0]);
		const minPickupTimeMinutes = parseInt(minPickupTime.split(':')[1]);

		const maxPickupTime = working_hours[1];
		const maxPickupTimeHours = parseInt(maxPickupTime.split(':')[0]);
		const maxPickupTimeMinutes = parseInt(maxPickupTime.split(':')[1]);

		let second_working_hours = this.getSettingsValue(settings, 'second_working_hours')

		if (second_working_hours == '') {
			if (currentHour >= maxPickupTimeHours && currentHour <= 23) {
				if (translations.pages && translations.pages.cart_delivery) {
					return this.translate(translations.pages.cart_delivery.restaurant_closed_label_tommorow, { value: this.appendLeadingZeroes(minPickupTimeHours) + ':' + this.appendLeadingZeroes(minPickupTimeMinutes) })
				}
			} else {
				if (translations.pages && translations.pages.cart_delivery) {
					return this.translate(translations.pages.cart_delivery.restaurant_closed_label_today, { value: this.appendLeadingZeroes(minPickupTimeHours) + ':' + this.appendLeadingZeroes(minPickupTimeMinutes) })
				}
			}
		} else {
			second_working_hours = second_working_hours.split('-');

			const minPickupTimeTwo = second_working_hours[0];
			const minPickupTimeHoursTwo = parseInt(minPickupTimeTwo.split(':')[0]);
			const minPickupTimeMinutesTwo = parseInt(minPickupTimeTwo.split(':')[1]);

			const maxPickupTimeTwo = second_working_hours[1];
			const maxPickupTimeHoursTwo = parseInt(maxPickupTimeTwo.split(':')[0]);
			const maxPickupTimeMinutesTwo = parseInt(maxPickupTimeTwo.split(':')[1]);

			if (translations && translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.you_can_order_between) {
				return this.translate(translations.pages.cart_delivery.you_can_order_between, { from: this.appendLeadingZeroes(minPickupTimeHours) + ':' + this.appendLeadingZeroes(minPickupTimeMinutes), to: this.appendLeadingZeroes(maxPickupTimeHours) + ':' + this.appendLeadingZeroes(maxPickupTimeMinutes), from_two: this.appendLeadingZeroes(minPickupTimeHoursTwo) + ':' + this.appendLeadingZeroes(minPickupTimeMinutesTwo), to_two: this.appendLeadingZeroes(maxPickupTimeHoursTwo) + ':' + this.appendLeadingZeroes(maxPickupTimeMinutesTwo) })
			}
		}

	}

	// Product ingredients
	static getCartProductQuantity(product_variant_id, selectedIngredients) {
		var to_order = window.localStorage.getItem('to_order');//setItem('token', token)
		to_order = JSON.parse(to_order);
		let foundSameIngredients = -1;
		let cartProductQuantity = 1;

		if (to_order && Object.values(to_order).length > 0) {
			Object.values(to_order).map(o => {
				// console.log('ooooooooooooooh', o)
				foundSameIngredients = o.find(i => ((i.product_variant_id == product_variant_id) && (parseInt(i.ingredients.required) == parseInt(selectedIngredients.required) && (isEqual(sortBy(i.ingredients.addable), sortBy(selectedIngredients.addable)) && (isEqual(sortBy(i.ingredients.removable), sortBy(selectedIngredients.removable)))))));
				if (foundSameIngredients) {
					cartProductQuantity = parseInt(foundSameIngredients.quantity) + 1
				}
			})
		}
		// console.log('cartProductQuantity', cartProductQuantity)
		return cartProductQuantity;
	}


	static differentIngridients(product_variant_id, selectedIngredients) {
		// console.log('product_variant_id', product_variant_id)
		// console.log('selectedIngredients', selectedIngredients)
		var to_order = window.localStorage.getItem('to_order');//setItem('token', token)
		to_order = JSON.parse(to_order);

		let differentIngridients = false;
		if (to_order && Object.values(to_order).length > 0) {
			// console.log('to_order', to_order)
			Object.values(to_order).map(o => {
				// console.log('real o', o)

				// console.log('o.length', o.length)
				// console.log('o.filter(op=>op.product_variant_id == product_variant_id', o.filter(op => op.product_variant_id == product_variant_id));

				if (o.filter(op => op.product_variant_id == product_variant_id).length == 1) {
					// console.log('1 e')
					// console.log('o[0]', o[0])
					// console.log('o[0].ingredients', o[0].ingredients)
					// console.log('parseInt(o[0].product_variant_id) == parseInt(product_variant_id', parseInt(o[0].product_variant_id) == parseInt(product_variant_id));
					// console.log('parseInt(o[0].ingredients.required) == parseInt(selectedIngredients.required)', parseInt(o[0].ingredients.required) == parseInt(selectedIngredients.required))
					// console.log('isEqual(sortBy(o[0].ingredients.addable), sortBy(selectedIngredients.addable))', isEqual(sortBy(o[0].ingredients.addable), sortBy(selectedIngredients.addable)))
					// console.log('isEqual(sortBy(o[0].ingredients.removable), sortBy(selectedIngredients.removable))', isEqual(sortBy(o[0].ingredients.removable), sortBy(selectedIngredients.removable)))

					if (parseInt(o[0].product_variant_id) == parseInt(product_variant_id)
						&&

						(Object.values(o[0].ingredients).length == 0 ||
							(parseInt(o[0].ingredients.required) == parseInt(selectedIngredients.required)
								&& (isEqual(sortBy(o[0].ingredients.addable), sortBy(selectedIngredients.addable)))
								&& (isEqual(sortBy(o[0].ingredients.removable), sortBy(selectedIngredients.removable)))
							)
						)
					) {

						differentIngridients = true;
						// console.log('imago 1')
					}

				} else if (o.filter(op => op.product_variant_id == product_variant_id).length > 1) {
					// console.log('nee 1')
					Object.values(o).map(oo => {
						if (parseInt(oo.product_variant_id) == parseInt(product_variant_id)
							&& parseInt(oo.ingredients.required) == parseInt(selectedIngredients.required)
							&& (isEqual(sortBy(oo.ingredients.addable), sortBy(selectedIngredients.addable)))
							&& (isEqual(sortBy(oo.ingredients.removable), sortBy(selectedIngredients.removable)))
						) {

							differentIngridients = true;
							// console.log('imago 2')
						}
					})
				}

			})
		}
		// console.log('razlichen li e :', !differentIngridients)
		return !differentIngridients;

	}


	static normalizeCartProducts = (cartProducts = []) => {
		let newp = [];

		Object.values(cartProducts).map(p => {
			newp = newp.concat(p)
		})

		return newp;

	}


	// Each price for each product - List products
	static calculateProductPriceListingProducts = (productVariantPrice, discountPercent) => {
		let calculatedProductPrice = productVariantPrice;

		if (discountPercent) {
			calculatedProductPrice = (parseFloat(calculatedProductPrice - ((parseFloat(discountPercent) * parseFloat(calculatedProductPrice)) / 100))).toFixed(2);
		}

		// TODO - add price for default required ingredient

		return calculatedProductPrice;

	}



	// Ingredients
	static getIngredientNamesByIds = (ingredientIds, productIngredients) => {
		// const { productIngredients } = this.props;
		// console.log('checking for: ', ingredientIds)
		let ingredientsNames = '';
		if (productIngredients && Object.values(productIngredients).length) {
			// debugger;
			if (Array.isArray(ingredientIds)) {
				// console.log('arr')
				Object.values(ingredientIds).map((i, k) => {
					// console.log('map: ', i, ' ', k)

					if (productIngredients[i] && productIngredients[i].name) {
						if (k != 0) {
							ingredientsNames += ', ';
						}
						ingredientsNames += productIngredients[i].name;
					}
					// console.log('ingredientsNames: ', ingredientsNames)
				})
			} else {
				// console.log('elsa')
				if (productIngredients && productIngredients[parseInt(ingredientIds)] && productIngredients[parseInt(ingredientIds)].name) {
					ingredientsNames += productIngredients[parseInt(ingredientIds)].name;
				}
				// console.log('ingredientsNames :', ingredientsNames)
			}
		}

		return ingredientsNames;
	}

	static addIngredientsPrice = (item, productIngredients) => {
		let ingredientsPrice = 0;
		// console.log('item INGR', item)
		// console.log('productIngredients', productIngredients)
		// console.log('parseInt(item.ingredients.required)', parseInt(item.ingredients.required))
		// console.log('item.product_ingredients', item.product_ingredients)
		// console.log('productIngredients', productIngredients)
		if (item && item.ingredients && item.product_ingredients && Object.values(item.product_ingredients).length > 0 && productIngredients) {
			if (item.ingredients.required) {
				if (productIngredients && productIngredients[parseInt(item.ingredients.required)] && productIngredients[parseInt(item.ingredients.required)].price) {
					// console.log('adding: productIngredients[parseInt(item.ingredients.required)].price', productIngredients[parseInt(item.ingredients.required)].price)
					ingredientsPrice += parseFloat(productIngredients[parseInt(item.ingredients.required)].price);
				}
			}

			if (item.ingredients.addable) {
				Object.values(item.ingredients.addable).map(ingrId => {
					if (productIngredients && productIngredients[parseInt(ingrId)] && productIngredients[parseInt(ingrId)].price) {
						// console.log('adding: productIngredients[parseInt(ingrId)].price', productIngredients[parseInt(ingrId)].price)
						ingredientsPrice += parseFloat(productIngredients[parseInt(ingrId)].price);
					}
				})
			}

			// if (item.ingredients.removable) {
			//     Object.values(item.ingredients.removable).map(ingrId => {
			//         if (this.props.productIngredients && this.props.productIngredients[parseInt(ingrId)] && this.props.productIngredients[parseInt(ingrId)].price) {
			//             console.log('adding: this.props.productIngredients[parseInt(ingrId)].price', this.props.productIngredients[parseInt(ingrId)].price)
			//             ingredientsPrice += parseFloat(this.props.productIngredients[parseInt(ingrId)].price);
			//         }
			//     })
			// }

		}

		return ingredientsPrice;
	}


	// Each price for each product - Cart modal
	/*
		@forceQuantity - Used when you have to know the price of that product, if passed '1' the current product quantity is not valid and not calculated
	*/
	static calculateProductPriceOrder = (item, settings, productIngredients, forceQuantity) => {
		let totalCartPrice = 0;
// console.log('item', item)
		// Cart price structure / Order price structure
		const price = item.price || item.product_variant.price;
		const discount_percent = item.discount_percent;
		const quantity = item.quantity;
		const additional_price = item.additional_price || item.product_variant.additional_price;

		//Product discount
		let productPrice = this.calculateProductPriceListingProducts(price, discount_percent);

		// Product quantity
		let productQuantity = forceQuantity ? forceQuantity : quantity;

		//ingredients
		totalCartPrice += (parseFloat(productPrice) + parseFloat(this.addIngredientsPrice(item, productIngredients))) * 1 * productQuantity;

		// BOX
		if ((settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && (((parseFloat(additional_price)).toFixed(2)) != '0.00')) {
			totalCartPrice += parseFloat(additional_price) * 1 * productQuantity;
		}

		return parseFloat(totalCartPrice).toFixed(2);

	}


	// Calculate total price for all products - Cart modal	
	static calculateTotalPriceForOrder = (products, settings, productIngredients) => {
		var totalCartPrice = 0;
		Object.values(products).map((item, k) => {
			totalCartPrice += parseFloat(this.calculateProductPriceOrder(item, settings, productIngredients));
		})

		return parseFloat(totalCartPrice).toFixed(2);
	}

	static getOrderTypeDiscount = (choosenPlace, orderType, currentCartPrice, returnObjectInfo) => {
		let currentPlaceOrderType = choosenPlace && choosenPlace.ordertypes && choosenPlace.ordertypes.find(t => t.type == orderType);
		let currentPlaceOrderTypeDiscount = currentPlaceOrderType && currentPlaceOrderType.discounts ? currentPlaceOrderType.discounts : [];

		let negative_price = 0;
		let returnObject = {};

		currentPlaceOrderTypeDiscount.sort((a, b) => (a.price_over - b.price_over)).map((d, k) => {
			if (currentCartPrice >= d.price_over && (typeof currentPlaceOrderTypeDiscount[k + 1] === "undefined" || (typeof currentPlaceOrderTypeDiscount[k + 1] !== "undefined" && currentCartPrice < currentPlaceOrderTypeDiscount[k + 1].price_over))) {
				returnObject.value_type = d.value_type;
				returnObject.value = d.value;

				if (d.value_type == 'percent') {
					negative_price += (parseFloat(d.value) * parseFloat(currentCartPrice) / 100);
				} else if (d.value_type == 'money') {
					negative_price += parseFloat(d.value);
				}
			}
		})

		return returnObjectInfo ? returnObject : negative_price;
	}


	static getOrderTypePrice = (choosenPlace, orderType, currentCartPrice, returnObjectInfo) => {
		// Delivery only for now
		// Money only for now value_type
		// Price instead of value
		let currentPlaceOrderType = choosenPlace.ordertypes.find(t => t.type == orderType);
		let currentPlaceOrderTypePrices = currentPlaceOrderType && currentPlaceOrderType.prices ? currentPlaceOrderType.prices : [];
		let positive_price = 0;
		let returnObject = {};


		currentPlaceOrderTypePrices.sort((a, b) => (a.price_over - b.price_over)).map((d, k) => {
			if (currentCartPrice >= d.price_over && (typeof currentPlaceOrderTypePrices[k + 1] === "undefined" || (typeof currentPlaceOrderTypePrices[k + 1] !== "undefined" && currentCartPrice < currentPlaceOrderTypePrices[k + 1].price_over))) {
				positive_price += parseFloat(d.price);
				returnObject.value_type = 'money';
				returnObject.value = d.price;
			}
		});

		return returnObjectInfo ? returnObject : positive_price;

	}

// Calculate final price for all products - Order Modal
	static calculateFinalPriceForOrder = (orderType, products, settings, productIngredients, regions, selectedRegion, choosenPlace) => {
		var totalCartPrice = parseFloat(this.calculateTotalPriceForOrder(products, settings, productIngredients));
		let finalCartPrice = totalCartPrice;
		// debugger;
		let positive_price = 0;
		let negative_price = 0;

		if (orderType == 'delivery') {
			// Do not calculcate if region is not selected
			// if (regions.length > 0 && !selectedRegion) {
			// 	return null;
			// }

			// Deliery Discount - FIRST (before delivery prices, because discount is not valid for delivery prices)
			negative_price += this.getOrderTypeDiscount(choosenPlace, 'delivery', finalCartPrice)

			const orderTypeCurrentPrice = this.getOrderTypePrice(choosenPlace, 'delivery', finalCartPrice, true);
			if (choosenPlace && choosenPlace.ordertypes && orderTypeCurrentPrice && orderTypeCurrentPrice.value) {
				// Delivery price order type - (IF NOT REGIONS)
				positive_price += orderTypeCurrentPrice.value
			} else if (regions.length > 0 && selectedRegion) {
				let selectedRegionPrice = regions.find(r => r.id == selectedRegion);
				selectedRegionPrice = selectedRegionPrice.delivery_price ? selectedRegionPrice.delivery_price : 0;
				positive_price += parseFloat(selectedRegionPrice);
			}

		} else if (orderType == 'preorder') {
			// Preorder Discount
			negative_price += this.getOrderTypeDiscount(choosenPlace, 'preorder', finalCartPrice)
		}

		positive_price = parseFloat(positive_price);
		negative_price = parseFloat(negative_price);

		finalCartPrice = finalCartPrice + positive_price - negative_price;
		finalCartPrice = parseFloat(finalCartPrice).toFixed(2);

		return finalCartPrice;

		// return (totalCartPrice != finalCartPrice) ? finalCartPrice : null;
	}



}

export default Utils;