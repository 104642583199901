import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { LocalizeProvider } from "react-localize-redux";
import CircularProgress from '@material-ui/core/CircularProgress';

import Router from "./Router";
import RouterMultiplePlaces from "./RouterMultiplePlaces";
import Utils from "./utils/Utils";
import MetaAndOg from './common/MetaAndOg';
import { changeLanguage } from './language/actions/language.action'
import { setChosenPlace } from './places/actions/places.actions';
import { getAllProducts } from './products/actions/products.action';
import { getCategoriesHierarchy } from './categories/actions/categories.action';

const styles = theme => ({
  pageContainer: {
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',

    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updated: false
    }
  }
  componentDidMount() {
    document.onreadystatechange = function () {
      var state = document.readyState
      if (state == 'interactive') {
        document.getElementById('root').style.visibility = "hidden";
      } else if (state == 'complete') {
        document.getElementById('root').style.visibility = "visible";
        var fadeTarget = document.getElementById("load");
        var fadeEffect = setInterval(function () {
          if (!fadeTarget.style.opacity) {
            fadeTarget.style.opacity = 1;
          }
          if (fadeTarget.style.opacity > 0) {
            fadeTarget.style.opacity -= 0.1;
          } else {
            clearInterval(fadeEffect);
            document.getElementById('load').style.visibility = "hidden";
          }
        }, 20);
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    var placesCount = nextProps.places.length;

    // if (!this.state.updated) {
    // this.setState({updated: true});
    // }

    if (placesCount === 1 && !this.props.chosenPlace) {
      nextProps.setChosenPlace(nextProps.places[0].id, true);
      // if (nextProps.allProducts.length == 0) {
      //   nextProps.getAllProducts(nextProps.places[0].id);
      // }
      // nextProps.getCategoriesHierarchy(nextProps.places[0].id);
    }
  }

  componentDidUpdate() {
    if ((localStorage.getItem('initial_translate') == 1) && this.props.settings.translations_type && this.props.settings.translations_type.value == 1 && this.props.settings.first_init_lang.value.length > 0) {
      const makeTranslation = () => {
        let element = document.querySelector('.goog-te-combo');
        if (element) {
          window.localStorage.setItem('initial_translate', 0);
          element.value = this.props.settings.first_init_lang.value;
          Utils.fireEvent(element, 'change');
        }
      }

      setTimeout(() => {
        makeTranslation();
      }, 300);
      setTimeout(() => {
        makeTranslation();
      }, 400);
      setTimeout(() => {
        makeTranslation();
      }, 500);
    }


    if (this.props.settings.default_lang && this.props.settings.default_lang.value) {
      // this.props.changeLanguage(this.props.settings.default_lang.value);
      this.props.changeLanguage(this.props.currLang);
    }

  }

  render() {
    const { classes, isLoading, settings, places } = this.props;
    var placesCount = places.length;

    return (
      <LocalizeProvider>
        <MetaAndOg />
        {isLoading ? <CircularProgress className="is-loading" /> : null}
        {/* prevent hide url bar */}
        <div
          id="page-container"
          className={classes.pageContainer}
          style={{
            backgroundImage: this.props.mainBackground + ', url(' + (settings.menu_background_image && settings.menu_background_image.value ? Utils.getSettingsPath(settings.menu_background_image.value) : '') + ')',
            overflow: 'auto',
            height: '100%'
          }} >
          {/* {placesCount > 1 ? <RouterMultiplePlaces /> : <Router />} */}
          <Router />
        </div>
      </LocalizeProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.products.isLoading || state.categories.isLoading || false,
    settings: state.settings.settings || [],
    places: state.places.places || [],
    chosenPlace: state.places.chosen_place || 0,
    currLang: state.lang.currLang,
    mainBackground: state.settings.mainBackground || 'linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) )',
    allProducts: state.products.all_products || []
  }
};

export default withStyles(styles)(connect(mapStateToProps, { changeLanguage, setChosenPlace, getAllProducts, getCategoriesHierarchy })(App));
