import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducer';

let initialState = {};
const middleware = [thunk];
// console.log("WIDNDOWS ", window.__INITIAL_STATE__)

initialState = window.__INITIAL_STATE__

// console.log("INIII ", initialState)
// Allow the passed state to be garbage-collected
delete window.__INITIAL_STATE__

var element = document.getElementById("toDelete");
if(element) element.parentNode.removeChild(element);

// if(initialState && initialState.places) {
//     window.localStorage.setItem('client', initialState.client);
    
// } else {
//     initialState = {};
// }

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, initialState, composeEnhancers(
    applyMiddleware(...middleware),
));

// const store = createStore(rootReducer, initialState, compose(
//     applyMiddleware(...middleware),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// ));

export default store;