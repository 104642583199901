import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import WarningIcon from '@material-ui/icons/Warning';

import Utils from '../../utils/Utils';

const styles = theme => ({
    errorContainer: {
        position: 'absolute',
        top: '30%',
        color: 'white',
        textAlign: 'center',
        width: '100%',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    },
    errorIcon: {
        color: '#ffffff73',
        fontSize: 75,
    },
    errorTitle: {
        fontWeight: 'bold',
        margin: 0,
        fontSize: 60,
        fontStyle: 'italic',
        paddingLeft: 10,
        paddingRight: 10,
    },
    errorDescription: {
        color: '#ccc',
    },
    backBtn: {
        color: 'white',
        fontSize: 20,
        marginTop: 20,
        borderBottom: '1px solid white',
        borderRadius: 0,
    }
});



class Page404 extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Grow in={true}>
                <div className={classes.errorContainer}>
                    <MoodBadIcon className={classes.errorIcon} />
                    <p className={classes.errorTitle}>
                        Error 404
                    </p>
                    <p className={classes.errorDescription}>
                        Requested page was not found!
                    </p>
                    <Button
                        onClick={() => Utils.navigateTo('/')}
                        startIcon={<ArrowBackIosIcon />}
                        className={classes.backBtn}
                    >
                        Back
                    </Button>
                </div>

            </Grow >
        )

    }
}

export default withStyles(styles)(Page404);

