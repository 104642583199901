import { GET_DISTRIBUTORS } from './../actions/types';

const initialState = {
    distributors: [],
}

export default function(state = initialState, action) {
    state.errors = [];

    switch(action.type) {
        case GET_DISTRIBUTORS:
            return {
                ...state,
                distributors: action.payload,
            }
        default:
            return state;
    }
}