import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Collapse from '@material-ui/core/Collapse';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CardMedia from '@material-ui/core/CardMedia';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import TextsmsIcon from '@material-ui/icons/Textsms';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';

import clsx from 'clsx';

import { merge } from 'lodash';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import { DEFAULT_PRODUCT_IMAGE } from '../../constants';
// import ProductImage from './ProductImage'
import Utils from '../../utils/Utils'
import { customStyles, designV1_1, designV1_2, designV2_1, designV2_2, designV3_1, designV3_2, designV3_3 } from '../styles/styles';

import { setProductPageId } from '../actions/products.action'
import { showHeaderCategory } from '../../categories/actions/categories.action';


const mainDesign = window.localStorage.getItem('design');
const secondaryDesign = window.localStorage.getItem('design');

const designVersion = mainDesign ? parseInt(JSON.parse(mainDesign).main) : 3;
const designSubVersion = secondaryDesign ? parseInt(JSON.parse(secondaryDesign).secondary) : 3;

let gridXs = 12;
let gridSm = 6;
let gridMd = 4;
let gridLg = 3;
// ------------------------------------------- Default Definitions ^

// Load styles according to selected theme
let cssStyles = designV1_1;
switch (designVersion) {
    case 1:
        if (designSubVersion === 1) {
            cssStyles = designV1_1;
            break;
        } else {
            cssStyles = designV1_2;
            break;
        }
    case 2:
        gridXs = 6; gridSm = 4; gridMd = 3; gridLg = 2;
        if (designSubVersion === 1) {
            cssStyles = designV2_1;
            break;
        } else {
            cssStyles = designV2_2;
            break;
        }
    case 3:
        // cssStyles = this.components['designV3_'+designSubVersion]
        if (designSubVersion === 1) {
            cssStyles = designV3_1;
        } else if (designSubVersion === 2) {
            cssStyles = designV3_2;
        } else {
            cssStyles = designV3_3;
        }
        break;
    default:
        cssStyles = designV3_3;
        break;

}
const styles = theme => (merge(cssStyles, customStyles));


class ProductListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alergensExpanded: false,
            photoIndex: 0,
            photoOpened: false,
            variantQuantity: 1,
        }
    }


    // addToCartHandler = (prodId, prodName) => {
    //     const product_variants = this.props.product.product_variants;
    //     if (product_variants.length === 1) {
    //         this.props.addToCart(prodId, prodName);
    //         // 
    //     } else {
    //         // Open modal
    //     }

    // }

    openProductPage = (prod) => {
        // console.log(prod)
        // Utils.navigateTo('product/' + prod.id);
        const { translations } = this.props;
        this.props.setProductPageId(prod.id);
        if (this.props.scrollToTopAfterClick) {
            window.scrollTo(0, 0);

        }
        window.location.hash = prod.slug;
        this.props.showHeaderCategory(translations && translations.translations && translations.translations.common ? translations.translations.common.action.back : '');
        // removeProductPageId
    }

    productQuantityCounts = (selectedQuantity) => {
        let menuItems = [];
        let maxShowQuantity = 100;
        maxShowQuantity = Math.max(maxShowQuantity, selectedQuantity)

        for (let i = 1; i <= maxShowQuantity; i++) {
            menuItems.push(< MenuItem value={i}>{i}</MenuItem >)
        }

        return menuItems;
    }

    render() {
        const { classes, addToCartBtnClicked, settings, translations, showSubCatInProductHeader, setCommentModalProps } = this.props;
        let { id, name, product_variants, product_images, description, allergens, subcategory, in_stock, label, hide_for_ordering, isLiquid, discount_percent } = this.props.product;
        const { photoIndex, photoOpened } = this.state;
        // console.log('this.props.product', this.props.product)
        const hideProductImage = (settings && settings.hide_product_images_without_img && settings.hide_product_images_without_img.value ? parseInt(settings.hide_product_images_without_img.value) : 0);
        const ClickableVariantsOnlyIfProdWithoutImg = (settings && settings.clickable_product_variants_only_for_products_without_image && settings.clickable_product_variants_only_for_products_without_image.value ? parseInt(settings.clickable_product_variants_only_for_products_without_image.value) : 0);

        name = this.props.product.translations[this.props.currLang] && this.props.product.translations[this.props.currLang].name || this.props.product.name;
        description = this.props.product.translations[this.props.currLang] && this.props.product.translations[this.props.currLang].description || this.props.product.description;

        let prodImgPath = '';
        if (typeof product_images[0] !== "undefined" && typeof product_images[0].path !== "undefined" && product_images[0].path) {
            prodImgPath = Utils.getProductImagePath(product_images[0].path);
        }

        let prodThumbImgPath = '';
        if (typeof product_images[0] !== "undefined" && typeof product_images[0].path !== "undefined" && product_images[0].path) {
            prodThumbImgPath = Utils.getProductThumbImagePath(product_images[0].path);
        }

        const images = [prodImgPath];
        let alergensInfo;
        let alergensButton;

        if (hide_for_ordering == true && (Utils.getSettingsValue(settings, 'hide_products_for_ordering_with_token') == 1) && (Utils.getUserToken() != '')) {
            return null;
        }

        if (Object.values(allergens).length > 0) {
            alergensInfo =
                Object.values(allergens).map(al => (
                    <ListItem button className={classes.alergensListItem}>
                        <img src={al.image} className={classes.alergenIcon} />
                        <ListItemText primary={al.name} />
                    </ListItem>
                ))

            alergensButton =
                // (designVersion !== 1) ?
                <IconButton aria-label="info" onClick={() => { this.setState({ alergensExpanded: !this.state.alergensExpanded }) }} style={{ color: '#1a1a1a', float: 'right' }}>
                    <InfoIcon />
                    {/* <SpellcheckIcon/> */}
                </IconButton>
            // :
            // null
        }

        let addButton =
            ((settings.clickable_product_variants && settings.clickable_product_variants.value == 0) && Utils.getSettingsValue(settings, 'view_product_page') == 0 || Utils.getSettingsValue(settings, 'view_product_page') == 2) ?
                <Button
                    variant="text"
                    className={classes.addToCartButton}
                    endIcon={<AddIcon className={classes.addToCartButtonIcon} />}
                    onClick={() => addToCartBtnClicked(id)}
                >
                    {designVersion === 3 && designSubVersion === 3 ?
                        null
                        :
                        (settings.order_type && settings.order_type.value && settings.order_type.value == 1 ? 'Добави' : 'Поръчай')
                    }
                </Button>
                :
                null
            ;
        addButton = null; //

        const descriptionText = <Typography component="h3" variant="subtitle1" color="textSecondary" className={classes.productDescription}>{name !== description ? description : ''}</Typography>

        let timeOutAnimation = Utils.calculateAnimationTimeout(this.props.k);
        // console.log('this.state.variantQuantity', this.state.variantQuantity)
        return (
            <Grow in={true} timeout={{ enter: timeOutAnimation, exit: timeOutAnimation }} key={this.props.k}>
                <Grid item xs={gridXs} sm={gridSm} md={gridMd} lg={gridLg} >

                    <Card className={in_stock == false ? clsx(classes.root, classes.outOfStockCard) : classes.root} data-class="product-container">

                        {showSubCatInProductHeader && showSubCatInProductHeader == 1 ?
                            <span className={classes.subcategoryName}>{subcategory.name}</span>
                            :
                            null
                        }

                        {label && label.length > 0 && showSubCatInProductHeader != 1 ?
                            <span className={classes.labelName} data-class="product-label">{label}</span>
                            :
                            null
                        }

                        {in_stock == false ?
                            <span className={classes.outOfStockLabel}>{translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.out_of_stock ? translations.translations.pages.products.out_of_stock : ''}</span>
                            :
                            null
                        }

                        <div className={classes.details}>
                            <CardContent className={classes.content}>

                                {/* Allergens and name container */}
                                <Typography component="h2" variant="h5" className={classes.productName} style={{ paddingTop: ((((showSubCatInProductHeader == 1 || in_stock == false || (label && label.length > 0)) && hideProductImage && !prodThumbImgPath)) ? 35 : 0) }}>
                                    {alergensButton} <span onClick={() => Utils.getSettingsValue(settings, 'view_product_page') != 0 ? this.openProductPage(this.props.product) : void (0)} className={(Utils.getSettingsValue(settings, 'view_product_page') != 0) ? 'cursor-pointer-onhover' : ''} data-class="product-name">{name}</span>
                                </Typography>

                                {/* Description container */}
                                {((designVersion === 1 && designSubVersion === 2) || (designVersion === 2) || (designVersion === 3)) && description.length > 0 ?
                                    <span onClick={() => Utils.getSettingsValue(settings, 'view_product_page') != 0 ? this.openProductPage(this.props.product) : void (0)} className={(Utils.getSettingsValue(settings, 'view_product_page') != 0) ? 'cursor-pointer-onhover' : ''} data-class="product-description" >{descriptionText}</span>
                                    :
                                    null
                                }

                                {/* Allergens collapse container */}
                                <Collapse in={this.state.alergensExpanded} timeout="auto" unmountOnExit className={classes.alergensCollapseContainer}>
                                    <div className={classes.alergensCointainer}>
                                        <div style={{ fontWeight: 'bold', fontSize: 15 }}>{translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.alergens ? translations.translations.pages.products.alergens : ''}</div>
                                        <List component="nav" aria-label="secondary alergens">
                                            {alergensInfo}
                                        </List>
                                        <Divider />
                                    </div>
                                </Collapse>

                                {(designVersion === 3 && designSubVersion === 3) ? addButton : null}

                            </CardContent>

                            {/* Variants container */}
                            <div className={classes.options}>
                                <Grid container direction="row" alignItems="center">
                                    {product_variants.map((variant, k) => {
                                        let calculatedProductPrice = Utils.calculateProductPriceListingProducts(variant.price, discount_percent);

                                        return (
                                            <React.Fragment>
                                                {Utils.getSettingsValue(settings, 'order_button_on_product_variant') == 1 ?
                                                    <ListItem className={clsx(classes.variantsContainer, classes.variantsContainerMultipleButtons)} key={k} data-class="product-variant-button" >
                                                        <Grid item xs={4} className={classes.variantColumnWithOrderButtonContainer}>
                                                            <div>
                                                                <div>
                                                                    <b className={classes.variantColumnWithOrderButtonVariantContainer}>
                                                                        {discount_percent ?
                                                                            <React.Fragment>
                                                                                <span className="strikethrough"><span className={classes.oldPrice}>{variant.price}</span></span>&nbsp;
                                                                            </React.Fragment>
                                                                            :
                                                                            null
                                                                        }

                                                                        {calculatedProductPrice.includes('.') ?
                                                                            <React.Fragment>
                                                                                {calculatedProductPrice.split('.')[0]}<span data-class="product-variant-price-dot">.</span><span data-class="product-variant-price-cents">{calculatedProductPrice.split('.')[1]}</span>
                                                                            </React.Fragment>
                                                                            :
                                                                            calculatedProductPrice
                                                                        }
                                                                        {settings.default_currency_suffix && settings.default_currency_suffix.value ? ' ' + settings.default_currency_suffix.value : ' лв.'}
                                                                    </b>
                                                                </div>
                                                                <div className={classes.variantColumnWithOrderButtonPriceContainer}>{variant.translations[this.props.currLang] && variant.translations[this.props.currLang].size || variant.size}</div>
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={4} className={classes.variantColumnWithOrderButtonContainer}>
                                                            <Button
                                                                variant="text"
                                                                className={classes.addCommentVariantOrderButton}
                                                                style={{ color: this.props.products.product_variants_comment.findIndex(p => p.variantId === variant.id && p.comment.length > 0) !== -1 ? '#d95900' : 'initial' }}
                                                                // endIcon={<TextsmsIcon className={classes.addToCartVariantButtonIcon} />}
                                                                onClick={() => setCommentModalProps(name, variant.id)}
                                                            >
                                                                {translations.translations && translations.translations.common && translations.translations.common.comment ? translations.translations.common.comment : ''}
                                                            </Button>

                                                        </Grid>

                                                        <Grid item xs={4} className={classes.variantColumnWithOrderButtonContainer}>
                                                            <Button
                                                                variant="text"
                                                                className={classes.addToCartVariantOrderButton}
                                                                // endIcon={<AddIcon className={classes.addToCartVariantButtonIcon} />}
                                                                onClick={() => addToCartBtnClicked(id, variant.id)}
                                                            >
                                                                {translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.add ? translations.translations.pages.products.add : ''}
                                                            </Button>
                                                        </Grid>
                                                    </ListItem>
                                                    :
                                                    Utils.getSettingsValue(settings, 'order_button_on_product_variant') == 2 ?

                                                        <ListItem className={clsx(classes.variantsContainer, classes.variantsContainerMultipleButtons)} key={k} data-class="product-variant-button" >
                                                            <Grid container direction="row" alignItems="center">
                                                                <Grid container direction="row" alignItems="center">

                                                                    <Grid item xs={12} className={clsx(classes.variantColumnWithOrderButtonContainer, classes.variantColumnWithOrderButtonContainerFirstChild)}>
                                                                        <div>
                                                                            <span>{variant.translations[this.props.currLang] && variant.translations[this.props.currLang].size || variant.size}</span>
                                                                            &nbsp;
                                                                            <b>
                                                                                {discount_percent ?
                                                                                    <React.Fragment>
                                                                                        <span className="strikethrough"><span className={classes.oldPrice}>{variant.price}</span></span>&nbsp;
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                {calculatedProductPrice.includes('.') ?
                                                                                    <React.Fragment>
                                                                                        {calculatedProductPrice.split('.')[0]}<span data-class="product-variant-price-dot">.</span><span data-class="product-variant-price-cents">{calculatedProductPrice.split('.')[1]}</span>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    calculatedProductPrice
                                                                                }
                                                                                {settings.default_currency_suffix && settings.default_currency_suffix.value ? ' ' + settings.default_currency_suffix.value : ' лв.'}
                                                                            </b>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                                <br />
                                                                <Grid container direction="row" alignItems="center">


                                                                    <Grid item xs={6} className={classes.variantColumnWithOrderButtonContainer}>
                                                                        <Button
                                                                            variant="text"
                                                                            className={classes.addCommentVariantOrderButton}
                                                                            style={{ color: this.props.products.product_variants_comment.findIndex(p => p.variantId === variant.id && p.comment.length > 0) !== -1 ? '#d95900' : 'initial' }}

                                                                            // endIcon={<TextsmsIcon className={classes.addToCartVariantButtonIcon} />}
                                                                            onClick={() => setCommentModalProps(name, variant.id)}
                                                                        >
                                                                            {translations.translations && translations.translations.common && translations.translations.common.comment ? translations.translations.common.comment : ''}
                                                                        </Button>

                                                                    </Grid>

                                                                    <Grid item xs={6} className={classes.variantColumnWithOrderButtonContainer}>
                                                                        <Button
                                                                            variant="text"
                                                                            className={classes.addToCartVariantOrderButton}
                                                                            // endIcon={<AddIcon className={classes.addToCartVariantButtonIcon} />}
                                                                            onClick={() => addToCartBtnClicked(id, variant.id)}
                                                                        >
                                                                            {translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.add ? translations.translations.pages.products.add : ''}
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </ListItem>

                                                        :

                                                        <ListItem className={settings.clickable_product_variants && settings.clickable_product_variants.value == 1 ? classes.variantsContainerClickableVariants : classes.variantsContainer} key={k} data-class="product-variant-button" >
                                                            {/* {((settings.clickable_product_variants && settings.clickable_product_variants.value == 1) || (ClickableVariantsOnlyIfProdWithoutImg && hideProductImage && !prodThumbImgPath)) ?
                                                                <Grid item xs={1} style={{ marginRight: 10 }}>
                                                                {Utils.getSettingsValue(settings, 'cart_icon') == 1 ?
                                                                <AddShoppingCartIcon style={{ verticalAlign: 'middle' }} />
                                                                :
                                                                <AddIcon style={{ verticalAlign: 'middle' }} />
                                                                    }
                                                                    </Grid>
                                                                    :
                                                                    null
                                                                } */}
                                                            <Grid container direction="row" alignItems="center">

                                                                <Grid item xs={2}>


                                                                    {/* <Select
                                                                        style={{ width: '90%' }}
                                                                        name="variantQuantity"
                                                                        value={this.state.variantQuantity && this.state.variantQuantity[variant.id] ? this.state.variantQuantity[variant.id] : 1}
                                                                        onChange={(e) => this.setState({ [e.target.name]: { [variant.id]: e.target.value } })}
                                                                    >
                                                                        {this.productQuantityCounts(this.state.variantQuantity && this.state.variantQuantity[variant.id] ? this.state.variantQuantity[variant.id] : 1)}
                                                                    </Select> */}

                                                                    <TextField
                                                                        type="number"
                                                                        InputProps={{ inputProps: { min: 1 } }}
                                                                        name="variantQuantity"
                                                                        value={
                                                                            this.state.variantQuantity && this.state.variantQuantity == 1 ? this.state.variantQuantity :
                                                                                (
                                                                                    (this.state.variantQuantity[variant.id] || this.state.variantQuantity[variant.id] == '') ?
                                                                                        this.state.variantQuantity[variant.id] : 1
                                                                                )
                                                                        }
                                                                        onChange={(e) => this.setState({ [e.target.name]: { [variant.id]: e.target.value ? parseInt(e.target.value) : '' } })}
                                                                        style={{ width: '90%' }}
                                                                    // id="standard-basic"
                                                                    // label="Кол."
                                                                    />

                                                                </Grid>

                                                                <Grid item xs={10}>
                                                                    <Grid container direction="row" alignItems="center"
                                                                        style={{
                                                                            background: '#e2e2e2',
                                                                            padding: 8,
                                                                            'border-radius': 3
                                                                        }}
                                                                        onClick={() => this.props.addProdToCart(id, variant.id, this.props.product, this.state.variantQuantity && this.state.variantQuantity == 1 ? 1 : this.state.variantQuantity[variant.id] ? this.state.variantQuantity[variant.id] : 1)}
                                                                    >

                                                                        {/* <Grid item xs={settings.clickable_product_variants && settings.clickable_product_variants.value == 1 ? 6 : 7} > */}
                                                                        <Grid item xs={6} >
                                                                            {/* {variant.size} */}
                                                                            {variant.translations[this.props.currLang] && variant.translations[this.props.currLang].size || variant.size}
                                                                        </Grid>
                                                                        <Grid item xs={6} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                                            {discount_percent ?
                                                                                <React.Fragment>
                                                                                    <span className="strikethrough"><span className={classes.oldPrice}>{variant.price}</span></span>&nbsp;
                                                                                    </React.Fragment>
                                                                                :
                                                                                null
                                                                            }
                                                                            {calculatedProductPrice.includes('.') ?
                                                                                <React.Fragment>
                                                                                    {calculatedProductPrice.split('.')[0]}<span data-class="product-variant-price-dot">.</span><span data-class="product-variant-price-cents">{calculatedProductPrice.split('.')[1]}</span>
                                                                                </React.Fragment>
                                                                                :
                                                                                calculatedProductPrice
                                                                            }

                                                                            {settings.default_currency_suffix && settings.default_currency_suffix.value ? ' ' + settings.default_currency_suffix.value : ' лв.'}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </ListItem>
                                                }
                                            </React.Fragment>
                                        )
                                    })}

                                    {(designVersion === 3 && designSubVersion === 3 && hideProductImage != 0) ? addButton : null}
                                    {((designVersion === 1 && designSubVersion === 1) || (designVersion === 2) || (designVersion === 3 && designSubVersion !== 3)) ? addButton : null}

                                </Grid>

                            </div>
                        </div>

                        {/* <ProductImage
                            image={prodThumbImgPath}
                            title={name}
                        /> */}

                        {photoOpened && (
                            <Lightbox
                                imageTitle={name}
                                imageCaption={description}
                                mainSrc={images[photoIndex]}
                                onCloseRequest={() => this.setState({ photoOpened: false })}
                            />
                        )}


                        {hideProductImage && !prodThumbImgPath ?
                            null
                            :
                            <CardMedia
                                className={(prodThumbImgPath || (Utils.getSettingsValue(settings, 'view_product_page') != 0)) ? classes.cover : classes.coverContain}
                                image={prodThumbImgPath ? prodThumbImgPath : DEFAULT_PRODUCT_IMAGE}
                                title={name}
                                style={{
                                    backgroundSize: (Utils.getSettingsValue(settings, 'isliquid_product_images_background_contain') == 1 && isLiquid == 1) ? 'contain' : (Utils.getSettingsValue(settings, 'product_images_background_contain') == 1 ? 'contain' : (prodThumbImgPath ? 'cover' : 'auto')),
                                    backgroundColor: (Utils.getSettingsValue(settings, 'isliquid_product_images_background_contain') == 1 && isLiquid == 1) ? 'contain' : (Utils.getSettingsValue(settings, 'product_images_background_contain') == 1 ? 'white' : 'initial')
                                }}
                                onClick={() => {
                                    Utils.getSettingsValue(settings, 'view_product_page') != 0 ?
                                        this.openProductPage(this.props.product)
                                        :
                                        (prodThumbImgPath ? this.setState({ photoOpened: true }) : void (0))
                                }}
                                data-class="each-product-image"
                            />

                            // onClick={() => Utils.getSettingsValue(settings, 'view_product_page') != 0 ? this.openProductPage(this.props.product) : void (0)}
                        }

                    </Card>

                    {designVersion === 1 && designSubVersion === 2 ? addButton : null}
                    {designVersion === 1 && designSubVersion === 1 && description.length > 0 ? descriptionText : null}

                </Grid>
            </Grow >
        );
    }
}

// TODO SET category name in state

// export default withStyles(styles)(ProductListItem);




const mapStateToProps = (state, ownProps) => {
    // const subcatId = ownProps.match.params.subcat_id;
    return {
        // subcatId: subcatId,
        // subcategory: state.products.subcat_products && state.products.subcat_products ? state.products.subcat_products.filter(sub => sub && sub[0] && sub[0].id && sub[0].id == subcatId)[0] : [],
        // subcategory: state.products.subcat_products || null,
        // isLoading: state.products.isLoading || false,
        products: state.products || [],
        // categoriesLoaded: state.categories.categories_loaded || 0,
        settings: state.settings.settings || [],
        translations: state.lang,
        // currLang: state.lang.currLang

    };
};

export default withStyles(styles)(connect(mapStateToProps, { setProductPageId, showHeaderCategory })(ProductListItem));