import React, { Component } from 'react';
import { TextField, Button } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    textField: {
        width: '100%',
        '& label.Mui-focused': {
            color: 'rgba(254, 105, 2, 0.7)',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'rgb(254, 105, 2)',
        },
    },
}));

const LoginForm = (props) => {
    const classes = useStyles();

    return (
        <form onSubmit={props.onSubmit}>
            <div style={{ marginBottom: 10 }}></div>

            <TextField className={classes.textField} onChange={props.handleChange} name="email" label="Потребителско име" required style={{ width: '100%' }} />

            <br /><br />

            <TextField className={classes.textField} onChange={props.handleChange} name="password" label="Парола" required type="password" style={{ width: '100%' }} />

            <br /><br />

            <Button variant="contained" style={{ color: 'rgb(203,65,2)', width: '100%', boxShadow: 'none' }} onClick={props.onSubmit}>
                Вход
            </Button>

        </form>
    );
}

export default LoginForm;



// import React, { Component } from 'react';

// const LoginForm = (props) => {

//     return (
//         <form onSubmit={props.onSubmit}>
//             <label>
//                 <div className="label-text">Потребителско име</div>
//                 <input onChange={props.handleChange} name="email" type="text" required />
//             </label>
//             <label>
//                 <div className="label-text">Парола</div>
//                 <input onChange={props.handleChange} name="password"  type="password" required />
//             </label>
//             <br />
//             <button>Вход</button><br />
//         </form>
//     );
// }

// export default LoginForm;
