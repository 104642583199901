export const enTranslations = {
    navbar: {
        restaurant_location: "Navigate to the restaurant",
        make_reservations: "Book a table",
        search_product: "Search product",
        call_waiter: "Call waiter",
        change_language: "Change language",
        feedback: "Feedback",
        about_tabl: 'About TABL',
        orders: "Orders",
        profile: "My profile",
        logout: "Logout",
    },

    pages: {
        products: {
            choose_variant: "Choose variant",
            alergens: "Allergens",
            outOfStock: "Out of stock",
            variant_not_choosed: "Product variant is not selected!",
            additional_options: "Additional options",
            addable_options: "Add", // or: Add ingredients
            removable_options: "Remove",
            comment: "Product comment", // Or:  Add extra chili please...
            related_products: "Related products",
            add: "Add", // in context 'add to cart'
            add_to_cart: "Add to cart", // in context 'add to cart'
            choose_size: "Choose size",
        },
        change_language: {
            title: "Please choose a language",
        },
        call_waiter: {
            clean_table: "Please clean the table",
            new_cutlery: "Please change the cutlery",
            more_napkins: "More napkins please",
            change_ashtray: "Please change the ashtray",
            bring_ice: "Please bring ice",
            other: "Other",
        },
        feedback: {
            title: "We will be happy if you rate us",
            customer_reviews: "Customer reviews",
            no_reviews_yet: "Be the first one to rate this service."
        },
        about_tabl: {
            title: "This service reaches you thanks to ",
            sub_title: "If you need more information or recommendations, please contact us.",
            about_link: "https://tabl.bg/about"
        },
        cart_delivery: {
            // you_can_order_between: "Може да поръчате от {from} до {to}",
            call_waiter_to_order: "Please call the waiter to order",
            you_can_order_between: "Ordering hours: {from} - {to} and {from_two} - {to_two}",
            restaurant_closed_label_tommorow: "We are closed, try again tommorow at {value}",
            restaurant_closed_label_today: "We are closed, try again at {value}",
            delivery_price: "Delivery price",
            delivery_price_is_not_included: "+ delivery price",
            cart: "Cart",
            no_cart_items: "Your cart is empty.",
            orders: "Orders",
            order: "Order",
            min_delivery_price_text: 'For orders over {value} delivery is free',
            no_order_items: "You don't have active orders.",
            total: "Price",
            total_bill: "Total",
            status_accepted: "Accepted",
            status_preparing: "Preparing",
            status_finished: "Finished",
            make_order: "Order",
            want_the_bill: "Ask for BILL",
            finish_bill: "Finish the BILL",
            pay_in_cash: "Pay in cash",
            pay_with_card: "Pay with card",
            make_delivery_order: "Order with delivery",
            choose_order_type: "Choose order type",
            choose_payment_type: "Choose payment type",
            payment_cash: "In cash",
            payment_card: "With a card",
            delivery_price_included_in_total_price: "Delivery price included: {delivery_price_num} /For orders up to {min_price_free_delivery_num}/",
            order_type_restaurant: "In restaurant",
            scan_barcode: "Scan the barcode",
            order_type_delivery: "Door Delivery",
            order_type_preorder: "Address Pick up",
            first_and_last_name: "Name",
            phone: "Phone",
            order_address: "Address, street, town",
            delivery_address: "Delivery address",
            delivery_time: "Date and Time for pickup",
            email: "Email",
            remember_me: "Remember me",
            promo_code: "Promocode",
            promo_code_placeholder: "Promocode...",
            apply: "Apply",
            cancel: "Cancel",
            bill_already_wanted: "/bill already wanted/",
            clear: "Clear",
            close: "Close",
            fast_order: "Fast order",
            save: "Save",
        },
        search: {
            searchProduct: "Search a product",
            noSearchResults: "No products found",
            noSearchDescription: "Please change the criteria, to found the right product for you!",
        },
        choose_place: {
            title: "Pleace choose a place"
        }
    },

    common: {
        no_internet: "No internet connection.",
        count: 'pc.',
        box: "box",
        like_us: "Like us",
        follow_us: "Follow us",
        youtube: "YouTube",
        tipadvisor: "Tripadvisor",
        about: 'About',
        about_us: 'About us',
        discount: 'Discount',
        delivery: 'Delivery',
        book: 'Book',
        language: 'Language',
        navigate_me: "Navigete",
        navigation: 'Directions',
        search: 'Search',
        call_us: 'Call us',
        product: "product",
        products: "products",
        user: "User",
        comment: "comment",
        contacts: "Contacts",
        working_hours: "Working hours",
        payment_methods: "Payment methods",
        gallery: "Gallery",
        change_table: "Change table",
        date: {
            today: "Today",
            tommorow: "Tommorow",
        },

        action: {
            send: "Send",
            skip: "Skip",
            back: "Back",
        },

        label: {
            describe_here: "Type here",
        }

    },

    messages: {
        notifications: {
            waiter_notified_successfully: 'The waiter was notified successfully',
            order_sent_success: "Your order is being processed. Thank you!",
            feedback_thanks: "Thank you! We appreciate it.",
            bill_wanted_success: 'We have received your payment request.',
            user_need_success: 'Your message was sent to the waiter.',
            create_order_first: "You first need to create an order.",
            bill_already_wanted: "Your already asked for the bill",
        },
        errors: {
            invalid_name: 'Invalid name',
            invalid_address: 'Invalid address',
            invalid_number_in_distributor_system: 'Invalid number in distributor system',
            invalid_eik: 'Invalid bulstat',
            invalid_delivery_address: 'Invalid delivery address',
            invalid_region: 'Invalid region',
            invalid_fields: "Please fill in all fields",
            order_sent_fail: "You have scanned invalid QR code.",
            bill_wanted_fail: "Something went wrong during your payment request.",
            user_need_fail: 'Your message was not sent to the waiter.',
        }
    },

}