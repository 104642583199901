import { GET_SHOPS, NEW_SHOP_MODAL_OPENED, ADD_SHOP, SELECT_LAST_ADDED_SHOP, SHOP_INFO_MODAL_OPENED, SET_CURR_SHOP_ID } from './types';
import axios from 'axios';
import { addErrorMessages, addSuccessMessage } from './../../messages/messages.actions';
import { API_URL } from '../../constants';
import Auth from './../../utils/AuthC';

export const getShopsForUser = (user_id = 0) => dispatch => {
    let query = '';

    if(Auth.getUserFromToken() && Auth.getUserFromToken().role == "SUPER_ADMIN") {

    } else {
        if (user_id != 0) {
            query = "?user_id=" + user_id;
        }
    }

    axios.get(API_URL + 'shops' + query)
        .then(result => {
            dispatch({
                type: GET_SHOPS,
                payload: result.data
            })
        })
        .catch(err => {
        })
}

export const addShop = (data) => dispatch => {
    axios.post(API_URL + 'shops', data)
        .then(result => {
            dispatch(getShopsForUser());
            dispatch(addSuccessMessage(["Успешно добавен магазин"]));
            // console.log("RESULT ", result)
            dispatch(setCurrChosenShopId(result.data.id));
            dispatch(selectLastAddedShop(true));
            // dispatch({
            //     type: ADD_SHOP,
            //     payload: result.data
            // })
        })
        .catch(err => {
            dispatch(addErrorMessages(["Неуспех при добавяне на магазина"]));
        })
}

export const editShop = (data) => dispatch => {
    axios.post(API_URL + 'shops', data)
        .then(result => {
            dispatch(getShopsForUser());
            dispatch(addSuccessMessage(["Успешно добавени данни"]));
            // dispatch(selectLastAddedShop(true));
            // dispatch({
            //     type: ADD_SHOP,
            //     payload: result.data
            // })
        })
        .catch(err => {
            dispatch(addErrorMessages(["Неуспех при добавяне на данни към магазина"]));
        })
}

export const selectLastAddedShop = (selected = false) => dispatch => {
    dispatch({
        type: SELECT_LAST_ADDED_SHOP,
        payload: selected,
    })
}

export const setNewShopModalOpenedState = (state) => dispatch => {
    dispatch({
        type: NEW_SHOP_MODAL_OPENED,
        payload: state,
    })
}

export const setShopInfoModalOpened = (state) => dispatch => {
    dispatch({
        type: SHOP_INFO_MODAL_OPENED,
        payload: state,
    })
}

export const setCurrChosenShopId = id => dispatch => {
    dispatch({
        type: SET_CURR_SHOP_ID,
        payload: id,
    })
}
