import axios from 'axios';
import { VERIFY_TOKEN, VERIFICATION_SUCCESS, VERIFICATION_FAIL, OPEN_SCAN_MODAL, CLOSE_SCAN_MODAL, SET_CURR_OPENED_MODAL, SET_DELIVERY_MODAL_STATE } from './types';
import { API_URL, DEFAULT_PRODUCT_IMAGE } from '../../constants'
import Utils from '../../utils/Utils';

export const verifyToken = (token) => dispatch => {
    // dispatch({ type: LOADING_START });

    return axios
        .post(API_URL + 'tables/verify_token', {"token" : token});

        // .then(res => {
        //     Utils.setUserToken(token);
        //     history.push("/");
        // })
        // .catch(err => {
        //     Utils.removeUserToken();
        //     history.push("/");            
        // })
}

export const verifyTokenAndSession = (token) => dispatch => {
    // dispatch({ type: LOADING_START });

    if(Utils.getUserToken()) {
        return axios
        .post(API_URL + 'tables/verify_session', {"token" : token, "session": Utils.getUserToken()});   // in getUserToken() is saved the generated session from scannig the qr code
    } else {
        return axios
        .post(API_URL + 'tables/verify_token', {"token" : token});
    }


        // .then(res => {
        //     Utils.setUserToken(token);
        //     history.push("/");
        // })
        // .catch(err => {
        //     Utils.removeUserToken();
        //     history.push("/");            
        // })
}

export const setCurrOpenedModal = (currModal) => dispatch => {
    dispatch({
        type: SET_CURR_OPENED_MODAL,
        payload: currModal
    })
} 

export const openScanModal = () => dispatch => {
    dispatch({
        type: OPEN_SCAN_MODAL,
        payload: 1
    })
}

export const closeScanModal = () => dispatch => {
    dispatch({
        type: CLOSE_SCAN_MODAL,
        payload: 0
    })
}

export const setDeliveryModalState = (state) => dispatch => {
    dispatch({
        type: SET_DELIVERY_MODAL_STATE,
        payload: state
    })
}

export const getTheOnlyOrderType = () => dispatch => {
    window.localStorage.getItem("order_type");
}

export const setTheOnlyOrderType = (orderType) => dispatch => {
    window.localStorage.setItem("order_type", orderType);
}