import axios from 'axios';
import { GET_PLACES } from './types';
import { LOADING_START, LOADING_END, PLACE_IMAGE_UPLOADED } from '../../products/actions/types';
import { SET_CHOSEN_PLACE } from './../actions/types';
import { API_URL } from '../../constants';
import { clearCartLocal } from './../../products/actions/products.action';

import Utils from './../../utils/Utils';

export const getPlaces = () => dispatch => {
    dispatch({ type: LOADING_START });

    axios
        .get(API_URL + 'places?t=' + (new Date().getTime()))
        .then(res => {
            dispatch({
                type: GET_PLACES,
                payload: res.data
            });
            dispatch({ type: LOADING_END });
        }).catch(error => {
            throw (error);
        });
}



export const addPlaceImage = (file, placeId) => dispatch => {

    if (file == null) {
        dispatch({
            type: PLACE_IMAGE_UPLOADED,
            payload: false
        });

        // history.push();
        return;
    }

    axios
        .post(API_URL + `places/gallery`, file)
        .then(function (res) {
            dispatch({
                type: PLACE_IMAGE_UPLOADED,
                payload: true
            });

            dispatch(getPlaces());
            // if (res.error) {
            //     //TODO image not uploaded Toastr
            // }
            // else {
            //     // history.push(ADMIN_URL + 'products_admin');
            // }
            // // Error msg
            // history.push(API_URL + 'products');
        })
        .catch(e =>
            // history.push(API_URL + 'products')
            dispatch({
                type: PLACE_IMAGE_UPLOADED,
                payload: false
            })
        );


}

export const setChosenPlace = (place_id, should_clear_local_cart = false) => dispatch => {

    if (getChosenPlace() != place_id && should_clear_local_cart) {
        dispatch(clearCartLocal());
    }

    window.localStorage.setItem('place', place_id);

    dispatch({
        type: SET_CHOSEN_PLACE,
        payload: place_id
    });
}

export const getChosenPlace = () => {
    return window.localStorage.getItem('place');
}