import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import RoomIcon from '@material-ui/icons/Room';
import StoreIcon from '@material-ui/icons/Store';
import ImageIcon from '@material-ui/icons/Image';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import CancelIcon from '@material-ui/icons/Cancel';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import DirectionsIcon from '@material-ui/icons/Directions';
import IconButton from '@material-ui/core/IconButton';
import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';
import PlaceIcon from '@material-ui/icons/Place';
import CameraIcon from '@material-ui/icons/Camera';
import CropFreeIcon from '@material-ui/icons/CropFree';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import MenuItem from '@material-ui/core/MenuItem';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import HomeWorkIcon from '@material-ui/icons/HomeWork';

import { verifyToken, verifyTokenAndSession, closeScanModal, getTheOnlyOrderType, setCurrOpenedModal, setDeliveryModalState } from './../../qr-scan/actions/scan.actions';
import { setNewShopModalOpenedState, selectLastAddedShop, setShopInfoModalOpened, setCurrChosenShopId } from './../../shops/actions/shops.actions';
import { setChosenPlace } from './../../places/actions/places.actions';
import OpenApp from "react-open-app";

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import InfoIcon from '@material-ui/icons/Info';
import StarBorderIcon from '@material-ui/icons/StarBorder';


import QrReader from "react-qr-reader";
import Utils from "../../utils/Utils";
import history from './../../utils/history';

import { addErrorMessages } from './../../messages/messages.actions';

import { styles } from './styles/deliveryModal.styles';

class DeliveryModal extends Component {
    constructor(props) {
        super(props);
        const order_credentials = window.localStorage.getItem('order_credentials');
        this.state = {
            name: order_credentials ? JSON.parse(order_credentials).name : '',
            phone: order_credentials ? JSON.parse(order_credentials).phone : '',
            address: order_credentials ? JSON.parse(order_credentials).address : '',
            email: order_credentials ? JSON.parse(order_credentials).email : '',
            promoCode: '',
            rememberMe: order_credentials ? true : false,
            inputPromoCodeVisible: false,
            errors: '',
            modalType: 0,

            // Pickup
            pickupDate: '',
            pickupTime: '',

            // Delivery
            deliveryDate: '',
            deliveryTime: '',

            scanDelay: 200,
            scannedTableToken: '',
            scanLegacyMode: false,

            region_id: 0,
            currRegionOption: '',

            shop_id: 0,
            defaultShop: '',
            currentShops: [],

            distributor_id: 0,
            defaultDistributor: '',
            currentDistributor: [],

            userImages: [],
            userComments: [],
            userImagesCount: 0,
            imgAndCommentInputsList: [],
            orderComment: '',
            realOrderComment: '',

            selectedModalOrderType: 0,
        }
    }

    componentDidMount() {
        this.setState({ errors: '' });
    }

    componentWillReceiveProps(nextProps) {
        this.autoSetClientAddressOnPreOrder(nextProps);

        /*d*/
        // if (this.state.selectedModalOrderType != 2 && nextProps.distributors && nextProps.distributors[0]) {
        //     this.setState({
        //         distributor_id: nextProps.distributors[0].id
        //     })
        // }
        // console.log('WILLRECEIVE- this.props.selectLastAddedShopState', this.props.selectLastAddedShopState)
        // if (this.props.selectLastAddedShopState) {
        //     this.setState({ region_id: 0 });
        // }


        if (nextProps.selectLastAddedShopState == 1 && nextProps.shops != this.props.shops) {
            let shops = Object.values(nextProps.shops)

            // console.log('shops', shops);
            // console.log('this.state.region_id', this.state.region_id);

            let stateUpadate = {};

            if (shops[shops.length - 1].regionId != this.state.region_id) {
                stateUpadate = {
                    region_id: 0,
                    shop_id: shops[shops.length - 1].id,
                    defaultShop: { id: shops[shops.length - 1].id, name: shops[shops.length - 1].name }
                }
            } else {
                stateUpadate = {
                    shop_id: shops[shops.length - 1].id,
                    defaultShop: { id: shops[shops.length - 1].id, name: shops[shops.length - 1].name }
                };
            }

            this.setState(stateUpadate);

            this.props.selectLastAddedShop(false);
        } else {
            if (nextProps.shops && Object.keys(nextProps.shops).length > 0
                && nextProps.regions && Object.keys(nextProps.regions).length > 0
                && this.props.currShopId == nextProps.currShopId   // check this cuz it was getting here on shop change and was always setting the currShop = first one  
                && this.props.selectLastAddedShop != nextProps.selectLastAddedShop
            ) {
                let currRegion = Object.values(nextProps.regions)[0];
                let currRegionOption = { id: currRegion.id, name: currRegion.name };
                let currShops = Object.values(nextProps.shops).filter(s => s.regionId == currRegion.id);

                let currShopOption = currShops.length > 0 ? { id: currShops[0].id, name: currShops[0].name } : {};

                // console.log("VLIZA ", currRegion, currShops);
                this.setState({
                    region_id: currRegion.id,
                    currRegionOption: currRegionOption,
                    shop_id: currShops.length > 0 ? currShops[0].id : 0,
                    defaultShop: currShopOption,
                    currentShops: currShops,
                })

                if (currShopOption.id && nextProps.currShopId == 0) {
                    // console.log("SET CURRR SHIPOPPPPP", currShops)
                    this.props.setCurrChosenShopId(currShopOption.id);
                }

            }
        }


    }

    componentDidUpdate() {
        this.autoSetClientAddressOnPreOrder(this.props);

        // this.handleOrderAction();
    }

    onChangeDistributorAutocomplete = (event, newValue) => {
        // console.log('newValue', newValue)
        // if (newValue) {
        this.setState({
            distributor_id: newValue ? newValue.id : 0,
        })
        // }
    }

    onChangeShopAutocomplete = (event, newValue) => {
        if (newValue) {
            this.setState({
                shop_id: newValue.id,
                defaultShop: newValue
            })

            this.props.setCurrChosenShopId(newValue.id);

        } else {
            this.setState({ shop_id: 0 });
        }
    }

    onChangeRegionAutocomplete = (event, newValue) => {
        if (newValue) {

            let currShops = Object.values(this.props.shops).filter(s => s.regionId == newValue.id);
            let currDistributor = Object.values(this.props.distributors).filter(d => d.regions.includes(newValue.id));

            this.setState({
                region_id: newValue.id,
                defaultRegion: newValue,

                shop_id: currShops[0] ? currShops[0].id : 0,
                currentShops: currShops,
                defaultShop: currShops[0] ? currShops[0] : [],

                distributor_id: currDistributor[0] ? currDistributor[0].id : 0,
                currentDistributor: currDistributor,
                defaultDistributor: currDistributor[0] ? currDistributor[0] : [],

            })

            if (currShops[0]) {
                this.props.setCurrChosenShopId(currShops[0].id)
            }
        } else {

            this.setState({ region_id: 0 });

        }
    }

    handleOrderAction = (orderType) => {
        const { settings, translations } = this.props;
        const disabledOrdering = Utils.disabledOrdering(settings);

        if (disabledOrdering) {
            this.props.onError(Utils.getDisabledOrderingMessage(translations, settings))
            return;
        }

        if (orderType == 'spot') {
            if (Utils.getUserToken()) {
                this.props.onSubmit(this.state, 1);
            } else {
                if (window.localStorage.getItem("app")) {
                    window.location.pathname = "/close-browser-app";
                    return;
                }
            }
        }
        this.props.setCurrOpenedModal(orderType);
    }

    autoSetClientAddressOnPreOrder = (props) => {
        const { open, settings } = props
        // Set place address if preorder
        if (open == true && ((settings.order_type && settings.order_type.value == 3) || this.state.modalType == 3) && (settings.place_address && settings.place_address.value) && ((!this.state.address) || this.state.address && this.state.address != settings.place_address.value)) {
            this.setState({ address: settings.place_address.value });
        }
    }

    isFormValid = () => {
        const { settings } = this.props;
        let errors = [];
        let isFormValid = true;

        if (this.state.region_id == 0) {
            isFormValid = false;
            errors.push("Моля изберете Регион");
        }

        if (this.state.distributor_id == 0 && this.state.selectedModalOrderType != 2) {
            isFormValid = false;
            errors.push("Моля изберете дистрибутор");
        }
        if (this.state.shop_id == 0) {
            isFormValid = false;
            errors.push("Моля изберете магазин");
        }

        if (!isFormValid) {
            this.props.addErrorMessages(errors);
            // this.setState({ errors: errors })
        }

        // isFormValid = true;
        return isFormValid;
    }



    handleFormSubmit = () => {
        // console.log(this.state);return;
        let { translations } = this.props;
        // let currentOrderType = 'default';

        if (this.isFormValid()) {
            // const files = Array.from(document.querySelector('#multi').files)
            let formData = new FormData()
            let hasData = false;
            this.state.imgAndCommentInputsList.forEach((imgAndComment, i) => {
                hasData = true;
                formData.append("image_" + i, imgAndComment.file)
                formData.append("comment_" + i, imgAndComment.comment);
            })

            this.props.onSubmit(this.state, formData, hasData);
            this.setState({
                userImages: [],
                userComments: [],
                distributor_id: 0,
                shop_id: 0,
                userImages: [],
                userComments: [],
                userImagesCount: 0,
                imgAndCommentInputsList: [],
                orderComment: '',
                realOrderComment: '',
                selectedModalOrderType: 0,
                defaultShop: '',
                defaultDistributor: '',
            })
        } else {
            this.setState({ errors: (translations.messages && translations.messages.errors ? translations.messages.errors.invalid_fields : '') });
        }

    }

    openAddNewShopModal = () => {
        this.props.setNewShopModalOpenedState(true);
    }

    // reads data from input type file and puts it in img src tag
    readURL = (input, imgSelector) => {
        const self = this;
        if (input.target.files) {
            let userImagesCount = this.state.userImagesCount;
            let userImages = this.state.userImages;

            for (let i = 0; i < (input.target.files).length; i++) {
                var reader = new FileReader();
                let file = input.target.files[i];
                reader.onload = function (e) {

                    let imgCommentObject = { imgSrc: e.target.result, file: file, comment: "" };
                    let imgAndCommentInputsList = self.state.imgAndCommentInputsList;
                    imgAndCommentInputsList.push(imgCommentObject);
                    self.setState({
                        imgAndCommentInputsList: imgAndCommentInputsList
                    });

                };

                reader.readAsDataURL(input.target.files[i]);
            }

            this.setState({ 'userImages': userImages })
        }
    }

    // handle input change
    handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.imgAndCommentInputsList];
        list[index][name] = value;
        this.setState({
            imgAndCommentInputsList: list
        })
    };

    handleRemoveClick = index => {
        const list = [...this.state.imgAndCommentInputsList];
        list.splice(index, 1);
        this.setState({
            imgAndCommentInputsList: list
        })
    };

    render() {
        const { classes, fullWidth = false, maxWidth = 'xs', open, onClose, onSubmit, onError, isLoadingOrder, orderTotal, settings, modalData, translations } = this.props;
        const userToken = Utils.getUserToken();
        const default_currency_suffix = (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.')

        let distributorsOptions = Object.values(this.state.currentDistributor).map((d) => {
            return { id: d.id, name: d.name }
        });
        let defaultDistributor = '';
        defaultDistributor = this.state.defaultDistributor

        let shopsOptions = Object.values(this.state.currentShops).map((d) => {
            return { id: d.id, name: d.name, address: d.address }
        });

        let defaultShop = '';
        defaultShop = this.props.selectLastAddedShopState ? shopsOptions[shopsOptions.length - 1] : this.state.defaultShop


        let regionsOptions = Object.values(this.props.regions).map((d) => {
            return { id: d.id, name: d.name }
        });

        let defaultRegion = '';
        defaultRegion = this.state.defaultRegion

        return (
            <React.Fragment>
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={onClose}
                    aria-labelledby="max-width-dialog-title"
                    TransitionComponent={Grow}
                    className={classes.modal}
                    data-class="dialog-container"
                >
                    <DialogTitle id="max-width-dialog-title" style={{ textAlign: 'center', backgroundColor: "rgb(203,65,2)", color: 'white', boxShadow: '0 1px 5px 0px black' }} data-class="dialog-title-container">

                        {this.state.selectedModalOrderType != 0 ?
                            <IconButton aria-label="add to shopping cart" style={{ color: 'white' }} onClick={() => this.setState({ selectedModalOrderType: 0 })}>
                                <ArrowBackIosIcon />
                            </IconButton>
                            :
                            null
                        }

                        {this.state.selectedModalOrderType == 0 ?
                            <span>{translations.pages.cart_delivery.choose_order_type}</span>
                            :
                            (this.state.selectedModalOrderType == 1 ? 'Към Дистрибутор' : 'Към SI 7')
                        }
                    </DialogTitle>
                    <DialogContent style={this.props.currOpenedModal == 'multiple' || this.props.currOpenedModal == 'spot' ? { padding: 0 } : {}}>

                        <Backdrop open={isLoadingOrder} className={classes.backdrop}>
                            <CircularProgress className="is-loading" />
                        </Backdrop>


                        {this.state.selectedModalOrderType == 0 ?
                            <React.Fragment>

                                <div className={classes.isMultipleOptionsModalContainer} >
                                    <Button id="choose-order-type-modal-inrestaurant-button" size="large" className={clsx(classes.modalTypesBtns, classes.modalTypesBtnsBorderBottom)}
                                        // onClick={() => disabledOrdering ? onError(Utils.getDisabledOrderingMessage(translations, settings)) : (userToken.length > 0 ? onSubmit(this.state, 1) : this.setState({ modalType: 1 }))}
                                        onClick={() => this.setState({ selectedModalOrderType: 1 })}
                                    > {/* Go to scan page if not scanned, else make the order */}
                                        <div>
                                            <AirportShuttleIcon className={classes.modalTypesIcons} />
                                        </div>
                                        <div>
                                            {/* {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.order_type_restaurant : ''} */}
                                        Към Дистрибутор
                                    </div>
                                    </Button>


                                    <Button id="choose-order-type-modal-delivery-button" size="large" className={clsx(classes.modalTypesBtns, classes.modalTypesBtnsBorderBottom)}
                                        // onClick={() => disabledOrdering ? onError(Utils.getDisabledOrderingMessage(translations, settings)) : (this.setState({ modalType: 2 }))} style={parseFloat(orderDeliveryPrice) == 0 ? { borderBottom: '1px solid #ff5200' } : {}}
                                        onClick={() => this.setState({ selectedModalOrderType: 2, distributor_id: 0 })}
                                    >
                                        <div>
                                            <HomeWorkIcon className={classes.modalTypesIcons} />
                                        </div>
                                        <div>
                                            <div className={classes.typeText}>
                                                {/* {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.order_type_delivery : ''} */}
                                            Към SI 7
                                        </div>
                                        </div>
                                    </Button>

                                </div>

                            </React.Fragment>
                            :
                            null
                        }


                        {/* KUM DISTRIBUTOR */}
                        <div style={{ display: this.state.selectedModalOrderType == 0 ? 'none' : 'block' }} >
                            <div style={{ width: '90%', margin: '0 auto' }}>

                                <Autocomplete
                                    // id="free-solo-demo"
                                    freeSolo
                                    name={"region_id"}
                                    // defaultValue={this.props.userRegion}
                                    onChange={this.onChangeRegionAutocomplete}
                                    getOptionLabel={(option) => option && option.name && typeof option.name === 'string' ? option.name : '' }
                                    defaultValue={this.state.currRegionOption}
                                    value={this.state.region_id ? defaultRegion : 0}
                                    // options={[this.props.userRegion]}
                                    options={regionsOptions}
                                    filterOptions={createFilterOptions({
                                        limit: 100,
                                    })}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option.name}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Регион" margin="normal" value={""} />
                                    )}
                                // disabled
                                />

                                {this.state.selectedModalOrderType == 1 ?

                                    <Autocomplete

                                        // id="free-solo-demo"
                                        freeSolo
                                        onChange={this.onChangeDistributorAutocomplete}
                                        getOptionLabel={(option) => option && option.name && typeof option.name === 'string' ? option.name : '' }
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        defaultValue={distributorsOptions[0]}
                                        value={this.state.distributor_id ? distributorsOptions.filter(d=> d.id == this.state.distributor_id)[0] : 0}
                                        filterOptions={createFilterOptions({
                                            limit: 100,
                                        })}
                                        options={distributorsOptions}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Дистрибутор" margin="normal" />
                                        )}
                                    />
                                    :
                                    <TextField defaultValue="SI 7" label="Дистрибутор" margin="normal" disabled style={{ width: '100%' }} />
                                }

                                <Autocomplete
                                    // id="free-solo-demo"
                                    freeSolo
                                    name={"shop_id"}
                                    onChange={this.onChangeShopAutocomplete}
                                    getOptionLabel={(option) => option && option.name && typeof option.name === 'string' ? option.name : '' }
                                    value={this.state.shop_id ? defaultShop : this.state.shop_id}
                                    filterOptions={createFilterOptions({
                                        limit: 100,
                                    })}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <span>{option.name} {option.address ? <span style={{fontSize: 12}}> - {option.address}</span> : ""}</span>
                                        </React.Fragment>
                                    )}
                                    options={shopsOptions}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Обект" margin="normal" />
                                    )}
                                />
                                <br />

                                <Button variant="contained" style={{ color: 'rgb(254, 105, 2)', width: '100%', boxShadow: 'none' }}
                                    onClick={() => this.props.setShopInfoModalOpened(true)} startIcon={<StoreIcon />}>
                                    Добави данни за обект
                                </Button>
                                <br />
                                <br />
                                <Button variant="contained" style={{ color: 'rgb(254, 105, 2)', width: '100%', boxShadow: 'none' }} onClick={this.openAddNewShopModal} startIcon={<StoreIcon />}>
                                    Добави нов обект
                                </Button>

                                <br /><br />

                                <TextField
                                    // className={classes.textField}
                                    // onChange={props.handleChange}
                                    name="email" label="Коментар към поръчка" required style={{ width: '100%' }} onChange={e => this.setState({ realOrderComment: e.target.value })} />

                                <br />
                                <br />

                                <input
                                    style={{ display: 'none' }}
                                    onChange={(e) => this.readURL(e, "#asd")}
                                    accept="image/*"
                                    className={classes.input}
                                    id="order-images"
                                    multiple
                                    type="file"

                                />

                                <label htmlFor="order-images">
                                    <Button variant="contained" component="span" startIcon={<ImageIcon />} style={{ color: 'rgb(254, 105, 2)', width: '100%', boxShadow: 'none' }} >
                                        Качи снимка
                                      </Button>

                                    <div id="order-uploaded-image-containter" className={classes.orderUploadedImageContainer}>

                                        {this.state.imgAndCommentInputsList.map((x, i) => {
                                            return (
                                                <div className="" style={{ position: 'relative' }}>
                                                    <img src={x.imgSrc} style={{ maxWidth: '65%', display: 'block', margin: '0 auto' }} />
                                                    <input
                                                        name="comment"
                                                        placeholder="Напиши коментар"
                                                        value={x.comment}
                                                        onChange={e => this.handleInputChange(e, i)}
                                                    />
                                                    {/* <div className="btn-box"> */}
                                                    {/* {this.state.imgAndCommentInputsList.length !== 1 &&  */}
                                                    <IconButton aria-label="delete"
                                                        onClick={() => this.handleRemoveClick(i)}
                                                        style={{ position: 'absolute', top: 'calc(50% - 54px)', left: 24, color: '#f30000' }}
                                                    // className={classes.margin}
                                                    >
                                                        <CancelIcon fontSize="large" />
                                                    </IconButton>

                                                    {/* <button
                                                            className="mr10"
                                                            
                                                        >Премахни</button> */}
                                                    {/* {inputList.length - 1 === i && <button onClick={handleAddClick}>Add</button>} */}
                                                    {/* </div> */}
                                                </div>
                                            );
                                        })}




                                    </div>
                                </label>

                            </div>
                            <br /><br />

                        </div>


                    </DialogContent>

                    {this.state.selectedModalOrderType != 0 ?
                        <Button
                            id="selected-order-type-modal-submit-button"
                            onClick={() => this.handleFormSubmit()}
                            style={{
                                fontWeight: 'bold',
                                background: 'rgb(254, 105, 2)',
                                borderRadius: 0,
                                color: 'white',
                                padding: 10
                            }}
                        >
                            {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.make_order : ''}
                            {/* {(this.props.currOpenedModal == 'delivery' && parseFloat(orderDeliveryPrice) > 0) ? (' /' + Utils.normalizePrice(parseFloat(orderDeliveryPrice) + parseFloat(orderTotal)) + '' + default_currency_suffix + '/') : null} */}
                        </Button>
                        :
                        null
                    }

                </Dialog >
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let chosenPlaceOrderTypes = [];
    let chosenPlaceId = state.places.chosen_place;
    let chosenPlace = state.places.places.filter(p => p.id == chosenPlaceId);
    if (chosenPlace.length == 0) {
        chosenPlace = null;
    } else {
        chosenPlace = chosenPlace[0];
        chosenPlaceOrderTypes = chosenPlace.ordertypes;
    }

    let sortedDistributors = state.distributors.distributors || []
    sortedDistributors = Object.values(sortedDistributors).sort((a, b) => (a.priority > b.priority) ? 1 : -1)

    return {
        isLoadingOrder: state.products.isLoadingOrder || false,
        settings: state.settings.settings || [],
        scan_modal_open: state.scan.scan_modal_open || 0,
        currOpenedModal: state.scan.curr_opened_modal || 'multiple',
        places: state.places.places || [],
        chosenPlace: chosenPlace,
        chosenPlaceOrderTypes: chosenPlaceOrderTypes,
        distributors: sortedDistributors,
        shops: state.shops.shops || [],
        userRegion: state.users.user && state.users.user.region ? state.users.user.region.name : "",
        regions: state.regions.regions || [],
        selectLastAddedShopState: state.shops.selectLastAddedShop || false,
        currShopId: state.shops.currShopId || 0
    };
};

export default withStyles(styles)(connect(mapStateToProps, {
    verifyToken, verifyTokenAndSession, closeScanModal,
    setChosenPlace, getTheOnlyOrderType, setCurrOpenedModal, setDeliveryModalState, addErrorMessages,
    setNewShopModalOpenedState, selectLastAddedShop, setShopInfoModalOpened, setCurrChosenShopId
})(DeliveryModal));