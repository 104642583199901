import { SET_HEADER_CENTER_DATA } from '../actions/types';

const initialState = {
    header_center_data: "logo"
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_HEADER_CENTER_DATA: 
            return {
                ...state,
                header_center_data: action.payload
            }
        default:
            return state;
    }
}

