import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Promotions from '../../common/containers/Promotions'
import Grid from '@material-ui/core/Grid';

import ProductVariantsModal from '../../common/modals/ProductVariant'
import ProductListItem from "../../products/components/ProductListItem";
import { getProductsBySubcat, addToCart, addProductTooltip, changeSelectedCartTab, setOrderComment } from '../../products/actions/products.action';
import { showHeaderCategory, setChosenCat, setCategoriesLoadedFlag, getCategories, } from '../actions/categories.action';
import { setMainBackground } from '../../settings/actions/settings.action';
import Utils from '../../utils/Utils';
import ImageButton from './ImageButton';
import RowButton from './RowButton';

const styles = theme => ({
    root: {
        marginTop: 135,
        // backgroundColor: 'black', //down from settings
        width: '100%',

        // Categories underline indicator
        '& .MuiTabs-indicator': {
            backgroundColor: '#FE6902'
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            color: 'inherit',
            fontWeight: 'bold'
        },
        '& > .MuiPaper-root': {
            zIndex: 1099,
            position: 'absolute',
            paddingTop: 55,
            top: 0,
        },
    },
    categoryName: {
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        'flex-grow': 1,
        'max-width': 'none',
        lineHeight: 'initial'
    },
    categoryIcon: {
        height: 35,
    },
    swipeableViews: {
        height: 'calc(100vh - 135px)',
        WebkitOverflowScrolling: 'touch',
    },
    tabsContainer: {
        '& .MuiTabs-flexContainer': {
            'justify-content': 'space-between', //space-around
        },
    },
    productsContainer: {
        WebkitOverflowScrolling: 'touch',
        width: '90%',
        margin: '0 auto',
        paddingTop: 15, //DIFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF
        paddingBottom: 70, // temp unable to hive navbar fix
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


class CategoriesPage extends Component {
    constructor(props) {
        super(props);
        this.props.setCategoriesLoadedFlag(1); // Used for check in products
        this.state = {
            variantModalOpen: false,
            selectedProduct: null,
            variantModalWithComment: false,
        }

    }

    componentWillMount() {
        this.props.getCategories();
        this.props.showHeaderCategory(null);
    }

    componentDidMount() {
        this.props.setMainBackground('linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) )');
    }

    handleChange = (event, newValue) => {
        this.props.setChosenCat(newValue);
    };

    handleChangeIndex = index => {
        this.props.setChosenCat(index);
    };

    categoryClickHandler = (id) => {
        const { subcategory } = this.props;

        // if (Object.keys(allProducts).length > 0) {
        //     Utils.navigateTo('/products/' + id);
        //     return;
        // }

        // Send request only if products are not loaded
        // if (subcategory ? subcategory.findIndex(sub => sub && sub[0] && sub[0].id && sub[0].id == id) != -1 : false) {
        if (subcategory) {
            Utils.navigateTo('/products/' + id);
        } else {
            this.props.getProductsBySubcat(id);
            setTimeout(() => { Utils.navigateTo('/products/' + id) }, 100)
        }
    }



    // addToCartBtnClicked = (id) => {
    //     const currProduct = Object.values(this.props.products.all_products).filter(p => p.id === id)[0];

    //     if (currProduct.product_variants) {
    //         if (currProduct.product_variants.length === 1) {
    //             this.addProdToCart(currProduct.id, currProduct.product_variants[0].id, currProduct)
    //         } else {
    //             // Open variant modal
    //             this.setState({ variantModalOpen: true, selectedProduct: currProduct })
    //         }
    //     }

    // }




    // SAME
    // Opens variant modal or adds prod to cart
    addToCartBtnClicked = (id) => {
        const currProduct = Object.values(this.props.products.all_products).filter(p => p.id === id)[0];
        // const currProduct = products.filter(p => p.id === id)[0];

        if (this.props.settings && this.props.settings.variant_modal_with_comment && this.props.settings.variant_modal_with_comment.value == 1) {
            this.setState({ variantModalOpen: true, selectedProduct: currProduct, variantModalWithComment: true })
        } else {
            if (currProduct.product_variants) {
                if (currProduct.product_variants.length === 1) {
                    this.addProdToCart(currProduct.id, currProduct.product_variants[0].id, currProduct)
                } else {
                    // Open variant modal
                    this.setState({ variantModalOpen: true, selectedProduct: currProduct })
                }
            }
        }

    }

    addProdToCart = (product_id, product_variant_id, product) => {
        this.showAddedProductTooltip(product_id, product_variant_id, product);
        if (this.state.variantModalOpen) {
            this.setState({ variantModalOpen: false });
        }
        this.props.changeSelectedCartTab(0); // select default tab "Cart"
        this.props.addToCart(product_id, product_variant_id, product);
    };


    // Shows tooltip
    showAddedProductTooltip = (id, product_variant_id, product) => {
        let totalProdQuantity = 1;
        if (this.props.products && this.props.products.to_order && Object.keys(this.props.products.to_order).length > 0) {
            const addedProductVariant = this.props.products.to_order.find(pv => pv.product_variant_id === product_variant_id);
            if (addedProductVariant) {
                totalProdQuantity = parseInt(addedProductVariant.quantity) + 1;
            }
        }

        let choosedProductVariant = product.product_variants.filter(pv => pv.id === product_variant_id)[0];
        let tooltipText = totalProdQuantity + ' x ' + product.name + ' - /' + choosedProductVariant.size + '/';

        // Add to cart tooltip
        const addProdTooltip = (id, tooltipText) => this.props.addProductTooltip(id, tooltipText);
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
            this.setState({ timeout: null });
        }
        addProdTooltip(id, tooltipText);
        let timeOut = setTimeout(function () { addProdTooltip(0, tooltipText); }, 2500);
        this.setState({ timeout: timeOut });
    }
    // SAME ^

    render() {
        const { classes, categories, chosenCat, settings, translations } = this.props;
        const subcategories_design = settings.subcategories_design && settings.subcategories_design.value ? settings.subcategories_design.value : 0;

        return (
            <div className={classes.root} style={subcategories_design == '1' ? { backgroundColor: '#e6e6e6' } : {}} data-class="all-products-container">
                <AppBar position="static" color="default"
                    style={
                        subcategories_design == '1' ?
                            {
                                backgroundImage: 'url(https://tabl.bg/img/white-shiny-background.jpg)',
                                backgroundPosition: 'bottom',
                                backgroundAttachment: 'fixed',
                                backgroundSize: 'cover',
                            }
                            :
                            {
                                background: 'rgba(241, 241, 241, 0.85)'
                            }
                    }>
                    <Tabs
                        value={chosenCat}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        // variant="fullWidth"
                        scrollButtons="on"
                        variant="scrollable"
                        aria-label="full width tabs example"
                        className={classes.tabsContainer}
                    >
                        {Object.values(categories).map((category, k) => (
                            <Tab key={k} component="h2" 
                                label={Utils.splitCatSubcatOnTwoRowsBySpace((category.translations[this.props.currLang] && category.translations[this.props.currLang].name || category.name), settings)}
                                icon={<img className={classes.categoryIcon} src={Utils.getCategoryImagePath(category.icon)} />}
                                {...a11yProps(++k)} className={classes.categoryName} 
                                data-class="each-category"
                            />
                        ))}
                    </Tabs>
                </AppBar>
                <SwipeableViews className={classes.swipeableViews}
                    data-class="swipeable-container"
                    index={chosenCat}
                    onChangeIndex={this.handleChangeIndex}
                    enableMouseEvents
                    animateTransitions={false}
                >

                    {Object.values(categories).map((category, k) => (
                        <TabPanel key={k} value={chosenCat} index={k}>

                            {category.no_subcategories == true ?
                                <Grid
                                    className={classes.productsContainer}
                                    container
                                    direction="row"
                                    //   justify="space-between"
                                    //   justify="center"
                                    // alignItems="center"
                                    spacing={3}
                                >
                                    {Object.values(category.subcategories).map((subcategory, kkk) =>
                                        Object.values(this.props.allProducts).filter(o => o.subcategory.id == subcategory.id).map((product, kkk) => (
                                            <ProductListItem
                                                k={kkk}
                                                // hideProductImage={1}
                                                // showSubCatInProductHeader={1}
                                                product={product}
                                                settings={settings}
                                                addProdToCart={this.addProdToCart}
                                                addToCartBtnClicked={this.addToCartBtnClicked}
                                                translations={translations}
                                                currLang={this.props.currLang}
                                            />
                                        ))
                                    )}
                                </Grid>
                                :
                                <React.Fragment>
                                    {subcategories_design == '1' ?
                                        <React.Fragment>
                                            <Promotions settings={settings} translations={translations.translations} />
                                            <ImageButton
                                                items={category.subcategories}
                                                categoryClickHandler={this.categoryClickHandler}
                                                currLang={this.props.currLang}
                                            />
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <Promotions settings={settings} translations={translations.translations} />
                                            <RowButton
                                                items={category.subcategories}
                                                categoryClickHandler={this.categoryClickHandler}
                                                translations={translations.translations}
                                                currLang={this.props.currLang}
                                            />
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }
                        </TabPanel>
                    ))}

                </SwipeableViews>


                <ProductVariantsModal
                    open={this.state.variantModalOpen}
                    variantModalWithComment={this.state.variantModalWithComment}
                    product={this.state.selectedProduct}
                    addProdToCart={this.addProdToCart}
                    product_variants_comment={this.props.products.product_variants_comment}
                    setOrderComment={this.props.setOrderComment}
                    closeProductVariantModalHandler={() => this.setState({ variantModalOpen: !this.state.variantModalOpen })}
                    settings={settings}
                    translations={translations}
                    currLang={this.props.currLang}
                />

            </div>
        );

    }
}

const mapStateToProops = (state, ownProps) => {
    return {
        categories: state.categories.categories,
        subcategory: state.products.subcat_products,
        chosenCat: state.categories.chosen_cat || 0,
        settings: state.settings.settings || [],
        translations: state.lang,
        currLang: state.lang.currLang,
        products: state.products || [],
        allProducts: state.products.all_products || [],
    };
};

export default withStyles(styles)(connect(mapStateToProops, { showHeaderCategory, getProductsBySubcat, setChosenCat, setCategoriesLoadedFlag, getCategories, addToCart, addProductTooltip, changeSelectedCartTab, setMainBackground, setOrderComment })(CategoriesPage));

