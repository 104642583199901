
const initialState = {
}

export default function (state = initialState, action) {
    state.errors = [];

    switch (action.type) {

        default:
            return state;
    }
}