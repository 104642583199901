import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import { showHeaderCategory } from '../../categories/actions/categories.action';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import EmailIcon from '@material-ui/icons/Email';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import { setMainBackground } from '../../settings/actions/settings.action';
import clsx from 'clsx';

const styles = theme => ({
    feedBackContainer: {
        // backgroundColor: 'rgba(147, 147, 147, 0.4)',
        height: 'calc(100vh - 110px)',
        paddingTop: 55,
        paddingBottom: 55,
        paddingLeft: '10%',
        paddingRight: '10%',
        display: 'grid',
        'grid-template-rows': '100px calc(100% - 200px) 100px',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        '@media screen and (max-width: 1000px)': {
            '&': {
                paddingLeft: '5%',
                paddingRight: '5%',
            },
        },
    },
    mainImageContainer: {
        height: '100%',
        backgroundImage: 'url(https://tabl.bg/img/website_images/3dphone.png)',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
    },
    imageContactsContainer: {
        display: 'grid',
        gridTemplateColumns: '25% 50% 25%',
        '& > *': {
            alignSelf: 'center',
        },
        '& > *:last-child': {
            textAlign: 'right',
        },
        '@media screen and (max-width: 750px)': {
            gridTemplateColumns: 'none',
            gridTemplateRows: '75px calc(100% - 150px) 75px',
            '& > *': {
                textAlign: 'center !important',
            },
        },
    },
    // logoImage: {
    //     width: '50%',
    //     maxWidth: 500,
    //     margin: '0 auto',
    //     display: 'block',
    //     marginTop: 30,
    // },
    aboutUsTitle: {
        color: '#efefef',
        fontSize: 23,
        textAlign: 'right',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    },
    aboutUsTitleDark: {
        color: '#000000',
    },
    aboutUsSubTitle: {
        color: '#efefef',
        fontSize: 18,
        marginTop: 10,
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    },
    aboutUsSubTitleDark: {
        color: '#202020',
    },
    contactIcons: {
        fontSize: 30,
        color: '#ddd',
    },
    contactIconsDark: {
        color: '#363636'
    },
    contactIconsText: {
        verticalAlign: 9,
        color: 'white',
        fontSize: 16,
    },
    contactIconsTextDark: {
        color: '#363636'
    }
});

class AboutUsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            animatedContainerVisible: false,
            secondAnimatedContainerVisible: false,
        }
    }
    componentWillMount() {
        this.props.showHeaderCategory(<span className="skiptranslate">TABL</span>);
    }

    componentDidMount() {
        setTimeout(() => this.setState({ animatedContainerVisible: true }), 500)
        setTimeout(() => this.setState({ secondAnimatedContainerVisible: true }), 1000)
        this.props.setMainBackground('linear-gradient( rgba(147, 147, 147, 0.4), rgba(147, 147, 147, 0.4) )');
    }

    render() {
        let { classes, translations, settings } = this.props;
        translations = translations.translations;

        // this.props.showHeaderCategory(<span className="skiptranslate">TABL</span>);


        return (
            <Fade in={true} timeout={{ enter: 350, exit: 350 }}>

                <div className={classes.feedBackContainer}>
                    <Grow in={this.state.animatedContainerVisible} timeout={{ enter: 450, exit: 350 }}>
                        <h2 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.aboutUsTitle, classes.aboutUsTitleDark) : classes.aboutUsTitle}>
                            {translations.pages && translations.pages.about_tabl ? translations.pages.about_tabl.title : ''} <span className="skiptranslate">TabL !</span>
                        </h2>
                    </Grow>

                    <div className={classes.imageContactsContainer}>
                        <Fade in={this.state.secondAnimatedContainerVisible} timeout={{ enter: 350, exit: 350 }}>
                            <div>
                                <a href="mailto:info@tabl.bg" className="text-decoration-none skiptranslate">
                                    <EmailIcon className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.contactIcons, classes.contactIconsDark) : classes.contactIcons} />
                                    <span className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.contactIconsText, classes.contactIconsTextDark) : classes.contactIconsText}>
                                        &nbsp;
                                        info@tabl.bg
                                    </span>
                                </a>
                                <br />
                                <a href={translations.pages && translations.pages.about_tabl ? translations.pages.about_tabl.about_link : 'https://tabl.bg/za-nas'} target="_blank" className="text-decoration-none skiptranslate">
                                    <ImportantDevicesIcon className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.contactIcons, classes.contactIconsDark) : classes.contactIcons} />
                                    <span className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.contactIconsText, classes.contactIconsTextDark) : classes.contactIconsText}>
                                        &nbsp;
                                        {translations.pages && translations.pages.about_tabl ? translations.pages.about_tabl.about_link : 'https://tabl.bg/za-nas'}
                                    </span>
                                </a>
                            </div>
                        </Fade>

                        <Grow in={true} timeout={{ enter: 450, exit: 350 }}>
                            <div className={classes.mainImageContainer}>
                            </div>
                        </Grow>

                        <Fade in={this.state.secondAnimatedContainerVisible} timeout={{ enter: 350, exit: 350 }} >
                            <div>
                                <a href="tel:+359884014689" className="text-decoration-none skiptranslate">
                                    <PhoneInTalkIcon className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.contactIcons, classes.contactIconsDark) : classes.contactIcons} />
                                    <span className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.contactIconsText, classes.contactIconsTextDark) : classes.contactIconsText}>
                                        &nbsp;
                                        +359 884 014 689
                                    </span>
                                </a>
                                <br />
                                <a href="tel:+359878801797" className="text-decoration-none skiptranslate">
                                    <PhoneInTalkIcon className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.contactIcons, classes.contactIconsDark) : classes.contactIcons} />
                                    <span className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.contactIconsText, classes.contactIconsTextDark) : classes.contactIconsText}>
                                        &nbsp;
                                        +359 878 801 797
                                    </span>
                                </a>
                            </div>
                        </Fade>
                    </div>

                    <Grow in={this.state.animatedContainerVisible} timeout={{ enter: 450, exit: 350 }}>
                        <div className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.aboutUsSubTitle, classes.aboutUsSubTitleDark) : classes.aboutUsSubTitle}>
                            {translations.pages && translations.pages.about_tabl ? translations.pages.about_tabl.sub_title : ''}
                        </div>
                    </Grow>

                    {/* 
                    <img src='https://tabl.bg/img/tabl-transparent_black.png' className={classes.logoImage} />
                    <FacebookIcon /> /tabl.bg
                    <InstagramIcon /> /tabl.bg 
                    */}

                </div>
            </Fade >
        )
    }

}


const mapStateToProps = (state, ownProps) => {
    return {
        translations: state.lang,
        settings: state.settings.settings || [],
    };
};

export default withStyles(styles)(connect(mapStateToProps, { showHeaderCategory, setMainBackground })(AboutUsPage));
