import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Promotions from '../../common/containers/Promotions'
import Grid from '@material-ui/core/Grid';
import CartCommentModal from '../../common/modals/CartCommentModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import ProductVariantsModal from '../../common/modals/ProductVariant'
import ProductListItem from "../../products/components/ProductListItem";
import { getProductsBySubcat, addToCart, addProductTooltip, changeSelectedCartTab, setOrderComment, setCommentModalData } from '../../products/actions/products.action';
import { showHeaderCategory, setChosenCat, setCategoriesLoadedFlag, getCategories, setHierarchyLevel } from '../actions/categories.action';
import { setMainBackground } from '../../settings/actions/settings.action';
import Utils from '../../utils/Utils';
import ImageButton from './ImageButton';
import RowButtonHierarchy from './RowButtonHierarchy';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';


import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';


import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';


const styles = theme => ({
    root: {
        marginTop: 135,
        // backgroundColor: 'black', //down from settings
        width: '100%',

        // Categories underline indicator
        '& .MuiTabs-indicator': {
            backgroundColor: '#FE6902'
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            color: 'inherit',
            fontWeight: 'bold'
        },
        '& > .MuiPaper-root': {
            zIndex: 1099,
            position: 'absolute',
            paddingTop: 55,
            top: 0,
        },
    },
    categoryName: {
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        'flex-grow': 1,
        'max-width': 'none',
        lineHeight: 'initial'
    },
    categoryIcon: {
        height: 35,
    },
    swipeableViews: {
        height: 'calc(100vh - 135px)',
        WebkitOverflowScrolling: 'touch',
    },
    tabsContainer: {
        '& .MuiTabs-flexContainer': {
            'justify-content': 'space-between', //space-around
        },
    },
    productsContainer: {
        WebkitOverflowScrolling: 'touch',
        width: '90%',
        margin: '0 auto',
        paddingTop: 15, //DIFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF
        paddingBottom: 70, // temp unable to hive navbar fix
    },
    backdrop: {
        zIndex: 2,
        color: '#fff',
        position: 'absolute !important', //backdrop not on the full screen
    },

    // Image gallery
    gridList: {
        // width: 500,
        height: 450,
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


class CategoriesHierarchyPage extends Component {
    constructor(props) {
        super(props);
        this.props.setCategoriesLoadedFlag(1); // Used for check in products
        this.state = {
            variantModalOpen: false,
            selectedProduct: null,
            variantModalWithComment: false,
            currHierarchyLevel: 1,
            openCommentModal: false,
            commentModalTitle: '',
            commentVariantId: '',
        }

    }

    componentWillMount() {
        // this.props.getCategories();
        this.props.showHeaderCategory(null);
    }

    componentWillUnmount() {
        // console.log( "back from cattt" )

    }

    componentDidMount() {
        this.props.setMainBackground('linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) )');
    }

    componentWillReceiveProps(nextProps) {
        // if(nextProps.selectedCat) {
        //     this.setState({
        //         currHierarchyLevel: nextProps.selectedCat.hierarchyLevel
        //     })
        // }
    }

    handleChange = (event, newValue) => {
        const { translations } = this.props;


        this.props.setChosenCat(newValue);
        // let catSlug = this.props.allCatsHierarchy && Object.values(this.props.allCatsHierarchy)[newValue] ? Object.values(this.props.allCatsHierarchy)[newValue].slug : "";
        let cat = this.props.categories && this.props.categories[newValue] ? this.props.categories[newValue] : [];

        if (cat) {
            if (cat.hierarchyLevel > 1) {
                // this.props.showHeaderCategory(cat.name)
                this.props.showHeaderCategory(translations && translations.translations && translations.translations.common ? translations.translations.common.action.back : '')
            }
            this.props.history.push("/" + cat.slug);
        }
        // let catSlug = this.props.categories && this.props.categories[newValue] ?  this.props.categories[newValue].slug : "";
        // let catLevel = this.props.categories && this.props.categories[newValue] ?  this.props.categories[newValue].hierarchyLevel : 1;
    };

    handleChangeIndex = index => {
        this.props.setChosenCat(index);
        // let catSlug = this.props.allCatsHierarchy && Object.values(this.props.allCatsHierarchy)[index] ? Object.values(this.props.allCatsHierarchy)[index].slug : "";
        let catSlug = this.props.categories && this.props.categories[index] ? this.props.categories[index].slug : "";
        this.props.history.push("/" + catSlug);
    };

    categoryClickHandler = (id) => {
        const { translations } = this.props;

        const { subcategory } = this.props;
        var newLevel = this.props.allCatsHierarchy[id].hierarchyLevel;

        this.props.setHierarchyLevel(newLevel);
        if (parseInt(newLevel) > 1) {
            // this.props.showHeaderCategory(this.props.allCatsHierarchy[id].name)
            this.props.showHeaderCategory(translations && translations.translations && translations.translations.common ? translations.translations.common.action.back : '')
        }
        this.setState({ currHierarchyLevel: newLevel });
        this.props.setChosenCat(0);
        this.props.history.push("/" + this.props.allCatsHierarchy[id].slug);

        // if (Object.keys(allProducts).length > 0) {
        //     Utils.navigateTo('/products/' + id);
        //     return;
        // }

        // Send request only if products are not loaded
        // if (subcategory ? subcategory.findIndex(sub => sub && sub[0] && sub[0].id && sub[0].id == id) != -1 : false) {
        // if (subcategory) {
        //     Utils.navigateTo('/products/' + id);
        // } else {
        //     this.props.getProductsBySubcat(id);
        //     setTimeout(() => { Utils.navigateTo('/products/' + id) }, 100)
        // }
    }


    // addToCartBtnClicked = (id) => {
    //     const currProduct = Object.values(this.props.products.all_products).filter(p => p.id === id)[0];

    //     if (currProduct.product_variants) {
    //         if (currProduct.product_variants.length === 1) {
    //             this.addProdToCart(currProduct.id, currProduct.product_variants[0].id, currProduct)
    //         } else {
    //             // Open variant modal
    //             this.setState({ variantModalOpen: true, selectedProduct: currProduct })
    //         }
    //     }

    // }



    // SAME
    // Opens variant modal or adds prod to cart
    addToCartBtnClicked = (id, productVariantId = 0) => {
        const currProduct = Object.values(this.props.products.all_products).filter(p => p.id === id)[0];
        // const currProduct = products.filter(p => p.id === id)[0];

        if (this.props.settings && this.props.settings.variant_modal_with_comment && this.props.settings.variant_modal_with_comment.value == 1) {
            this.setState({ variantModalOpen: true, selectedProduct: currProduct, variantModalWithComment: true })
        } else {
            if (currProduct.product_variants) {
                if (currProduct.product_variants.length === 1 || productVariantId > 0) {
                    this.addProdToCart(currProduct.id, productVariantId ? productVariantId : currProduct.product_variants[0].id, currProduct)
                } else {
                    // Open variant modal
                    this.setState({ variantModalOpen: true, selectedProduct: currProduct })
                }
            }
        }

    }

    addProdToCart = (product_id, product_variant_id, product, prodQuantiy = 1) => {

        if (this.state.variantModalOpen) {
            this.setState({ variantModalOpen: false });
        }

        // console.log('proddddDDdd!!!', product)

        const requiredProductIngridients = product.product_ingredients.filter(pi => pi.type == 'required');

        let defautRequiredProductIngridient = 0;
        if (requiredProductIngridients && requiredProductIngridients[0] && requiredProductIngridients[0].ingredientId) {
            defautRequiredProductIngridient = requiredProductIngridients[0].ingredientId;
        }

        const selectedIngredients = {
            required: defautRequiredProductIngridient,
            addable: [],
            removable: [],
        }

        this.showAddedProductTooltip(product_id, product_variant_id, product, selectedIngredients, prodQuantiy);
        this.props.changeSelectedCartTab(0); // select default tab "Cart"
        this.props.addToCart(product_id, product_variant_id, product, selectedIngredients, prodQuantiy);
    };


    // Shows tooltip
    showAddedProductTooltip = (id, product_variant_id, product, selectedIngredients, totalProdQuantity = 1) => {
        // let totalProdQuantity = 1;
        // console.log('product_variant_id', product_variant_id)
        // console.log('this.props.products.to_order', this.props.products.to_order)
        let prodSize = '';
        if (this.props.products && this.props.products.to_order && Object.keys(this.props.products.to_order).length > 0) {
            let addedProductVariant = this.props.products.to_order.find(pv => pv && pv[0] && pv[0].product_variant_id === product_variant_id);
            addedProductVariant = addedProductVariant && addedProductVariant[0] ? addedProductVariant[0] : addedProductVariant;
            // console.log('addedProductVariant', addedProductVariant)
            if (addedProductVariant) {
                // console.log('totalProdQuantity', totalProdQuantity)
                totalProdQuantity = parseInt(addedProductVariant.quantity) + totalProdQuantity;
                prodSize = addedProductVariant.size;
            }
        }

        // Old faster way
        // if (this.props.products && this.props.products.to_order && this.props.products.to_order[0] && this.props.products.to_order[0].length > 0) {
        //     console.log('q: ', totalProdQuantity)
        //     totalProdQuantity = Utils.getCartProductQuantity(product_variant_id, selectedIngredients);
        //     console.log('totalProdQuantity', totalProdQuantity)
        // }
        //

        // let totalProdQuantity = 1;
        // console.log('this.props.products', this.props.products)
        // if (this.props.products && this.props.products.to_order && this.props.products.to_order[0] && this.props.products.to_order[0].length > 0) {
        //     console.log('q: ', totalProdQuantity)
        //     let newTotalProdQuantity = Utils.getCartProductQuantity(product_variant_id, selectedIngredients);
        //     console.log('totalProdQuantity', totalProdQuantity)
        //     console.log('newTotalProdQuantity', newTotalProdQuantity)
        //     if (newTotalProdQuantity != 1) {
        //         totalProdQuantity = newTotalProdQuantity
        //     }
        // }

        let choosedProductVariant = [];
        // console.log('prodSize', prodSize)
        if (!!(prodSize && prodSize != ' ')) {
            choosedProductVariant = product.product_variants.filter(pv => pv.id === product_variant_id)[0];
        }
        // console.log('choosedProductVariant', choosedProductVariant)
        let tooltipText = totalProdQuantity + ' x ' + product.name + (prodSize && prodSize != '' && prodSize != ' ' ? (' - /' + prodSize + '/') : (choosedProductVariant.size && choosedProductVariant.size != ' ' ? ' - /' + choosedProductVariant.size + '/' : ''));

        // Add to cart tooltip
        const addProdTooltip = (id, tooltipText) => this.props.addProductTooltip(id, tooltipText);
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
            this.setState({ timeout: null });
        }
        addProdTooltip(id, tooltipText);
        let timeOut = setTimeout(function () { addProdTooltip(0, tooltipText); }, 2500);
        this.setState({ timeout: timeOut });
    }
    // SAME ^


    addCommentToOrder = (variantId, comment) => {
        let stateComments = Object.values(this.props.products.product_variants_comment);
        let validatedComments = Utils.validatedCommentObject(stateComments, variantId, comment)
        if (validatedComments) {
            this.props.setOrderComment(validatedComments)
        }
    }


    render() {
        const { classes, categories, chosenCat, settings, translations, isLoadingHierarchy } = this.props;
        const subcategories_design = settings.subcategories_design && settings.subcategories_design.value ? settings.subcategories_design.value : 0;

        return (
            <div className={classes.root} style={subcategories_design == '1' && this.props.selectedCat && this.props.selectedCat.children && this.props.selectedCat.children.length > 0 ? { backgroundColor: '#e6e6e6' } : {}} data-class="all-products-container">

                <Backdrop open={isLoadingHierarchy} className={classes.backdrop}>
                    <CircularProgress className="is-loading" />
                </Backdrop>

                <AppBar position="static" color="default"
                    style={
                        (subcategories_design == '1') ?
                            {
                                backgroundImage: 'url(https://tabl.bg/img/white-shiny-background.jpg)',
                                backgroundPosition: 'bottom',
                                backgroundAttachment: 'fixed',
                                backgroundSize: 'cover',
                            }
                            :
                            {
                                background: 'rgba(241, 241, 241, 0.85)'
                            }
                    }>
                    <Tabs
                        value={this.props.catIndex}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        // variant="fullWidth"
                        scrollButtons="on"
                        variant="scrollable"
                        aria-label="full width tabs example"
                        className={classes.tabsContainer}
                    >
                        {Object.values(categories).map((category, k) => {
                            // console.log('category', category)
                            return (
                                <Tab key={k} component="h2"
                                    label={Utils.splitCatSubcatOnTwoRowsBySpace((category.translations[this.props.currLang] && category.translations[this.props.currLang].name || category.name), settings)}
                                    icon={<img className={classes.categoryIcon} src={Utils.getCategoryImagePath(category.icon)} />}
                                    {...a11yProps(++k)} className={classes.categoryName}
                                    data-class="each-category"
                                />
                            )
                        })}
                    </Tabs>
                </AppBar>
                <SwipeableViews className={classes.swipeableViews}
                    data-class="swipeable-container"
                    index={this.props.catIndex}
                    onChangeIndex={this.handleChangeIndex}
                    enableMouseEvents
                    animateTransitions={false}
                >
                    {Object.values(categories).map((category, k) => (
                        <TabPanel key={k} value={this.props.catIndex} index={k}>

                            {category.children.length == 0 ?
                                <Grid
                                    className={classes.productsContainer}
                                    container
                                    direction="row"
                                    //   justify="space-between"
                                    //   justify="center"
                                    // alignItems="center"
                                    spacing={3}
                                >
                                    {Object.values(this.props.allProducts).filter(product => product.catHierarchyId === category.id).map((product, kkk) => (
                                        <ProductListItem
                                            k={kkk}
                                            // hideProductImage={1}
                                            // showSubCatInProductHeader={1}
                                            product={product}
                                            settings={settings}
                                            addProdToCart={this.addProdToCart}
                                            addToCartBtnClicked={this.addToCartBtnClicked}
                                            translations={translations}
                                            currLang={this.props.currLang}
                                            setCommentModalProps={(commentModalTitle, commentVariantId) => { this.props.setCommentModalData(true, commentModalTitle, commentVariantId) }}
                                        // openCommentModal={() => { this.setState({ openCommentModal: true }) }}
                                        // modalTitle={() => { this.setState({ modalTitle: modalTitle }) }}
                                        // commentVariantId={() => { this.setState({ commentVariantId: commentVariantId }) }}
                                        />
                                    ))
                                    }
                                </Grid>
                                :
                                <React.Fragment>
                                    {subcategories_design == '1' ?
                                        <React.Fragment>
                                            <Promotions settings={settings} translations={translations.translations} />
                                            <ImageButton
                                                items={category.children}
                                                categoryClickHandler={this.categoryClickHandler}
                                                currLang={this.props.currLang}
                                            />
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <Promotions settings={settings} translations={translations.translations} />
                                            <RowButtonHierarchy
                                                items={category.children}
                                                categoryClickHandler={this.categoryClickHandler}
                                                translations={translations.translations}
                                                currLang={this.props.currLang}
                                            />
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }
                        </TabPanel>
                    ))}

                </SwipeableViews>

                {this.state.openCommentModal ?
                    <CartCommentModal
                        open={this.state.openCommentModal}
                        modalTitle={this.state.commentModalTitle}
                        commentVariantId={this.state.commentVariantId}
                        comments={this.props.products.product_variants_comment}
                        onClose={() => { this.setState({ openCommentModal: false }) }}
                        onSubmit={this.addCommentToOrder}
                    />

                    :
                    null
                }
                <ProductVariantsModal
                    open={this.state.variantModalOpen}
                    variantModalWithComment={this.state.variantModalWithComment}
                    product={this.state.selectedProduct}
                    addProdToCart={this.addProdToCart}
                    product_variants_comment={this.props.products.product_variants_comment}
                    setOrderComment={this.props.setOrderComment}
                    closeProductVariantModalHandler={() => this.setState({ variantModalOpen: !this.state.variantModalOpen })}
                    settings={settings}
                    translations={translations}
                    currLang={this.props.currLang}
                />

            </div >
        );

    }
}

const mapStateToProops = (state, ownProps) => {
    var catsHierarchy = state.categories.categories_hierarchy || [];
    let catSlug = ownProps.match.params.catSlug ? ownProps.match.params.catSlug : "";

    let selectedCat = catsHierarchy && Object.values(catsHierarchy).filter(c => c.slug === catSlug)[0] ? Object.values(catsHierarchy).filter(c => c.slug === catSlug)[0] : null;
    if (!selectedCat) {
        if(Object.keys(catsHierarchy).length > 0) {
            let firstLevelCats = Object.values(catsHierarchy).filter(c => c.hierarchyLevel == 1);
            if(firstLevelCats[0]) {
                selectedCat = firstLevelCats[0];
            }
        } else {
            selectedCat = [];
        }
        // selectedCat = catsHierarchy && Object.values(catsHierarchy).filter(c => c.curr_hierarchy_level == 1) ? Object.values(catsHierarchy)[0] : [];
    }
    var level = selectedCat.hierarchyLevel || 1; // state.categories.curr_hierarchy_level;
    var currCats = Object.values(catsHierarchy).filter(c => c.hierarchyLevel === level && c.parentId === selectedCat.parentId);
    let catIndex = currCats && Object.values(currCats).findIndex(e => e.id === selectedCat.id);

    if (catIndex === -1) catIndex = 0;

    // if(selectedCat) {
    //     setHierarchyLevel(selectedCat.hierarchyLevel);
    // }

    return {
        catSlug: catSlug || "",
        allCatsHierarchy: catsHierarchy,
        categories: currCats, //state.categories.categories,
        catIndex: catIndex,
        selectedCat: selectedCat,

        isLoadingHierarchy: state.categories.hierarchy_loading || false,
        subcategory: state.products.subcat_products,
        chosenCat: state.categories.chosen_cat || 0,
        settings: state.settings.settings || [],
        translations: state.lang,
        currLang: state.lang.currLang,
        products: state.products || [],
        allProducts: state.products.all_products || [],
    };
};

export default withStyles(styles)(connect(mapStateToProops, {
    showHeaderCategory, getProductsBySubcat, setChosenCat, setCategoriesLoadedFlag,
    getCategories, addToCart, addProductTooltip, changeSelectedCartTab, setMainBackground, setOrderComment,
    setHierarchyLevel, setCommentModalData
})(CategoriesHierarchyPage));

