import { GET_SHOPS, NEW_SHOP_MODAL_OPENED, SELECT_LAST_ADDED_SHOP, SHOP_INFO_MODAL_OPENED, SET_CURR_SHOP_ID } from './../actions/types';

const initialState = {
    shops: [],
    showModalOpened: false,
    selectLastAddedShop: false,
    shopInfoModalOpened: false,
    currShopId: 0
}

export default function(state = initialState, action) {
    state.errors = [];

    switch(action.type) {
        case GET_SHOPS:
            return {
                ...state,
                shops: action.payload,
            }
        case NEW_SHOP_MODAL_OPENED:
            return {
                ...state,
                showModalOpened: action.payload,
            }
        case SELECT_LAST_ADDED_SHOP: 
            return {
                ...state,
                selectLastAddedShop: action.payload,
            }
        case SHOP_INFO_MODAL_OPENED: 
            return {
                ...state,
                shopInfoModalOpened: action.payload
            }
        case SET_CURR_SHOP_ID:
            return {
                ...state,
                currShopId: action.payload
            }
        default:
            return state;
    }
}