let currentDomain = window.location.origin + '/';
let apiDomain = currentDomain;

if (window.location.hostname === 'localhost') {
    apiDomain = 'http://localhost:4000/'; // Custom api requests url
}

export const API_URL = apiDomain + "api/";

// Listing
export const ITEMS_PER_PAGE = 10;


// Image
export const IMG_PATH = currentDomain;
export const PRODUCT_ORDER_IMG_URL = IMG_PATH + "files/orders/";
export const PRODUCT_IMG_URL = IMG_PATH + "files/images/";
export const PRODUCT_THUMB_IMG_URL = IMG_PATH + "files/images_thumbs/";
export const SETTINGS_IMG_URL = IMG_PATH + "files/images/";
export const DEFAULT_PRODUCT_IMAGE = 'https://tabl.bg/img/default-product-image.svg';
export const DEFAULT_PRODUCT_THUMB_IMAGE = DEFAULT_PRODUCT_IMAGE; // Temporary

export const DEFAULT_SUBCATEGORY_IMAGE = 'https://tabl.bg/img/food/' + (Math.floor(Math.random() * 40) + 1) + '.png'; //'http://lorempixel.com/400/200/food/SERVER-TEST-IMAGE/'; //'https://tabl.bg/img/default-subcategory-image.jpg';
export const DEFAULT_CATEGORY_IMAGE = 'https://tabl.bg/img/default-category-image.svg';
export const DEFAULT_USER_ICON = 'https://tabl.bg/img/default-user-icon.svg'; //feedback

// Settings
export const SCAN_QRCODE_REQUIRED = false;
export const PREORDER_ALLOWED = true;
export const ORDER_EXPIRE_TIME_HOURS = 3;
export const BILL_WANTED_EXPIRE_TIME_MINUTES = 15;
export const DEMO = (window.location.hostname == 'demo.tabl.bg' || window.location.hostname == 'test.tabl.bg' || window.location.hostname == 'localhost'); //true;
