import { SET_CURRENT_USER, SET_LOGIN_MODAL_OPENED, SET_REGISTER_MODAL_OPENED, DEAUTHENTICATE_USER, CHANGE_PASSWORD_MODAL_OPENED, START_LOAGIND_LOGIN, END_LOAGIND_LOGIN } from '../actions/types';
import isEmpty from 'lodash/isEmpty';

const initialState = {
    isAuthenticated: false,
    user: {},
    auth_error: "",
    login_modal_opened: false,
    register_modal_opened: false,
    changePasswordModalOpened: false,
    isLoadingLogin: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                isAuthenticated: !isEmpty(action.user),
                user: action.user
            }
        case SET_LOGIN_MODAL_OPENED:
            return {
                ...state,
                login_modal_opened: action.state
            }
        case SET_REGISTER_MODAL_OPENED:
            return {
                ...state,
                register_modal_opened: action.state
            }
        case DEAUTHENTICATE_USER:
            return {
                ...state,
                isAuthenticated: false,
                user: {},
            }
        case CHANGE_PASSWORD_MODAL_OPENED:
            return {
                ...state,
                changePasswordModalOpened: action.payload,
            }
        case START_LOAGIND_LOGIN:
            return {
                ...state,
                isLoadingLogin: true,
            }
        case END_LOAGIND_LOGIN:
            return {
                ...state,
                isLoadingLogin: false,
            }

        default: return state;
    }
}