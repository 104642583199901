import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';

import Promotions from '../../common/containers/Promotions'
import ProductListItem from "./ProductListItem";
import ProductVariantsModal from "../../common/modals/ProductVariant";
import { getProductsBySubcat, addProductTooltip, addToCart, changeSelectedCartTab, getAllProducts, removeProductPageId } from '../actions/products.action';
import { showHeaderCategory, getCategories } from '../../categories/actions/categories.action';
import Page404 from '../../common/errors/404'
import ProductsFooterContainer from '../../common/containers/ProductsFooterContainer';
import Utils from '../../utils/Utils';
import { setMainBackground } from '../../settings/actions/settings.action';


const styles = theme => ({
    root: {
        marginTop: 135,
        // backgroundColor: 'black', //down from settings
        width: '100%',

        // Categories underline indicator
        '& .MuiTabs-indicator': {
            backgroundColor: '#FE6902'
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            color: 'inherit',
            fontWeight: 'bold'
        },
        '& > .MuiPaper-root': {
            zIndex: 1099,
            position: 'absolute',
            paddingTop: 55,
            top: 0,
        },
    },
    categoryName: {
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        'flex-grow': 1,
        'max-width': 'none',
        lineHeight: 'initial'
    },
    categoryIcon: {
        height: 35,
    },
    swipeableViews: {
        height: 'calc(100vh - 135px)',
        WebkitOverflowScrolling: 'touch',
    },
    tabsContainer: {
        '& .MuiTabs-flexContainer': {
            'justify-content': 'space-between', //space-around
        },
    },
    productsContainer: {
        WebkitOverflowScrolling: 'touch',
        width: '90%',
        margin: '0 auto',
        paddingTop: 15, //DIFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF
        paddingBottom: 70, // temp unable to hive navbar fix
    },
    headerInfoText: {
        width: '80%',
        maxWidth: 550,
        borderRadius: 5,
        background: 'rgba(204, 204, 204, 0.6)',
        margin: '0 auto',
        marginTop: 160,
        padding: 14,
        fontSize: 17,
        textAlign: 'center',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    },
});


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

class SubcatsWithProductsSluggedPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            variantModalOpen: false,
            selectedProduct: null,
            timeout: null,
            designVariantButtonsOpened: false,
            selectedSubCategory: 0,
        }
    }

    componentDidMount() {
        this.props.setMainBackground('linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) )');
    }

    // Opens variant modal or adds prod to cart
    addToCartBtnClicked = (id) => {
        const currProduct = Object.values(this.props.products.all_products).filter(p => p.id === id)[0];

        if (currProduct.product_variants) {
            if (currProduct.product_variants.length === 1) {
                this.addProdToCart(currProduct.id, currProduct.product_variants[0].id, currProduct)
            } else {
                // Open variant modal
                this.setState({ variantModalOpen: true, selectedProduct: currProduct })
            }
        }

    }

    addProdToCart = (product_id, product_variant_id, product) => {
        this.showAddedProductTooltip(product_id, product_variant_id, product);
        if (this.state.variantModalOpen) {
            this.setState({ variantModalOpen: false });
        }
        this.props.changeSelectedCartTab(0); // select default tab "Cart"
        this.props.addToCart(product_id, product_variant_id, product);
    };


    // Shows tooltip
    showAddedProductTooltip = (id, product_variant_id, product) => {
        let totalProdQuantity = 1;
        if (this.props.products && this.props.products.to_order && Object.keys(this.props.products.to_order).length > 0) {
            const addedProductVariant = this.props.products.to_order.find(pv => pv.product_variant_id === product_variant_id);
            if (addedProductVariant) {
                totalProdQuantity = parseInt(addedProductVariant.quantity) + 1;
            }
        }

        let choosedProductVariant = product.product_variants.filter(pv => pv.id === product_variant_id)[0];
        let tooltipText = totalProdQuantity + ' x ' + product.name + ' - /' + choosedProductVariant.size + '/';

        // Add to cart tooltip
        const addProdTooltip = (id, tooltipText) => this.props.addProductTooltip(id, tooltipText);
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
            this.setState({ timeout: null });
        }
        addProdTooltip(id, tooltipText);
        let timeOut = setTimeout(function () { addProdTooltip(0, tooltipText); }, 2500);
        this.setState({ timeout: timeOut });
    }

    componentWillMount() {
        this.props.showHeaderCategory(null);
        this.props.getCategories();
        // this.props.getAllProducts(1);
    }

    handleChange = (event, newValue) => {
        let subcatSlug = this.props.subcats && Object.values(this.props.subcats)[newValue] ? Object.values(this.props.subcats)[newValue].slug : "";

        this.props.removeProductPageId();

        this.props.history.push("/" + subcatSlug);

        // I think it's not needed anymore
        this.setState({ selectedSubCategory: newValue })
    };

    handleChangeIndex = index => {
        let subcatSlug = this.props.subcats && Object.values(this.props.subcats)[index] ? Object.values(this.props.subcats)[index].slug : "";

        this.props.removeProductPageId();

        // I think it's not needed anymore
        this.setState({ selectedSubCategory: index })
        this.props.history.push("/" + subcatSlug);
    };

    render() {
        const { classes, settings, products, categories, translations } = this.props;
        // const ACTIVE_CAT = 0; //first found cat
        // const subcats = (categories && Object.values(categories)[ACTIVE_CAT] && Object.values(categories)[ACTIVE_CAT].subcategories) ? Object.values(categories)[ACTIVE_CAT].subcategories : [];
        // const selectedSubCatId = subcats && Object.values(subcats)[this.state.selectedSubCategory] ? Object.values(subcats)[this.state.selectedSubCategory].id : 0;

        // const filteredProds = products && products.all_products && (Object.values(products.all_products).filter(p => p && p.subcategory && p.subcategory.id == selectedSubCatId));

        return (
            <div className={classes.root} data-class="all-products-container">
                <AppBar position="static" color="default"
                    style={
                        {
                            // backgroundImage: 'url(https://tabl.bg/img/white-shiny-background.jpg)',
                            // backgroundPosition: 'bottom',
                            // backgroundAttachment: 'fixed',
                            // backgroundSize: 'cover',
                            background: 'rgba(241, 241, 241, 0.85)'
                        }
                    }>
                    <Tabs
                        value={this.props.subcatIndex}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        // variant="fullWidth"
                        scrollButtons="on"
                        variant="scrollable"
                        aria-label="full width tabs example"
                        className={classes.tabsContainer}
                    >
                        {Object.values(this.props.subcats).map((subcategory, k) => (
                            <Tab key={subcategory.id} component="h2"
                                label={Utils.splitCatSubcatOnTwoRowsBySpace(subcategory.name, settings)}
                                icon={<img className={classes.categoryIcon} src={Utils.getCategoryImagePath(subcategory.icon)} />}
                                {...a11yProps(++k)} className={classes.categoryName}
                                data-class="each-category"
                            />
                        ))}
                    </Tabs>
                </AppBar>

                {/* 
                <div className={classes.headerInfoText}
                    dangerouslySetInnerHTML={{
                        __html: settings.all_products_view_text.value
                    }}>
                </div> 
                */}

                {/* <Promotions settings={settings} /> */}

                <SwipeableViews className={classes.swipeableViews}
                    data-class="swipeable-container"
                    index={this.props.subcatIndex}
                    onChangeIndex={this.handleChangeIndex}
                    enableMouseEvents
                    animateTransitions={false}
                >

                    {Object.values(this.props.subcats).map((subcategory, k) => (
                        <TabPanel key={k} value={this.props.subcatIndex} index={k}>

                            <Promotions settings={settings} translations={translations} />

                            <Grid
                                className={classes.productsContainer}
                                container
                                direction="row"
                                //   justify="space-between"
                                //   justify="center"
                                // alignItems="center"
                                spacing={3}
                            >
                                {this.props.filteredProds && this.props.filteredProds.map((product, k) => (
                                    <ProductListItem
                                        k={k}
                                        product={product}
                                        settings={settings}
                                        addProdToCart={this.addProdToCart}
                                        addToCartBtnClicked={this.addToCartBtnClicked}
                                        translations={translations}
                                        currLang={this.props.currLang}
                                    />
                                ))}
                            </Grid>

                            {settings.show_footer_in_products_page && settings.show_footer_in_products_page.value && settings.show_footer_in_products_page.value == 1 ?
                                <ProductsFooterContainer settings={settings} translations={translations} />
                                :
                                null
                            }

                        </TabPanel>

                    ))}

                </SwipeableViews>


                <ProductVariantsModal
                    open={this.state.variantModalOpen}
                    product={this.state.selectedProduct}
                    addProdToCart={this.addProdToCart}
                    closeProductVariantModalHandler={() => this.setState({ variantModalOpen: !this.state.variantModalOpen })}
                    settings={settings}
                    translations={translations}
                    currLang={this.props.currLang}
                />

            </div>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    // const {products, categories, translations } = this.props;
    let categories = state.categories.categories || [];
    let products = state.products || [];
    let subcatSlug = ownProps.match.params.subcatSlug !== "null" ? ownProps.match.params.subcatSlug : "";

    const ACTIVE_CAT = 0; //first found cat
    const subcats = (categories && Object.values(categories)[ACTIVE_CAT] && Object.values(categories)[ACTIVE_CAT].subcategories) ? Object.values(categories)[ACTIVE_CAT].subcategories : [];
    // const selectedSubCatId = subcats && Object.values(subcats)[this.state.selectedSubCategory] ? Object.values(subcats)[this.state.selectedSubCategory].id : 0;
    let selectedSubCatId = subcats && Object.values(subcats).filter(s => s.slug === subcatSlug)[0] ? Object.values(subcats).filter(s => s.slug === subcatSlug)[0].id : 0;

    if (selectedSubCatId == 0) {
        selectedSubCatId = subcats && Object.values(subcats)[0] ? Object.values(subcats)[0].id : 0
    }
    let subcatIndex = subcats && Object.values(subcats).findIndex(e => e.id === selectedSubCatId);

    if (subcatIndex === -1) subcatIndex = 0;

    const filteredProds = products && products.all_products && (Object.values(products.all_products).filter(p => p && p.subcategory && p.subcategory.id == selectedSubCatId));

    return {
        // isLoading: state.products.isLoading || false,
        products: state.products || [],
        categories: state.categories.categories,
        settings: state.settings.settings || [],
        translations: state.lang,
        currLang: state.lang.currLang,
        subcats: subcats,
        filteredProds: filteredProds,
        subcatIndex: subcatIndex,
        view_product_page_id: state.products.view_product_page_id || 0
    };
};

export default withStyles(styles)(connect(mapStateToProps, { getProductsBySubcat, showHeaderCategory, addProductTooltip, addToCart, changeSelectedCartTab, getAllProducts, getCategories, setMainBackground, removeProductPageId })(SubcatsWithProductsSluggedPage));