import React, { useState, useEffect, Component } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grow, CircularProgress, Container }
    from '@material-ui/core';

import clsx from 'clsx';
// import axios from 'axios';
import { withStyles } from '@material-ui/styles';
import { setLoginModalOpened, login, setRegisterModalOpened } from './../actions/users.actions';
import jwt from 'jsonwebtoken';
import Utils from './../../utils/Utils';
import setAuthorizationToken from '../../utils/setAuthorizationToken';
import { history } from '../../utils/history';

import LoginForm from './LoginForm';
import { styles } from './../styles/loginModal.css'
import MessagesContainer from './../../messages/MessagesContainer';

// import { makeStyles } from '@material-ui/core/styles';

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: ''
        }

        // this.handleChange = this.handleChange.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (!!this.state.email && !!this.state.password) {
            this.props.login(this.state)
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    goToRegister = () => {
        this.props.setLoginModalOpened(false);
        this.props.setRegisterModalOpened(true);
    }


    render() {
        const { email, password } = this.state;

        return (


            <React.Fragment>
                <MessagesContainer />

                <Dialog
                    // fullWidth={fullWidth}
                    // maxWidth={maxWidth}
                    open={true}
                    // onClose={() => this.props.setLoginModalOpened(false)}
                    aria-labelledby="max-width-dialog-title"
                    TransitionComponent={Grow}
                    data-class="dialog-container"

                >
                    {/* <CircularProgress className="is-loading language-modal" /> */}
                    <DialogTitle id="max-width-dialog-title"
                        style={{ textAlign: 'center', backgroundColor: "rgb(203,65,2)", color: 'white', boxShadow: '0 1px 5px 0px black' }}
                        // className={classes.modalTitle}
                        data-class="dialog-title-container">
                        {/* {translations.pages && translations.pages.choose_place ? translations.pages.choose_place.title : ''} */}
                        Влез в профила си
                         {/* на напитка */}
                    </DialogTitle>

                    <DialogContent
                        style={{ margin: 0 }}
                    // className={classes.dialogContent}
                    >
                        <Backdrop open={this.props.isLoadingLogin}
                            // className={classes.backdrop}
                            style={{
                                zIndex: 2,
                                color: '#fff',
                                position: 'absolute !important', //backdrop not on the full screen
                            }}
                        >
                            <CircularProgress className="is-loading" />
                        </Backdrop>

                        <DialogContentText
                        // className={classes.dialogContentText}
                        >

                            <div
                            // className={classes.isMultipleOptionsModalContainer}
                            >

                                <LoginForm
                                    email={this.state.username}
                                    password={this.state.password}
                                    handleChange={this.handleChange}
                                    onSubmit={this.onSubmit}
                                />

                                {/* <span onClick={this.goToRegister}>Нямаш профил? Регистрирай се</span> */}

                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button
                            className={classes.submitButton}
                            onClick={() => this.makeTheOrder()}
                            color="red"
                            data-class="initial-banner-fast-order-submit-button">
                            Поръчай
                        </Button> */}
                    </DialogActions>

                </Dialog>
                {/* </Grow> */}
            </React.Fragment>


        );
    }





    //     <Container >
    //     <MessagesContainer />

    //     <div className="col-md-12 col-md-offset-3 m0auto mtop50">
    //         <h2>Login</h2>
    //         <form name="form" onSubmit={this.onSubmit}>
    //             <div className={'form-group'}>
    //                 <label htmlFor="username">Username</label>
    //                 <input type="text" className="form-control" name="email" value={email} onChange={this.handleChange} />
    //             </div>
    //             <div className={'form-group'}>
    //                 <label htmlFor="password">Password</label>
    //                 <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
    //             </div>
    //             <div className="form-group">
    //                 <button className="btn btn-primary">Login</button>
    //             </div>
    //         </form>
    //     </div>

    // </Container>



    // render() {
    //     const { classes, fullWidth = true, maxWidth = 'xs', open, handleClose, translations, allProducts } = this.props;
    //     return (
    //         <React.Fragment>

    //             <Dialog
    //                 fullWidth={fullWidth}
    //                 maxWidth={maxWidth}
    //                 open={this.props.loginModalOpened}
    //                 onClose={() => this.props.setLoginModalOpened(false)}
    //                 aria-labelledby="max-width-dialog-title"
    //                 TransitionComponent={Grow}
    //                 data-class="dialog-container"

    //             >
    //                 {/* <CircularProgress className="is-loading language-modal" /> */}
    //                 <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} data-class="dialog-title-container">
    //                     {/* {translations.pages && translations.pages.choose_place ? translations.pages.choose_place.title : ''} */}
    //                     Влез в профила си
    //                      {/* на напитка */}
    //                 </DialogTitle>

    //                 <DialogContent
    //                 // className={classes.dialogContent}
    //                 >
    //                     <DialogContentText
    //                     // className={classes.dialogContentText}
    //                     >

    //                         <div className={classes.isMultipleOptionsModalContainer}>

    //                             <LoginForm 
    //                                 email={this.state.username}
    //                                 password={this.state.password}
    //                                 handleChange={this.handleChange}
    //                                 onSubmit={this.onSubmit}
    //                             />

    //                            <span onClick={this.goToRegister}>Нямаш профил? Регистрирай се</span>
    //                         </div>
    //                     </DialogContentText>
    //                 </DialogContent>
    //                 <DialogActions>
    //                     {/* <Button
    //                         className={classes.submitButton}
    //                         onClick={() => this.makeTheOrder()}
    //                         color="red"
    //                         data-class="initial-banner-fast-order-submit-button">
    //                         Поръчай
    //                     </Button> */}
    //                 </DialogActions>

    //             </Dialog>
    //             {/* </Grow> */}
    //         </React.Fragment>
    //     );
    // }






}


const mapStateToProps = (state, ownProps) => {
    // console.log("LOGI N")
    return {
        isLoadingLogin: state.users.isLoadingLogin || false,
        loginModalOpened: state.users.login_modal_opened
    };
};

export default withStyles(styles)(connect(mapStateToProps, { setLoginModalOpened, login, setRegisterModalOpened })(LoginPage))