import { GET_CATEGORIES, GET_SUBCATEGORIES, SET_CHOSEN_CAT, SHOW_CATEGORY, 
    SET_CATEGORIES_LOADED, GET_CATEGORIES_HIERARCHY, SET_HIERARCHY_LEVEL,
    SET_HEADER_CENTER_DATA, LOADING_HIERARCHY_START, LOADING_HIERARCHY_END } from '../actions/types';

const initialState = {
    categories: [],

    categories_hierarchy: [],
    curr_categories_hierarchy: [],
    selected_cat_hierarchy_id: 0,
    curr_hierarchy_level: 1,

    chosen_cat: 0,
    show_category: false,
    category_text: '',
    categories_loaded: 0,

    header_center_data: "",

    hierarchy_loading: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        case GET_CATEGORIES_HIERARCHY:
            return {
                ...state,
                categories_hierarchy: action.payload
            }
        case SET_HIERARCHY_LEVEL: 
            return {
                ...state,
                curr_hierarchy_level: action.payload
            }
        case GET_SUBCATEGORIES:
            return {
                ...state,
                subcategories: action.payload
            }
        case SET_CHOSEN_CAT:
            return {
                ...state,
                chosen_cat: action.payload
            }
        case SET_CATEGORIES_LOADED:
            return {
                ...state,
                categories_loaded: action.payload
            }
        case SHOW_CATEGORY:
            return {
                ...state,
                show_category: Boolean(action.payload),
                category_text: Boolean(action.payload) === true ? action.payload : state.category_text,
            }

            case LOADING_HIERARCHY_START:
                return {
                    ...state,
                    hierarchy_loading: true
                }

            case LOADING_HIERARCHY_END:
                return {
                    ...state,
                    hierarchy_loading: false
                }

        default:
            return state;
    }
}