import React, { useState, useEffect, Component } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grow, CircularProgress } 
        from '@material-ui/core';
import clsx from 'clsx';
// import axios from 'axios';
import { withStyles } from '@material-ui/styles';
import { setLoginModalOpened, login, setRegisterModalOpened } from './../actions/users.actions';
import jwt from 'jsonwebtoken';
import Utils from './../../utils/Utils';
import setAuthorizationToken from '../../utils/setAuthorizationToken';
import { history } from '../../utils/history';

import LoginForm from './LoginForm';
import { styles } from './../styles/loginModal.css'

// import { makeStyles } from '@material-ui/core/styles';

class RegisterModal extends Component {    
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: ''
        }

        // this.handleChange = this.handleChange.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    onSubmit = (e) => {
        e.preventDefault();

        // console.log(this.state);
        if (!!this.state.email && !!this.state.password) {
            this.props.login(this.state)
        }
    }
    
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    goToLogin = () => {
        this.props.setRegisterModalOpened(false);
        this.props.setLoginModalOpened(true);
        
    }
    
    render() {
        const { classes, fullWidth = true, maxWidth = 'xs', open, handleClose, translations, allProducts } = this.props;
        // console.log('allProducts', (Object.values(allProducts).filter(p => p.isLiquid == '1')).slice(0, 10));
        // console.log('this.state.selectedProducts', this.state.selectedProducts)
        return (
            <React.Fragment>

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={this.props.registerModalOpened}
                    onClose={() => this.props.setRegisterModalOpened(false)}
                    aria-labelledby="max-width-dialog-title"
                    TransitionComponent={Grow}
                    data-class="dialog-container"

                >
                    {/* <CircularProgress className="is-loading language-modal" /> */}
                    <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} data-class="dialog-title-container">
                        {/* {translations.pages && translations.pages.choose_place ? translations.pages.choose_place.title : ''} */}
                        Регистрирай се
                         {/* на напитка */}
                    </DialogTitle>

                    <DialogContent
                    // className={classes.dialogContent}
                    >
                        <DialogContentText
                        // className={classes.dialogContentText}
                        >

                            <div className={classes.isMultipleOptionsModalContainer}>

                                <LoginForm 
                                    email={this.state.username}
                                    password={this.state.password}
                                    handleChange={this.handleChange}
                                    onSubmit={this.onSubmit}
                                />
                               
                               <span onClick={this.goToLogin}>Вече имаш профил? Влез</span>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button
                            className={classes.submitButton}
                            onClick={() => this.makeTheOrder()}
                            color="red"
                            data-class="initial-banner-fast-order-submit-button">
                            Поръчай
                        </Button> */}
                    </DialogActions>

                </Dialog>
                {/* </Grow> */}
            </React.Fragment>
        );
    }






    }


const mapStateToProps = (state, ownProps) => {

    return {
        loginModalOpened: state.users.login_modal_opened,
        registerModalOpened: state.users.register_modal_opened,
    };
};

export default withStyles(styles)(connect(mapStateToProps, { setLoginModalOpened, login, setRegisterModalOpened })(RegisterModal)
)