import React, { Component, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
import CircularProgress from '@material-ui/core/CircularProgress';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import { setChosenPlace } from './../../../places/actions/places.actions';
import Utils from './../../../utils/Utils';
import { getAllProducts } from './../../../products/actions/products.action';
import { getCategoriesHierarchy } from './../../../categories/actions/categories.action';
import { setNewShopModalOpenedState, addShop } from './../../../shops/actions/shops.actions'
import clsx from 'clsx';
import { addErrorMessages } from './../../../messages/messages.actions';
import Auth from '../../../utils/AuthC';

const styles = theme => ({
    modal: {
        '& .MuiPaper-root': {
            width: '350px !important',
        }
    },
    modalTitle: {
        textAlign: 'center',
        backgroundColor: "rgb(203,65,2)",
        color: 'white',
        boxShadow: '0 1px 5px 0px black',
        '& .MuiTypography-root': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        }
    },
    dialogContent: {
        // minHeight: 100,
        'background-image': 'url(https://tabl.bg/img/white-shiny-background.jpg)',
        'background-position': 'center bottom',
        'background-attachment': 'fixed',
        'background-size': 'cover',
    },
    dialogContentText: {
        paddingTop: 10,
    },
    modalTypesBtns: {
        color: 'white',
        width: '100%',
        fontSize: 18,
        display: 'grid',
        // 'grid-template-rows': '50% 50%',
        'place-self': 'center',
        textAlign: 'center',
        // color: '#575757',
        borderRadius: 0,
        borderBottom: '1px solid #ff7600',
        backgroundColor: '#f9f9f9',
        minWidth: 180,
        '@media screen and (max-width: 475px)': {
            minWidth: '80%',
        },

        'border': '1px solid #ebebeb',

        // 'border': '1px solid #ff5200',
        // 'border-top-right-radius': 7,
        // 'border-top-left-radius': 7,

        'border-top-left-radius': 20,
        'border-bottom-right-radius': 20,

        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: 'none',

        height: 80,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    modalTypesBtnsBorderBottom: {
        'border-bottom': '1px solid #ff5200 !important',
    },
    modalTypesIcons: {
        fontSize: 42,
        color: '#ff7600',
    },
    isMultipleOptionsModalContainer: {
        margin: '20px 0 20px 0',
        display: 'grid',
        // 'grid-template-columns': '50% 50%',
        'place-self': 'center',
        textAlign: 'center',
        padding: 0,
        // '@media screen and (max-width: 475px)': {
        //     'grid-template-columns': 'none',
        //     // '& > *:first-child': {
        //     //     marginBottom: 40,
        //     // },
        //     margin: '20px 0 20px 0',
        // }
    },
    textField: {
        width: '100%',
        '& label.Mui-focused': {
            color: 'rgba(254, 105, 2, 0.7)',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'rgb(254, 105, 2)',
        },
    },

});

class ChoosePlaceModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            name: '',
            address: '',
            deliveryAddress: '',
            numberInDistributorSystem: '',
            eik: '',
            regionId: 0
        }
    }

    componentWillReceiveProps(nextProps) {

    }

    validate() {
        let errors = [];
        const { translations } = this.props;

        if (this.state.name.length === 0) {
            errors.push(this.props.translations.messages.errors.invalid_name);
            return errors;
        }
        if (this.state.address.length === 0) {
            errors.push(this.props.translations.messages.errors.invalid_address);
            return errors;
        }
        if (this.state.numberInDistributorSystem.length === 0) {
            errors.push(this.props.translations.messages.errors.invalid_number_in_distributor_system);
            return errors;
        }
        if (this.state.eik.length === 0) {
            errors.push(this.props.translations.messages.errors.invalid_eik);
            return errors;
        }
        if (this.state.deliveryAddress.length === 0) {
            errors.push(this.props.translations.messages.errors.invalid_delivery_address);
            return errors;
        }
        // console.log('this.state.regionId', this.state.regionId)
        if (!this.state.regionId || this.state.regionId == 0) {
            errors.push(this.props.translations.messages.errors.invalid_region);
            return errors;
        }
        return errors;
    }


    addShop = () => {
        // debugger;
        const errors = this.validate();
        if (errors.length > 0) {
            this.props.addErrorMessages(errors);
            return;
        }

        const post = {
            name: this.state.name,
            address: this.state.address,
            deliveryAddress: this.state.deliveryAddress,
            numberInDistributorSystem: this.state.numberInDistributorSystem,
            eik: this.state.eik,
            regionId: this.state.regionId,
            userId: Auth.getUserFromToken().id
        }

        this.props.addShop(post);
        this.props.setNewShopModalOpenedState(false)
    }

    render() {
        const { classes, fullWidth = true, maxWidth = 'xs', open, handleClose, translations } = this.props;
        // console.log(this.state);
        return (
            <React.Fragment>

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={this.props.showModalOpened}
                    onClose={() => this.props.setNewShopModalOpenedState(false)}
                    aria-labelledby="max-width-dialog-title"
                    TransitionComponent={Grow}
                    // className={classes.modal}
                    data-class="dialog-container"
                >
                    {/* <CircularProgress className="is-loading language-modal" /> */}
                    <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} data-class="dialog-title-container">
                        {/* {translations.pages && translations.pages.choose_place && translations.pages.choose_place.title ? translations.pages.choose_place.title : ''} */}
                        Добави нов обект
                    </DialogTitle>

                    <DialogContent
                        className={classes.dialogContent}
                    >
                        <DialogContentText
                        // className={classes.dialogContentText}
                        >

                            <form
                            // onSubmit={props.onSubmit}
                            >
                                <div style={{ marginBottom: 10 }}></div>

                                <TextField className={classes.textField}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    name="name" label="Име" required style={{ width: '100%' }} />

                                <br /><br />

                                <TextField className={classes.textField}
                                    onChange={(e) => this.setState({ address: e.target.value })}
                                    name="address" label="Адрес" required style={{ width: '100%' }} />
                                <br /><br />

                                <TextField className={classes.textField}
                                    onChange={(e) => this.setState({ numberInDistributorSystem: e.target.value })}
                                    name="numberInDistributorSystem" label="Номер в системата на дистрибутора" required style={{ width: '100%' }} />
                                <br /><br />

                                <TextField className={classes.textField}
                                    onChange={(e) => this.setState({ eik: e.target.value })}
                                    name="eik" label="Булстат" required style={{ width: '100%' }} />

                                <br /><br />

                                <TextField className={classes.textField}
                                    onChange={(e) => this.setState({ deliveryAddress: e.target.value })}
                                    name="deliveryAddress" label="Адрес за доставка (различен от адреса)" required style={{ width: '100%' }} />

                                <br /><br />

                                {/* onChangeSelectRegions = (valueLabel) => {
                                    this.setState({ 'currRegions': valueLabel });
                                } */}
                                <FormControl
                                    style={{ width: '100%' }}
                                // className={classes.formControl}
                                >
                                    <InputLabel id="addshop-regionid">Регион</InputLabel>
                                    <Select
                                        className={classes.textField}
                                        title={""}
                                        labelId="addshop-regionid"
                                        // id="demo-simple-select-autowidth"
                                        name="regionId"
                                        // value={this.state.regionId}
                                        // onChange={handleChange}
                                        autoWidth
                                        onChange={(e) => this.setState({ regionId: e.target.value })}
                                    >
                                        <MenuItem value="0">-</MenuItem>
                                        {Object.values(this.props.regions).map(r => (
                                            <MenuItem value={r.id}>{r.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>



                                {/* <select name="regionId" className={classes.textField} required style={{ width: '100%' }}
                                    onChange={(e) => this.setState({ regionId: e.target.value })}
                                >
                                    <option value={0}>-</option>
                                </select>
                                */}

                                <br /><br />

                                <Button variant="contained" style={{ color: 'rgb(203,65,2)', width: '100%', boxShadow: 'none' }}
                                    onClick={this.addShop}
                                >
                                    Добави
                            </Button>

                            </form>
                        </DialogContentText>
                    </DialogContent>

                </Dialog>
                {/* </Grow> */}
            </React.Fragment>
        );
    }

}


const mapStateToProps = (state, ownProps) => {
    return {
        regions: state.regions.regions || [],
        // chosen_place: state.places.chosen_place || null,
        translations: state.lang.translations,
        showModalOpened: state.shops.showModalOpened || false,
    };
};

export default withStyles(styles)(connect(mapStateToProps, { setChosenPlace, getAllProducts, getCategoriesHierarchy, setNewShopModalOpenedState, addErrorMessages, addShop })(ChoosePlaceModal)
)

