export const bgTranslations = {
    navbar: {
        restaurant_location: "Упътване до ресторанта",
        make_reservations: "Направи резервация",
        search_product: "Търсене на продукт",
        call_waiter: "Повикай сервитьор",
        change_language: "Смяна на език",
        feedback: "Оцени ни",
        about_tabl: "За TABL",
        orders: "Поръчки",
        profile: "Моят профил",
        logout: "Изход",
    },

    pages: {
        products: {
            choose_variant: "Моля изберете",
            alergens: "Алергени",
            out_of_stock: "Неналичен",
            variant_not_choosed: "Не сте избрали вариант!",
            additional_options: "Допълнителни опции",
            addable_options: "Добави",
            removable_options: "Премахни",
            comment: "Коментар към продукта",
            related_products: "Други предложения",
            add: "Добави",
            add_to_cart: "Добави в количката", // in context 'add to cart'
            choose_size: "Изберете размер",
        },
        change_language: {
            title: "Моля изберете език",
        },
        call_waiter: {
            clean_table: "Моля почистете масата",
            new_cutlery: "Моля за нови прибори",
            more_napkins: "Моля за повече салфетки",
            change_ashtray: "Моля за смяна на пепелник",
            bring_ice: "Моля донесете лед",
            other: "Друго",
        },
        feedback: {
            title: "Отделете минутка, за да ни оцените",
            customer_reviews: "Мнения на клиенти",
            no_reviews_yet: "Бъди един от първите, оценили тази услуга."
        },
        about_tabl: {
            title: "Тази услуга достига до Вас благодарение на ",
            sub_title: "При необходимост от повече информация, въпроси или препоръки, моля свържете се с нас.",
            about_link: "https://tabl.bg/za-nas"
        },
        cart_delivery: {
            // you_can_order_between: "Може да поръчате от {from} до {to}",
            call_waiter_to_order: "Моля извикайте сервитьора, за да поръчате",
            you_can_order_between: "Може да поръчате от {from} до {to} и от {from_two} до {to_two}",
            restaurant_closed_label_tommorow: "В момента не приемаме поръчки, моля опитайте отново утре в {value}",
            restaurant_closed_label_today: "В момента не приемаме поръчки, моля опитайте отново по-късно в {value}",
            delivery_price: "Цена на доставка",
            delivery_price_is_not_included: "+ цена на доставка",
            cart: "Количка",
            no_cart_items: "Няма добавени продукти в количката",
            orders: "Поръчки",
            order: "Поръчка",
            min_delivery_price_text: 'При поръчки над {value} доставката е безплатна',
            no_order_items: "Нямате активни поръчки към момента",
            total: "Цена",
            total_bill: "Всичко",
            status_accepted: "Приета",
            status_preparing: "Приготвя се",
            status_finished: "Завършена",
            pay_in_cash: "Плати в брой",
            pay_with_card: "Плати с карта",
            make_order: "Поръчай",
            want_the_bill: "Поискай сметката",
            finish_bill: "Приключи сметката",
            make_delivery_order: "Поръчай с доставка",
            choose_order_type: "Изберете начин на поръчка",
            choose_payment_type: "Изберете начин на плащане",
            payment_cash: "В брой",
            payment_card: "С карта",
            delivery_price_included_in_total_price: "С включена цена на доставка {delivery_price_num} за поръчки до {min_price_free_delivery_num}",
            order_type_restaurant: "В ресторанта",
            scan_barcode: "Сканирай баркода",
            order_type_delivery: "Доставка до вкъщи",
            order_type_preorder: "Вземи от обекта",
            first_and_last_name: "Име и Фамилия",
            phone: "Тел. номер",
            order_address: "Адрес: ул. № ет. ап. гр.",
            delivery_time: "Дата и час за получаване",
            delivery_address: "Адрес за получаване",
            email: "Имейл",
            remember_me: "Запомни ме",
            promo_code: "Промокод",
            promo_code_placeholder: "Промокод...",
            apply: "Приложи",
            cancel: "Откажи",
            bill_already_wanted: "/сметката ви е поискана/",
            clear: "Изчисти",
            close: "Затвори",
            fast_order: "Fast order", // NOT TRANSLATED
            save: "Запази",
        },
        search: {
            searchProduct: "Търси продукт",
            noSearchResults: "Няма намерени продукти",
            noSearchDescription: "Моля променете критерийте, за да намерите подходящия за Вас продукт!",
        },
        choose_place: {
            title: "Моля, изберете локация"
        }
    },

    common: {
        no_internet: "Липсва връзка с интернет.",
        count: 'бр.',
        box: "кутия",
        like_us: "Харесай ни",
        follow_us: "Последвай ни",
        youtube: "YouTube",
        tipadvisor: "Tripadvisor",
        about: 'За',
        about_us: 'За нас',
        discount: 'Отстъпка',
        delivery: 'Доставка',
        book: 'Резервирай',
        language: 'Език',
        navigate_me: "Заведи ме",
        navigation: 'Навигация',
        search: 'Търси',
        call_us: 'Обади се',
        product: "продукт",
        products: "продукта",
        user: "Потребител",
        comment: "Коментар",
        contacts: "Контакти",
        working_hours: "Работно време",
        payment_methods: "Начин на плащане",
        gallery: "Галерия",
        change_table: "Смяна на маса",
        date: {
            today: "Днес",
            tommorow: "Утре",
        },

        action: {
            send: "Изпрати",
            skip: "Пропусни",
            back: "Назад",
        },

        label: {
            describe_here: "Опишете тук",
        }
    },

    messages: {
        notifications: {
            waiter_notified_successfully: 'Сервитьора е нотифициран успешно',
            order_sent_success: "Поръчката ви е приета успешно.",
            feedback_thanks: "Благодарим за отделеното време!",
            bill_wanted_success: 'Вашата сметка е поискана успешно.',
            user_need_success: 'Сервитьора беше успешно известен.',
            create_order_first: "Необходимо е първо да направите поръчка",
            bill_already_wanted: "Вашата сметка е вече поискана",
        },
        errors: {
            invalid_name: 'Невалидно име',
            invalid_address: 'Невалиден адрес',
            invalid_number_in_distributor_system: 'Невалиден номер в системата на дистриб.',
            invalid_eik: 'Невалиден булстат',
            invalid_delivery_address: 'Невалиден адрес на доставка',
            invalid_region: 'Невалиден регион',
            invalid_fields: "Моля попълнете всички полета",
            order_sent_fail: "Mоля сканирайте отново!",
            bill_wanted_fail: "Упс, сметката не може да бъде поискана!",
            user_need_fail: 'Упс, възникна грешка при известяването на сервитьора.',
        }
    },

}