import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ProductListItem from "./ProductListItem";
import ProductVariantsModal from "../../common/modals/ProductVariant";
import { getProductsBySubcat, addProductTooltip, addToCart, changeSelectedCartTab, setOrderComment } from '../actions/products.action';
import { showHeaderCategory } from '../../categories/actions/categories.action';
import Page404 from '../../common/errors/404'
import { DEMO } from '../../constants';
import MetaAndOg from '../../common/MetaAndOg'
import Utils from "../../utils/Utils";

const mainDesign = window.localStorage.getItem('design');
const secondaryDesign = window.localStorage.getItem('design');

// if (!mainDesign || !secondaryDesign) {
//     window.localStorage.setItem('design', JSON.stringify({ main: 3, secondary: 3 })); // Set default 
// }

const designVersion = mainDesign ? parseInt(JSON.parse(mainDesign).main) : 3;
const designSubVersion = secondaryDesign ? parseInt(JSON.parse(secondaryDesign).secondary) : 3;


const styles = theme => ({
    designVariantsContainer: {
        position: 'fixed',
        right: 0,
        top: '35%',
        zIndex: 5,
        maxWidth: 65,
        background: '#cecece',
        background: '#cecece96',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
    },
    designVariantsTitle: {
        textAlign: 'center',
        fontSize: 17,
        marginBottom: 10,
        borderBottom: '1px solid #ccc',
        marginBottom: 5,
        padding: 10,
    },
    productsContainer: {
        WebkitOverflowScrolling: 'touch',
        width: '90%',
        margin: '0 auto',
        paddingTop: 65,
        paddingBottom: 70, // temp unable to hive navbar fix
    },
    mainCollapseButton: {
        fontWeight: 'bold',
        borderRadius: 0,
    },
    collapsedButton: {
        borderRadius: 0,
    },
    activeCollapsedButton: {
        background: '#f1f1f1a1',
    },
    firstCollapsedButton: {
        borderRadius: 0,
        borderTop: '1px solid #b8b8b8',
    },
    lastCollapsedButton: {
        borderRadius: 0,
        borderBottom: '1px solid #b8b8b8',
    },
    closeDesignThemesButton: {
        borderTop: '1px solid #ccc',
        borderRadius: 0,
    }
});

class ProductsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            variantModalOpen: false,
            variantModalWithComment: false,
            selectedProduct: null,
            timeout: null,
            designVariantButtonsOpened: false,
            useDesign: designVersion,
            useSubDesign: designSubVersion,
            openedDesign1: window.localStorage.getItem('design') && designVersion === 1,
            openedDesign2: window.localStorage.getItem('design') && designVersion === 2,
            openedDesign3: window.localStorage.getItem('design') && designVersion === 3,
        }
    }

    componentDidMount() {
        const { subcategory, subcatId, categoriesLoaded } = this.props;
        // If prod not in state - get it (on page refresh)
        // if (!subcategory && categoriesLoaded == 0) {
        this.props.getProductsBySubcat(subcatId);
        // }

        setTimeout(() => this.setState({ designVariantButtonsOpened: true }), 1000);
    }

    // Opens variant modal or adds prod to cart
    addToCartBtnClicked = (id, productVariantId = 0) => {
        const currProduct = this.props.subcategory.products.filter(p => p.id == id)[0];
        // const currProduct = products.filter(p => p.id === id)[0];
// console.log('prod', id)
        if (this.props.settings && this.props.settings.variant_modal_with_comment && this.props.settings.variant_modal_with_comment.value == 1) {
            this.setState({ variantModalOpen: true, selectedProduct: currProduct, variantModalWithComment: true })
        } else {
            if (currProduct.product_variants) {
                if (currProduct.product_variants.length === 1 || productVariantId > 0) {
                    this.addProdToCart(currProduct.id, productVariantId ? productVariantId : currProduct.product_variants[0].id, currProduct)
                } else {
                    // Open variant modal
                    this.setState({ variantModalOpen: true, selectedProduct: currProduct })
                }
            }
        }

    }

    // Add variant to cart
    addProdToCart = (product_id, product_variant_id, product) => {
        if (this.state.variantModalOpen) {
            this.setState({ variantModalOpen: false });
        }

        const requiredProductIngridients = product.product_ingredients.filter(pi => pi.type == 'required');

        let defautRequiredProductIngridient = 0;
        if (requiredProductIngridients && requiredProductIngridients[0] && requiredProductIngridients[0].ingredientId) {
            defautRequiredProductIngridient = requiredProductIngridients[0].ingredientId;
        }

        const selectedIngredients = {
            required: defautRequiredProductIngridient,
            addable: [],
            removable: [],
        }

        this.showAddedProductTooltip(product_id, product_variant_id, product, selectedIngredients);
        this.props.changeSelectedCartTab(0); // select default tab "Cart"
        this.props.addToCart(product_id, product_variant_id, product, selectedIngredients);
    };


    // Shows tooltip
    showAddedProductTooltip = (id, product_variant_id, product, selectedIngredients) => {
        let totalProdQuantity = 1;
        // if (this.props.products && this.props.products.to_order && Object.keys(this.props.products.to_order).length > 0) {
        //     const addedProductVariant = this.props.products.to_order.find(pv => pv.product_variant_id === product_variant_id);
        //     if (addedProductVariant) {
        //         totalProdQuantity = parseInt(addedProductVariant.quantity) + 1;
        //     }
        // }

        if (this.props.products && this.props.products.to_order && this.props.products.to_order[0] && this.props.products.to_order[0].length > 0) {
            totalProdQuantity = Utils.getCartProductQuantity(product_variant_id, selectedIngredients);
        }

        let choosedProductVariant = product.product_variants.filter(pv => pv.id === product_variant_id)[0];
        let tooltipText = totalProdQuantity + ' x ' + product.name + (choosedProductVariant.size && choosedProductVariant.size != ' ' ? ' - /' + choosedProductVariant.size + '/' : '');

        // Add to cart tooltip
        const addProdTooltip = (id, tooltipText) => this.props.addProductTooltip(id, tooltipText);
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
            this.setState({ timeout: null });
        }
        addProdTooltip(id, tooltipText);
        let timeOut = setTimeout(function () { addProdTooltip(0, tooltipText); }, 2500);
        this.setState({ timeout: timeOut });
    }

    designVariantButtons = () => {
        const { classes } = this.props;

        {/* //No translation needed */ }
        const content = (
            <Slide direction="left" in={this.state.designVariantButtonsOpened} mountOnEnter unmountOnExit>
                <div className={classes.designVariantsContainer}>
                    <div className={classes.designVariantsTitle}>Теми</div>

                    <Button className={classes.mainCollapseButton} onClick={() => this.setState({ openedDesign1: !this.state.openedDesign1, openedDesign2: false, openedDesign3: false })}>
                        Бар
                    </Button>

                    <Collapse in={this.state.openedDesign1} timeout="auto" unmountOnExit>
                        <Button className={this.state.useDesign == 1 && this.state.useSubDesign == 2 ? clsx(classes.firstCollapsedButton, classes.activeCollapsedButton) : classes.firstCollapsedButton} onClick={() => {
                            this.setState({ useDesign: 1, useSubDesign: 2 });
                            window.localStorage.setItem('design', JSON.stringify({ main: 1, secondary: 2 }));
                            window.location.reload(false);
                        }}>
                            art
                        </Button>
                        <Button className={this.state.useDesign == 1 && this.state.useSubDesign == 1 ? clsx(classes.lastCollapsedButton, classes.activeCollapsedButton) : classes.lastCollapsedButton} onClick={() => {
                            this.setState({ useDesign: 1, useSubDesign: 1 });
                            window.localStorage.setItem('design', JSON.stringify({ main: 1, secondary: 1 }));
                            window.location.reload(false);
                        }}>
                            light
                        </Button>
                    </Collapse>


                    <Button className={classes.mainCollapseButton} onClick={() => this.setState({ openedDesign1: false, openedDesign2: !this.state.openedDesign2, openedDesign3: false })}>
                        Рест.
                    </Button>

                    <Collapse in={this.state.openedDesign2} timeout="auto" unmountOnExit>
                        <Button className={this.state.useDesign == 2 && this.state.useSubDesign == 1 ? clsx(classes.firstCollapsedButton, classes.activeCollapsedButton) : classes.firstCollapsedButton} onClick={() => {
                            this.setState({ useDesign: 2, useSubDesign: 1 });
                            window.localStorage.setItem('design', JSON.stringify({ main: 2, secondary: 1 }));
                            window.location.reload(false);
                        }}>
                            art
                        </Button>
                        <Button className={this.state.useDesign == 2 && this.state.useSubDesign == 2 ? clsx(classes.lastCollapsedButton, classes.activeCollapsedButton) : classes.lastCollapsedButton} onClick={() => {
                            this.setState({ useDesign: 2, useSubDesign: 2 });
                            window.localStorage.setItem('design', JSON.stringify({ main: 2, secondary: 2 }));
                            window.location.reload(false);
                        }}>
                            light
                        </Button>
                    </Collapse>


                    <Button className={classes.mainCollapseButton} onClick={() => this.setState({ openedDesign1: false, openedDesign2: false, openedDesign3: !this.state.openedDesign3 })}>
                        Хотел
                    </Button>

                    <Collapse in={this.state.openedDesign3} timeout="auto" unmountOnExit>
                        <Button className={this.state.useDesign == 3 && this.state.useSubDesign == 1 ? clsx(classes.firstCollapsedButton, classes.activeCollapsedButton) : classes.firstCollapsedButton} onClick={() => {
                            this.setState({ useDesign: 3, useSubDesign: 1 });
                            window.localStorage.setItem('design', JSON.stringify({ main: 3, secondary: 1 }));
                            window.location.reload(false);
                        }}>
                            art
                        </Button>

                        <Button className={this.state.useDesign == 3 && this.state.useSubDesign == 3 ? clsx(classes.collapsedButton, classes.activeCollapsedButton) : classes.collapsedButton} onClick={() => {
                            this.setState({ useDesign: 3, useSubDesign: 3 });
                            window.localStorage.setItem('design', JSON.stringify({ main: 3, secondary: 3 }));
                            window.location.reload(false);
                        }}>
                            new
                        </Button>

                        <Button className={this.state.useDesign == 3 && this.state.useSubDesign == 2 ? clsx(classes.lastCollapsedButton, classes.activeCollapsedButton) : classes.lastCollapsedButton} onClick={() => {
                            this.setState({ useDesign: 3, useSubDesign: 2 });
                            window.localStorage.setItem('design', JSON.stringify({ main: 3, secondary: 2 }));
                            window.location.reload(false);
                        }}>
                            light
                        </Button>

                    </Collapse>

                    <Button className={classes.closeDesignThemesButton} onClick={() => {
                        this.setState({ designVariantButtonsOpened: false });
                        setTimeout(() => window.localStorage.setItem('hide_designes', 1), 1000);
                    }}
                    >
                        <ChevronRightIcon />
                    </Button>

                </div >
            </Slide >
        )

        return (DEMO == false || localStorage.getItem('hide_designes') == 1) ? null : content;
    }

    render() {
        const { classes, subcategory, subcatId, settings, translations } = this.props;
        let subcategoryName = '';
        let subcategoryIcon = '';
        if (subcategory && subcategory.id && parseInt(subcategory.id) === parseInt(subcatId)) {
            // subcategoryName = subcategory.name ? subcategory.name : null;
            subcategoryName = subcategory.translations[this.props.currLang]
                && subcategory.translations[this.props.currLang].name
                ? subcategory.translations[this.props.currLang].name
                : subcategory.name ? subcategory.name : null;

            subcategoryIcon = subcategory.icon ? subcategory.icon : null;
            this.props.showHeaderCategory(subcategoryName);
        } else {
            if (typeof subcategory === "undefined" && this.props.isLoading == false) {
                return <Page404 />; // Not needed - see Router.js
            }
            return null; //Loading
        }

        return (
            <React.Fragment>

                <MetaAndOg subcategory={subcategoryName} image={Utils.getSubcategoryImagePath(subcategoryIcon)} />

                {this.designVariantButtons()}

                <Grid
                    className={classes.productsContainer}
                    container
                    direction="row"
                    //   justify="space-between"
                    //   justify="center"
                    // alignItems="center"
                    spacing={3}
                >

                    {subcategory && subcategory.products ?
                        (Object.values(subcategory.products).map((product, k) => (
                            <ProductListItem
                                key={k}
                                k={k}
                                product={product}
                                settings={settings}
                                addProdToCart={this.addProdToCart}
                                addToCartBtnClicked={this.addToCartBtnClicked}
                                translations={translations}
                                currLang={this.props.currLang}
                            />
                        )))
                        :
                        ''
                    }

                </Grid>

                <ProductVariantsModal
                    open={this.state.variantModalOpen}
                    variantModalWithComment={this.state.variantModalWithComment}
                    product={this.state.selectedProduct}
                    addProdToCart={this.addProdToCart}
                    product_variants_comment={this.props.products.product_variants_comment}
                    setOrderComment={this.props.setOrderComment}
                    closeProductVariantModalHandler={() => this.setState({ variantModalOpen: !this.state.variantModalOpen })}
                    settings={settings}
                    translations={translations}
                    currLang={this.props.currLang}
                />

            </React.Fragment>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    const subcatId = ownProps.match.params.subcat_id;
    return {
        subcatId: subcatId,
        // subcategory: state.products.subcat_products && state.products.subcat_products ? state.products.subcat_products.filter(sub => sub && sub[0] && sub[0].id && sub[0].id == subcatId)[0] : [],
        subcategory: state.products.subcat_products || null,
        isLoading: state.products.isLoading || false,
        products: state.products || [],
        categoriesLoaded: state.categories.categories_loaded || 0,
        settings: state.settings.settings || [],
        translations: state.lang,
        currLang: state.lang.currLang

    };
};

export default withStyles(styles)(connect(mapStateToProps, { getProductsBySubcat, showHeaderCategory, addProductTooltip, addToCart, changeSelectedCartTab, setOrderComment })(ProductsPage));