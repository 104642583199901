import Cookies from 'universal-cookie';
import jwt from 'jsonwebtoken';
const cookies = new Cookies();
const TOKEN_NAME = 'si7_token'

class Auth {


    static setToken(token) {
        cookies.set(TOKEN_NAME, token, { path: '/' });
    }
    static getToken () {
        return cookies.get(TOKEN_NAME)
    }
    static removeToken () {
        cookies.remove(TOKEN_NAME)
    }
    static getUserFromToken() {
      let token = this.getToken();
      
      if(token) {
        return jwt.decode(token);
      } else {
        return null;
      }
    }



    static saveUser (user) {
      window.localStorage.setItem('menuUser', JSON.stringify(user))
    }
    static getUser () {
      const userJSON = window.localStorage.getItem('menuUser')
      if (userJSON) {
        return JSON.parse(userJSON)
      }
      // console.log('nqma user')
      return {}
    }
    static removeUser () {
      window.localStorage.removeItem('menuUser')
    }
    static authenticateUser (token) {
      window.localStorage.setItem('token', token)
    }
    static isUserAuthenticated () {
      return window.localStorage.getItem('menuToken') !== null
    }
    static deauthenticateUser () {
      window.localStorage.removeItem('token')
    }

    
  
    static test() {
    }
  }
  
  export default Auth
  