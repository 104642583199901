import axios from 'axios';
import { GET_SETTINGS, SET_BACKGROUND_IMAGE } from './types';
import { API_URL } from '../../constants'

export const getSettings = () => dispatch => {
    axios
        .get(API_URL + 'settings')
        .then(res =>
            dispatch({
                type: GET_SETTINGS,
                payload: res.data
            })

        ).catch(error => {
            throw (error);
        });
}

export const setMainBackground = (p) => dispatch => {
    dispatch({
        type: SET_BACKGROUND_IMAGE,
        payload: p
    })
}