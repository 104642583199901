import React, { Component } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles, withTheme } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import WarningIcon from '@material-ui/icons/Warning';
import SwipeableViews from 'react-swipeable-views';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SettingsIcon from '@material-ui/icons/Settings';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
// import StepConnector from '@material-ui/core/StepConnector';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CachedIcon from '@material-ui/icons/Cached';
import InfoIcon from '@material-ui/icons/Info';
import RateReviewIcon from '@material-ui/icons/RateReview';
import AddCommentIcon from '@material-ui/icons/AddComment';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { changeSelectedCartTab, setOrderComment, removeUserTokenAndOrder, setCommentModalData, setDisabledMakeOrderButton } from '../../products/actions/products.action';
import { setDeliveryModalState } from '../../qr-scan/actions/scan.actions'
import { DEFAULT_PRODUCT_IMAGE } from '../../constants'
import CartCommentModal from './CartCommentModal';
import PaymentTypeModal from './PaymentModal';
import Utils from '../../utils/Utils';

import { styles, useColorlibStepIconStyles, ColorlibConnector } from './styles/cardModal.styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CartModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comments: Object.values(this.props.products.product_variants_comment) || [],
            openCommentModal: false,
            commentModalTitle: '',
            commentVariantId: '',
            // openPaymentTypeModal: false,
            totalCartPriceWhenWithDeliveryTooltipOpened: false,
            // disabledMakeOrderButton: false,
        }
    }

    productVariantCounts = (selectedQuantity) => {
        let menuItems = [];
        let maxShowQuantity = 100;
        maxShowQuantity = Math.max(maxShowQuantity, selectedQuantity)

        for (let i = 1; i <= maxShowQuantity; i++) {
            menuItems.push(< MenuItem value={i}>{i}</MenuItem >)
        }

        return menuItems;
    }

    componentDidUpdate() {
        const { orderSent, orderMessage } = this.props;
        if (this.swipeableActions) this.swipeableActions.updateHeight();
        // if (orderSent == 'true' && this.props.deliveryModalState == 1 ) {
        //     this.props.setOrderComment([]);
        //     this.props.setDeliveryModalState(0);
        // }
        if (orderSent == 'true') {
            this.props.setOrderComment([]);
            // this.props.setDeliveryModalState(0);
            // this.setState({ disabledMakeOrderButton: true })
            this.props.setDisabledMakeOrderButton(false);
        }

    }

    ColorlibStepIcon(props) {
        const classes = useColorlibStepIconStyles();
        const { active, completed } = props;

        const icons = {
            1: <SettingsIcon />,
            2: <CachedIcon />,
            3: <DoneAllIcon />,
        };

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })
                }
            >
                {icons[String(props.icon)]}
            </div >
        );
    }



    finish_bill = () => {
        this.props.removeUserTokenAndOrder();
        this.props.onClose();
    }

    callMakeOrder = (eachCartItem, totalCartPrice) => {
        const { settings, onSubmit, onError, translations } = this.props;
        const disabledOrdering = Utils.disabledOrdering(settings);

        if (this.props.chosenPlaceOrderTypes.length == 0) {
            onError(translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.call_waiter_to_order ? translations.pages.cart_delivery.call_waiter_to_order : '');
        } else if (disabledOrdering && this.props.chosenPlaceOrderTypes.length == 1) {
            onError(Utils.getDisabledOrderingMessage(translations, settings));
        } else {
            if (eachCartItem.length > 0) {
                onSubmit(totalCartPrice)
            }
        }


    }

    getIngredientNamesByIds = (ingredientIds) => {
        const { productIngredients } = this.props;
        // console.log('checking for: ', ingredientIds)
        let ingredientsNames = '';
        if (productIngredients && Object.values(productIngredients).length) {
            // debugger;
            if (Array.isArray(ingredientIds)) {
                // console.log('arr')
                Object.values(ingredientIds).map((i, k) => {
                    // console.log('map: ', i, ' ', k)

                    if (productIngredients[i] && productIngredients[i].name) {
                        if (k != 0) {
                            ingredientsNames += ', ';
                        }
                        ingredientsNames += productIngredients[i].name;
                    }
                    // console.log('ingredientsNames: ', ingredientsNames)
                })
            } else {
                // console.log('elsa')
                if (productIngredients && productIngredients[parseInt(ingredientIds)] && productIngredients[parseInt(ingredientIds)].name) {
                    ingredientsNames += productIngredients[parseInt(ingredientIds)].name;
                }
                // console.log('ingredientsNames :', ingredientsNames)
            }
        }

        return ingredientsNames;
    }

    addIngredientsPrice = (item) => {
        let ingredientsPrice = 0;
        // console.log('item INGR', item)
        // console.log('this.props.productIngredients', this.props.productIngredients)
        // console.log('parseInt(item.ingredients.required)', parseInt(item.ingredients.required))
        // console.log('item.product_ingredients', item.product_ingredients)
        // console.log('productIngredients', productIngredients)
        if (item && item.ingredients && item.product_ingredients && Object.values(item.product_ingredients).length > 0 && this.props.productIngredients) {
            if (item.ingredients.required) {
                if (this.props.productIngredients && this.props.productIngredients[parseInt(item.ingredients.required)] && this.props.productIngredients[parseInt(item.ingredients.required)].price) {
                    // console.log('adding: this.props.productIngredients[parseInt(item.ingredients.required)].price', this.props.productIngredients[parseInt(item.ingredients.required)].price)
                    ingredientsPrice += parseFloat(this.props.productIngredients[parseInt(item.ingredients.required)].price);
                }
            }

            if (item.ingredients.addable) {
                Object.values(item.ingredients.addable).map(ingrId => {
                    if (this.props.productIngredients && this.props.productIngredients[parseInt(ingrId)] && this.props.productIngredients[parseInt(ingrId)].price) {
                        // console.log('adding: this.props.productIngredients[parseInt(ingrId)].price', this.props.productIngredients[parseInt(ingrId)].price)
                        ingredientsPrice += parseFloat(this.props.productIngredients[parseInt(ingrId)].price);
                    }
                })
            }

            // if (item.ingredients.removable) {
            //     Object.values(item.ingredients.removable).map(ingrId => {
            //         if (this.props.productIngredients && this.props.productIngredients[parseInt(ingrId)] && this.props.productIngredients[parseInt(ingrId)].price) {
            //             console.log('adding: this.props.productIngredients[parseInt(ingrId)].price', this.props.productIngredients[parseInt(ingrId)].price)
            //             ingredientsPrice += parseFloat(this.props.productIngredients[parseInt(ingrId)].price);
            //         }
            //     })
            // }

        }

        return ingredientsPrice;
    }



    addIngredientsPriceInOrder = (item) => {
        let ingredientsPrice = 0;
        // console.log('item INGR OPV', item);
        // console.log('this.props.productIngredients', this.props.productIngredients)
        // console.log('parseInt(item.ingredients.required)', parseInt(item.ingredients.required))
        // console.log('item.product_ingredients', item.product_ingredients)
        // console.log('productIngredients', productIngredients)


        if (item && item.opv_ingredients) {
            item.opv_ingredients.map(ing => {
                if (ing.ingredient_type == "required" || ing.ingredient_type == "addable") {
                    ingredientsPrice += parseFloat(this.props.productIngredients[parseInt(ing.ingredientId)].price);
                }
            })
        }


        // if (item && item.ingredients && item.product_ingredients && Object.values(item.product_ingredients).length > 0 && this.props.productIngredients) {
        //     if (item.ingredients.required) {
        //         if (this.props.productIngredients && this.props.productIngredients[parseInt(item.ingredients.required)] && this.props.productIngredients[parseInt(item.ingredients.required)].price) {
        //             // console.log('adding: this.props.productIngredients[parseInt(item.ingredients.required)].price', this.props.productIngredients[parseInt(item.ingredients.required)].price)
        //             ingredientsPrice += parseFloat(this.props.productIngredients[parseInt(item.ingredients.required)].price);
        //         }
        //     }

        //     if (item.ingredients.addable) {
        //         Object.values(item.ingredients.addable).map(ingrId => {
        //             if (this.props.productIngredients && this.props.productIngredients[parseInt(ingrId)] && this.props.productIngredients[parseInt(ingrId)].price) {
        //                 // console.log('adding: this.props.productIngredients[parseInt(ingrId)].price', this.props.productIngredients[parseInt(ingrId)].price)
        //                 ingredientsPrice += parseFloat(this.props.productIngredients[parseInt(ingrId)].price);
        //             }
        //         })
        //     }

        // if (item.ingredients.removable) {
        //     Object.values(item.ingredients.removable).map(ingrId => {
        //         if (this.props.productIngredients && this.props.productIngredients[parseInt(ingrId)] && this.props.productIngredients[parseInt(ingrId)].price) {
        //             console.log('adding: this.props.productIngredients[parseInt(ingrId)].price', this.props.productIngredients[parseInt(ingrId)].price)
        //             ingredientsPrice += parseFloat(this.props.productIngredients[parseInt(ingrId)].price);
        //         }
        //     })
        // }

        // }

        return ingredientsPrice;
    }



    render() {
        const { classes, open, handleChange, handleRemove, onClose, onSubmit, onError, askForBill, settings, translations, isLoadingCart } = this.props;
        let { productsToOrder } = this.props;
        productsToOrder = productsToOrder || [];
        // if (productsToOrder) {
        //     productsToOrder = Object.values(productsToOrder)[0];
        // }
        // console.log('productsToOrder', productsToOrder)
        let totalCartPrice = 0;
        let totalOrderCurrentPersonPrice = 0;
        let eachCartItem = [];
        const productsToOrderLength = productsToOrder ? Object.keys(productsToOrder).length : 0;
        let calculatedDeliveryPrice = 0;

        const noCartItemsText = <p className={classes.noCartItemsContainer}>
            <WarningIcon className={classes.noCartItemsWarningIcon} />
            <div className={classes.noItemsInCart}>{translations && translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.no_cart_items : ''}</div>
        </p>


        Object.values(productsToOrder).map((item, k) => {
            totalCartPrice += (parseFloat(item.price) + parseFloat(this.addIngredientsPrice(item))) * 1 * item.quantity;

            if ((settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && (((parseFloat(item.additional_price)).toFixed(2)) != '0.00')) {
                totalCartPrice += parseFloat(item.additional_price) * 1 * item.quantity;
            }
            // console.log('this.addIngredientsPrice(item)', this.addIngredientsPrice(item))
            // totalCartPrice += this.addIngredientsPrice(item);

            eachCartItem.push(
                <React.Fragment>
                    <ListItem button key={k} className={clsx(classes.eachCartItemRow, k === 0 ? "mt-1" : '')}>
                        {/* <img src={Utils.getProductImagePath(item.image)} alt="" className={classes.productCircularImage} /> */}
                        <img src={'https://tabl.bg/img/icons/blogging' + (this.props.products.product_variants_comment.findIndex(p => p.variantId === item.product_variant_id && p.comment.length > 0) !== -1 ? '-active' : '') + '.svg'} alt="" className={classes.commentIcon} onClick={() => this.props.setCommentModalData(true, item.name, item.product_variant_id) /*this.setState({ openCommentModal: true, commentModalTitle: item.name, commentVariantId: item.product_variant_id })*/} />
                        {/* <AddCommentIcon className={clsx(classes.addCommentIconBtn, (this.state.comments.findIndex(p => p.variantId === item.product_variant_id && p.comment.length > 0) !== -1 ? classes.activeAddCommentIconBtn : ''))} onClick={() => this.setState({ openCommentModal: true, commentModalTitle: item.name, commentVariantId: item.product_variant_id })} /> */}
                        <ListItemText
                            primary={(item.product_translations && item.product_translations[this.props.currLang] && item.product_translations[this.props.currLang].name || item.name) + (((settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && ((parseFloat(item.additional_price)).toFixed(2) != '0.00')) ? (' + ' + (translations.common && translations.common.box ? translations.common.box : '') + ' ' + item.additional_price + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.')) : '')}
                            secondary={
                                <React.Fragment>
                                    <span>{item.variant_translations && item.variant_translations[this.props.currLang] && item.variant_translations[this.props.currLang].size || item.size}</span>
                                    {item.ingredients ?
                                        <React.Fragment>
                                            {item.ingredients.required ?
                                                <div className={classes.ingredientsListRequired}>{Utils.getIngredientNamesByIds(item.ingredients.required, this.props.productIngredients)}</div>
                                                :
                                                null
                                            }

                                            {item.ingredients.addable && Object.values(item.ingredients.addable).length > 0 ?
                                                <div className={classes.ingredientsListAddable}>+ {Utils.getIngredientNamesByIds(item.ingredients.addable, this.props.productIngredients)}</div>
                                                :
                                                null
                                            }

                                            {item.ingredients.removable && Object.values(item.ingredients.removable).length > 0 ?
                                                <div className={classes.ingredientsListRemovable}>- {Utils.getIngredientNamesByIds(item.ingredients.removable, this.props.productIngredients)}</div>
                                                :
                                                null
                                            }
                                        </React.Fragment>
                                        :
                                        null
                                    }
                                </React.Fragment>
                            }
                            // secondary={item.size} 
                            className={classes.listItemText} />
                        <ListItemSecondaryAction className={classes.listItemRightText}>
                            <div style={{ display: 'contents' }}>
                                <div style={{ display: 'contents' }}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={item.quantity}
                                        className={classes.quantitySelect}
                                        onChange={(e) => handleChange(item, e.target.value)}
                                    >
                                        {this.productVariantCounts(item.quantity)}
                                    </Select>

                                    <span>
                                        x <span className={classes.priceContainer}>
                                            {Utils.calculateProductPriceOrder(productsToOrder[k], settings, this.props.productIngredients, 1)}
                                    &nbsp;
                                    {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}</span>
                                    </span>

                                    <span className={classes.eachCartItemTotalWithQuantity}>&nbsp;= <b>{Utils.normalizePrice(item.quantity * Utils.calculateProductPriceOrder(productsToOrder[k], settings, this.props.productIngredients, 1))}</b> {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}</span>

                                </div>

                                <IconButton aria-label="delete" className={classes.deleteOrderIcon} onClick={() => handleRemove(item)} data-class="remove-product-from-cart-btn">
                                    <HighlightOffIcon />
                                </IconButton>
                            </div>
                            <div className={classes.eachCartItemTotalWithQuantityNewRow}>
                                Общо: <b>{Utils.normalizePrice(item.quantity * Utils.calculateProductPriceOrder(productsToOrder[k], settings, this.props.productIngredients, 1))}</b> {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}
                            </div>
                        </ListItemSecondaryAction>
                    </ListItem>
                    {(k + 1) !== productsToOrderLength ? <Divider className={classes.productDivider} /> : null}
                </React.Fragment>
            );
        });

        let delivery_price_num = 0
        let min_price_free_delivery_num = 0

        if (settings.delivery_price && settings.delivery_price.value && settings.delivery_price.value > 0) {
            const default_currency_suffix = (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.')

            delivery_price_num = (settings.delivery_price && settings.delivery_price.value) ? parseFloat(settings.delivery_price.value).toFixed(2) : '';
            const deliveryPrice = delivery_price_num + default_currency_suffix;

            min_price_free_delivery_num = (settings.min_price_free_delivery && settings.min_price_free_delivery.value) ? parseFloat(settings.min_price_free_delivery.value).toFixed(2) : '';
            const minPriceFreeDelivery = min_price_free_delivery_num + default_currency_suffix;


            if (settings.order_type && settings.order_type.value && settings.order_type.value == 2) {// Only if delivery
                calculatedDeliveryPrice = (parseFloat(totalCartPrice) < parseFloat(min_price_free_delivery_num)) ? deliveryPrice : 0;
                // totalCartPrice = parseFloat(totalCartPrice) + parseFloat(delivery_price_num); // This line is not used because Total price DO NOT includes delivery! 
                eachCartItem.push(
                    <React.Fragment>
                        <ListItem button className={classes.deliveryContainer}>
                            <ListItemText
                                primary={(translations.common && translations.common.delivery ? translations.common.delivery + ': ' : '') + deliveryPrice}
                                secondary={translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.min_delivery_price_text ? Utils.translate(translations.pages.cart_delivery.min_delivery_price_text, { value: minPriceFreeDelivery }) : ''}
                                className={classes.deliveryContent}
                            />
                        </ListItem>
                    </React.Fragment>
                );
            }
        }


        const orderedProductsLength = this.props.orderedProducts.length;
        let eachOrderedItem = [];

        const noOrderedItemsText = <p className={classes.noCartItemsContainer}>
            <WarningIcon className={classes.noCartItemsWarningIcon} />
            <div className={classes.noItemsInCart}>{translations && translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.no_order_items : ''}</div>
        </p>


        Object.values(this.props.orderedProducts).map((item, k) => {
            let orderDate = new Date(item.createdAt);
            orderDate = Utils.appendLeadingZeroes(orderDate.getHours()) + ':' + Utils.appendLeadingZeroes(orderDate.getMinutes()); // + ':' + Utils.appendLeadingZeroes(orderDate.getSeconds());

            let orderActiveStep = 0;
            switch (item.status) {
                case 'ordered':
                    orderActiveStep = 0 //Приета
                    break;
                case 'cooked_spot':
                case 'served_spot':
                case 'cooked_delivery':
                case 'on_the_way_delivery':
                case 'cooked_preorder':
                    orderActiveStep = 1 //Приготвя се
                    break;
                case 'paid':
                    orderActiveStep = 2 //Завършена
                    break;
            }

            let eachOrderedVariant = [];
            let eachOrderTotalSum = 0;

            {

                item.order_productvariants.map((opv, count) => {
                    if (item.ordertypeId == 1) {
                        totalOrderCurrentPersonPrice += (parseFloat(opv.product_variant.price) + parseFloat(this.addIngredientsPriceInOrder(opv))) * 1 * parseInt(opv.quantity);
                    }

                    let productsById = this.props.productsById;

                    let productImage = productsById[opv.product_variant.productId] && productsById[opv.product_variant.productId].product_images && productsById[opv.product_variant.productId].product_images[0] && productsById[opv.product_variant.productId].product_images[0].path ? Utils.getProductThumbImagePath(productsById[opv.product_variant.productId].product_images[0].path) : DEFAULT_PRODUCT_IMAGE;
                    // let productName = pv.product && pv.product.name ? pv.product.name : '';
                    let productName =
                        (productsById[opv.product_variant.productId]
                            && productsById[opv.product_variant.productId].translations
                            && productsById[opv.product_variant.productId].translations[this.props.currLang]
                            && productsById[opv.product_variant.productId].translations[this.props.currLang].name) ? productsById[opv.product_variant.productId].translations[this.props.currLang].name :
                            (productsById && productsById[opv.product_variant.productId] && productsById[opv.product_variant.productId].name ? productsById[opv.product_variant.productId].name : '');

                    // let productSize = pv.size ? pv.size : '';

                    let variant = productsById[opv.product_variant.productId]
                        && productsById[opv.product_variant.productId].product_variants.filter(v => v.id == opv.product_variant.productId);
                    variant = variant && variant[0] || null;

                    let productSize = (variant && variant.translations && variant.translations[this.props.currLang]
                        && variant.translations[this.props.currLang].size) ? variant.translations[this.props.currLang].size : (variant && variant.size ? variant.size : '');

                    let productQuantity = opv.quantity ? opv.quantity : '';
                    let productPrice = opv.product_variant && opv.product_variant.price ? opv.product_variant.price : '';

                    // totalCartPrice += this.addIngredientsPrice(item);


                    let productVariant = productSize + ' - ' + productQuantity + ' x ' + (parseFloat(productPrice) + parseFloat(this.addIngredientsPriceInOrder(opv))).toFixed(2) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.');
                    let productRequiredIngredient = [];
                    let productAddablengredient = [];
                    let productRemovableIngredient = [];

                    opv.opv_ingredients.map(ing => {
                        if (ing.ingredient_type == 'required') {
                            productRequiredIngredient.push(ing.ingredientId)
                        } else if (ing.ingredient_type == 'addable') {
                            productAddablengredient.push(ing.ingredientId)
                        } else if (ing.ingredient_type == 'removable') {
                            productRemovableIngredient.push(ing.ingredientId)
                        }
                    })

                    eachOrderTotalSum += parseFloat((Math.round((parseFloat(productQuantity) * (parseFloat(productPrice) + parseFloat(this.addIngredientsPriceInOrder(opv)))) * 100) / 100).toFixed(2));

                    if ((item.ordertypeId == 2 || item.ordertypeId == 3) && (settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && (((parseFloat(opv.product_variant.additional_price)).toFixed(2)) != '0.00')) {
                        eachOrderTotalSum += parseFloat(opv.product_variant.additional_price) * 1 * parseInt(productQuantity);
                    }

                    eachOrderedVariant.push(
                        <React.Fragment key={count}>
                            <ListItem button key={count} className={classes.orderedListItem} style={item.ordertypeId == 1 && count == Object.values(item.order_productvariants).length - 1 ? { borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderBottom: '1px solid #ccc' } : {}}>
                                <img src={productImage} alt="" className={classes.productCircularImage} />
                                <ListItemText
                                    primary={productName + (((settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && ((parseFloat(opv.product_variant.additional_price)).toFixed(2) != '0.00')) ? (' /+ ' + productQuantity + ' ' + (translations.common && translations.common.count ? translations.common.count : '') + ' ' + (translations.common && translations.common.box ? translations.common.box : '') + ' ' + ((parseFloat(opv.product_variant.additional_price) * 1 * parseInt(productQuantity)).toFixed(2)) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.') + '/') : '')}
                                    secondary={
                                        <>
                                            {productVariant}

                                            {productRequiredIngredient.length > 0 ?
                                                <div className={classes.ingredientsListRequired}>{this.getIngredientNamesByIds(productRequiredIngredient)}</div>
                                                :
                                                null
                                            }

                                            {productAddablengredient.length > 0 ?
                                                <div className={classes.ingredientsListAddable}>+ {this.getIngredientNamesByIds(productAddablengredient)}</div>
                                                :
                                                null
                                            }

                                            {productRemovableIngredient.length > 0 ?
                                                <div className={classes.ingredientsListRemovable}>- {this.getIngredientNamesByIds(productRemovableIngredient)}</div>
                                                :
                                                null
                                            }
                                        </>
                                    } className={classes.orderedListItemText}
                                />
                                <ListItemSecondaryAction>
                                    {((Math.round((parseFloat(productQuantity) * (parseFloat(productPrice) + parseFloat(this.addIngredientsPriceInOrder(opv)))) * 100) / 100) + ((item.ordertypeId == 2 || item.ordertypeId == 3) && (settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && (((parseFloat(opv.product_variant.additional_price)).toFixed(2)) != '0.00') ? (parseFloat(opv.product_variant.additional_price) * 1 * parseInt(productQuantity)) : 0)).toFixed(2)} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}
                                </ListItemSecondaryAction>
                            </ListItem>
                            {count != Object.values(item.order_productvariants).length - 1 ? <Divider className={classes.orderedProductDivider} /> : null}
                        </React.Fragment >
                    )


                });


                // item.order_productvariants.map((pv, count) => {

                //     if (item.ordertypeId == 1) {
                //         totalOrderCurrentPersonPrice += parseFloat(pv.price) * 1 * parseInt(pv.order_productvariant.quantity);
                //     }
                //     let productsById = this.props.productsById;

                //     let productImage = pv.product && pv.product.product_images && pv.product.product_images[0] && pv.product.product_images[0].path ? Utils.getProductThumbImagePath(pv.product.product_images[0].path) : DEFAULT_PRODUCT_IMAGE;
                //     // let productName = pv.product && pv.product.name ? pv.product.name : '';
                //     let productName = pv.product && productsById[pv.product.id] && productsById[pv.product.id].translations
                //         && productsById[pv.product.id].translations[this.props.currLang]
                //         && productsById[pv.product.id].translations[this.props.currLang].name
                //         || pv.product.name || '';

                //     // let productSize = pv.size ? pv.size : '';

                //     let variant = pv.product && productsById[pv.product.id]
                //         && productsById[pv.product.id].product_variants.filter(v => v.id == pv.id);
                //     variant = variant && variant[0] || null;
                //     let productSize = variant && variant.translations && variant.translations[this.props.currLang]
                //         && variant.translations[this.props.currLang].size || pv.size || '';

                //     let productQuantity = pv.order_productvariant && pv.order_productvariant.quantity ? pv.order_productvariant.quantity : '';
                //     let productPrice = pv.price ? pv.price : '';
                //     let productVariant = productSize + ' - ' + productQuantity + ' x ' + productPrice + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.');

                //     eachOrderTotalSum += parseFloat((Math.round((parseFloat(productQuantity) * parseFloat(productPrice)) * 100) / 100).toFixed(2));

                //     if ((item.ordertypeId == 2 || item.ordertypeId == 3) && (settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && (((parseFloat(pv.additional_price)).toFixed(2)) != '0.00')) {
                //         eachOrderTotalSum += parseFloat(pv.additional_price) * 1 * parseInt(productQuantity);
                //     }

                //     eachOrderedVariant.push(
                //         <React.Fragment key={count}>
                //             <ListItem button key={count} className={classes.orderedListItem} style={item.ordertypeId == 1 && count == Object.keys(item.ProductVariants).length - 1 ? { borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderBottom: '1px solid #ccc' } : {}}>
                //                 <img src={productImage} alt="" className={classes.productCircularImage} />
                //                 <ListItemText primary={productName + (((settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && ((parseFloat(pv.additional_price)).toFixed(2) != '0.00')) ? (' /+ ' + productQuantity + ' ' + (translations.common && translations.common.count ? translations.common.count : '') + ' ' + (translations.common && translations.common.box ? translations.common.box : '') + ' ' + ((parseFloat(pv.additional_price) * 1 * parseInt(productQuantity)).toFixed(2)) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.') + '/') : '')} secondary={productVariant} className={classes.orderedListItemText} />
                //                 <ListItemSecondaryAction>
                //                     {((Math.round((parseFloat(productQuantity) * parseFloat(productPrice)) * 100) / 100) + ((item.ordertypeId == 2 || item.ordertypeId == 3) && (settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && (((parseFloat(pv.additional_price)).toFixed(2)) != '0.00') ? (parseFloat(pv.additional_price) * 1 * parseInt(productQuantity)) : 0)).toFixed(2)} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}
                //                 </ListItemSecondaryAction>
                //             </ListItem>
                //             {count != Object.keys(item.ProductVariants).length - 1 ? <Divider className={classes.orderedProductDivider} /> : null}
                //         </React.Fragment>
                //     )
                // })
            }

            let deliveryIncluded = false;
            if (item.ordertypeId == 2) {
                if (eachOrderTotalSum < parseFloat(min_price_free_delivery_num)) {
                    deliveryIncluded = true;
                    eachOrderTotalSum += parseFloat(delivery_price_num);
                }
            }

            eachOrderedItem.push(
                <React.Fragment key={k}>

                    {/* постъпила, приели, готова */}
                    <Stepper alternativeLabel activeStep={orderActiveStep} connector={<ColorlibConnector />} className={k == 0 ? classes.stepperFirst : classes.stepper}>
                        {[
                            translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.status_accepted : '',
                            translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.status_preparing : '',
                            translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.status_finished : '',
                        ].map(label => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={this.ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    <ListItem button className={classes.eachOrderHeaderContainer}>
                        <div className="first">
                            <span>
                                {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.order : ''} #{item.id}
                            </span>
                        </div>
                        <div>
                            <ScheduleIcon className={classes.orderTimeIcon} />
                            <span className={classes.eachOrderTime}>
                                {orderDate}
                            </span>
                        </div>
                        <div className="last tooltip-text-align-center">
                            <span>
                                {/* {deliveryIncluded ?
                                    <React.Fragment> */}
                                {/* TODO - This logic is working, the problem is only in the tooltip showing */}
                                {/* <ClickAwayListener onClickAway={() => this.setState({ totalCartPriceWhenWithDeliveryTooltipOpened: false })}>
                                        <div>
                                            <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={() => this.setState({ totalCartPriceWhenWithDeliveryTooltipOpened: false })}
                                                open={this.state.totalCartPriceWhenWithDeliveryTooltipOpened}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title={Utils.translate(this.props.translations.pages.cart_delivery.delivery_price_included_in_total_price, { delivery_price_num: parseFloat(delivery_price_num) + '' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'), min_price_free_delivery_num: parseFloat(min_price_free_delivery_num) + '' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.') })}
                                            >
                                                <span onClick={() => this.state.totalCartPriceWhenWithDeliveryTooltipOpened == false ? this.setState({ totalCartPriceWhenWithDeliveryTooltipOpened: true }) : void (0)} onMouseOver={() => this.setState({ totalCartPriceWhenWithDeliveryTooltipOpened: true })} onMouseOut={() => this.setState({ totalCartPriceWhenWithDeliveryTooltipOpened: false })}>
                                                    {Utils.normalizePrice(eachOrderTotalSum)} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}
                                                    <InfoIcon style={{ verticalAlign: '-6', marginRight: 3 }} />
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </ClickAwayListener> */}
                                {/* </React.Fragment>
                                    : */}
                                <span>{Utils.normalizePrice(eachOrderTotalSum)} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}</span>
                                {/* } */}
                            </span>
                        </div>
                    </ListItem >

                    <Divider className={classes.orderedProductDivider} />

                    {eachOrderedVariant}

                    {item.ordertypeId == 1 ?
                        <React.Fragment>
                            {/* <ListItem className={clsx(classes.eachOrderFooterContainer, classes.eachOrderFooterContainerTwoColumns)}>
                            <div className="first">
                                <Button onClick={() => askForBill('cash')}>{translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.pay_in_cash : ''}</Button>
                            </div>
                            <div className="last">
                                <Button onClick={() => askForBill('card')}>{translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.pay_with_card : ''}</Button>
                            </div>
                        </ListItem> */}
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Divider className={classes.orderedProductDivider} />

                            <ListItem button className={clsx(classes.eachOrderFooterContainer, classes.eachOrderFooterContainerThreeColumns)}>
                                <div className="first">
                                    <span>
                                        {item.client_name}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        {item.client_phone}
                                    </span>
                                </div>
                                <div className="last">
                                    <span>
                                        {item.client_address}
                                    </span>
                                </div>
                            </ListItem>
                        </React.Fragment>

                    }

                    {(k !== orderedProductsLength - 1) ? < hr className="progress" /> : <div style={{ marginBottom: 20 }} />}


                </React.Fragment >
            );
        });

        // this.props.chosenPlaceOrderTypes.length == 1 && this.props.chosenPlaceOrderTypes.type == 'delivery'

        return (
            <div>
                {/* <PaymentTypeModal
                    open={this.state.openPaymentTypeModal}
                    onClose={() => this.setState({ openPaymentTypeModal: false })}
                    translations={translations}
                    currLang={this.props.currLang}
                    askForBillCash={() => { this.setState({ openPaymentTypeModal: false }); askForBill('cash'); }}
                    askForBillCard={() => { this.setState({ openPaymentTypeModal: false }); askForBill('card'); }}
                /> */}

                <Dialog
                    fullScreen
                    open={open}
                    onClose={onClose}
                    TransitionComponent={Transition}
                    className={classes.root}
                    data-class="dialog-container"
                >

                    <Backdrop open={isLoadingCart} className={classes.backdrop}>
                        <CircularProgress className="is-loading-colored-only" />
                    </Backdrop>

                    <AppBar className={classes.appBar} data-class="dialog-title-container">
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={onClose}
                                aria-label="close"
                                style={{ marginRight: 5 }}
                            >
                                <CloseIcon />
                            </IconButton>

                            {/* <Button className={this.props.selectedCartTab === 0 ? classes.titleActive : classes.title} onClick={() => this.props.changeSelectedCartTab(0)} data-class={this.props.selectedCartTab === 0 ? "active-cart-modal-button" : "inactive-cart-modal-button"}> */}
                            {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.cart : ''}
                            {/* </Button> */}

                            {/* <Button className={this.props.selectedCartTab === 1 ? classes.titleActive : classes.title} onClick={() => this.props.changeSelectedCartTab(1)} data-class={this.props.selectedCartTab === 1 ? "active-cart-modal-button" : "inactive-cart-modal-button"}>
                                {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.orders : ''}
                            </Button> */}

                        </Toolbar>
                    </AppBar>
                    <SwipeableViews index={this.props.selectedCartTab}
                        // onChangeIndex={(i) => this.props.changeSelectedCartTab(i)} 
                        enableMouseEvents animateHeight action={actions => (this.swipeableActions = actions)}>
                        <div>

                            <List className={classes.list}>

                                {productsToOrderLength == 1 ? <br /> : null}
                                {productsToOrderLength > 0 ? eachCartItem : noCartItemsText}
                                {productsToOrderLength == 1 ? <br /> : null}

                                {

                                    // Show delivery prices information
                                    (
                                        (settings.visible_about_page_delivery_prices_text_in_cart && settings.visible_about_page_delivery_prices_text_in_cart.value && settings.visible_about_page_delivery_prices_text_in_cart.value == 1)
                                        && (settings.about_page_delivery_prices && settings.about_page_delivery_prices.value)
                                        && (settings.order_type && settings.order_type.value && (settings.order_type.value == 2 || settings.order_type.value == 4 || settings.order_type.value == 5 || settings.order_type.value == 7))
                                        && (productsToOrderLength > 0)
                                    ) ?
                                        <div className={classes.deliveryPricesInfo}>
                                            <div className={classes.deliveryPricesText}>{translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.delivery_price ? translations.pages.cart_delivery.delivery_price : ''}</div>

                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: settings.about_page_delivery_prices && settings.about_page_delivery_prices.value ? settings.about_page_delivery_prices.value.replace('<strong>', '').replace('</strong>', '') : null
                                                }}>
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                                {productsToOrderLength > 0 ?
                                    <React.Fragment>
                                        <div className="ordered-arrow-up"></div>

                                        <div className={classes.footerContainer} data-class="cart-modal-footer-container">
                                            <div className={settings.show_delivery_price_not_included_text && settings.show_delivery_price_not_included_text.value && settings.show_delivery_price_not_included_text.value == 1 ? clsx(classes.totalSum, classes.totalSumSmallerPadding) : classes.totalSum}>
                                                {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.total : ''}: <span className="skiptranslate">{Utils.calculateTotalPriceForOrder(productsToOrder, settings, this.props.productIngredients)}</span> {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}
                                                {settings.show_delivery_price_not_included_text && settings.show_delivery_price_not_included_text.value && settings.show_delivery_price_not_included_text.value == 1 ? <React.Fragment><br />{translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.delivery_price_is_not_included : ''}</React.Fragment> : ''}
                                            </div>
                                            <Button
                                                color="inherit"
                                                className={clsx(classes.footerButton, 'shy')}
                                                onClick={() => { this.props.setDisabledMakeOrderButton(true); this.callMakeOrder(eachCartItem, totalCartPrice); }}
                                                disabled={this.props.disabledMakeOrderButton}
                                            >
                                                <div>{this.props.chosenPlaceOrderTypes.length == 1 && this.props.chosenPlaceOrderTypes[0] && this.props.chosenPlaceOrderTypes[0].type == 'delivery' ? (translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.make_delivery_order : '') : (translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.make_order : '')}</div>

                                                {(window.localStorage.getItem('bill_wanted') && Utils.getUserToken()) ?
                                                    <div className={classes.billAlreadyWantedInfoLabel}>
                                                        {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.bill_already_wanted : ''}
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </Button>

                                        </div>
                                    </React.Fragment>
                                    :
                                    null
                                }

                            </List>

                        </div>

                    </SwipeableViews>

                </Dialog>
            </div >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let chosenPlaceOrderTypes = [];
    let chosenPlaceId = state.places.chosen_place;
    let chosenPlace = state.places.places.filter(p => p.id == chosenPlaceId);
    if (chosenPlace.length == 0) {
        chosenPlace = null;
    } else {
        chosenPlace = chosenPlace[0];
        chosenPlaceOrderTypes = chosenPlace.ordertypes;
    }

    return {
        selectedCartTab: state.products.selected_cart_tab || 0,
        orderedProducts: state.products.ordered || [],
        orderSent: state.products.order_sent || null,
        settings: state.settings.settings || [],
        products: state.products || [],
        productsById: state.products.all_products || [],
        isLoadingCart: state.products.isLoadingCart || false,
        orderMessage: state.products.order_message || '',
        deliveryModalState: state.scan.delivery_modal_state,
        chosenPlaceOrderTypes: chosenPlaceOrderTypes,
        disabledMakeOrderButton: state.products.disabledMakeOrderButton,
        productIngredients: state.products.productIngredients || [],
    };
};

export default withStyles(styles)(connect(mapStateToProps, { changeSelectedCartTab, setOrderComment, removeUserTokenAndOrder, setDeliveryModalState, setCommentModalData, setDisabledMakeOrderButton })(CartModal))